export const zhHK = {
  instantEnrollment: '報價',
  quotationandconfirmpurchase: '輕鬆完成報價和購買確認',
  travelInsurnace: '旅遊保險',
  bravoTravelProtector: '忠意旅遊保',
  gereraliTravelPackageInsurance: '忠意旅遊綜合保險計劃',
  annualStudyAbroadProtector: '全年海外留學保障',
  ussstudyplan: '美國學習計劃',
  ussstudyplanpopup:'<ol><li>我已符合以下其中一個身分：a) 本科生- 註冊並全日制上課；或b) 研究生；或c) 受教育機構邀請的學者或研究人員</li><li>我不是美國公民</li></ol>',
  ussp: '美國學習計劃',
  homeInsurnace: '家居保險',
  homePackageInsurance: '家居綜合保險',
  fireInsurance: '火險',
  domesticHelperInsurance: '家庭僱傭保險',
  accidentInsurance: '人身意外險',
  generaliPersonalAccidentInsurancePlan: '人身意外保險計劃',
  executivePersonalAccidentProtector: '行政人員人身意外保障',
  bravoPassengerProtector: '忠意乘',
  ahiInsurance:'忠意保住院現金保障計劃',
  affinityProduct: '特約保險',
  cityU: '旅遊計劃 - 香港城市大學',
  uow: '旅遊計劃 - 香港澳大利亞伍倫貢書院',
  centalineLandlordProtector: '中原收租保',
  centalineHomeProtector: '中原家居保',
  centalineTravelProtector: '中原旅遊保',
  centalineDomesticHelperProtector: '中原家傭保',

  // Payment Screen
  payment: '付款',
  submit: '送出',
  validationFailed: '驗證失敗',
  recaptchaValidationFailed: 'ReCAPTCHA 驗證失敗！',
  retry: '重新驗證',
  congratulations: '恭喜',
  madeAPurchase: '你已成功購買這項產品\n',
  weWillSend: '接下來，我們將會寄一封附有保單及保單條款的電郵到你的電子郵箱',
  anyQuestions: '\n如有任何疑問，歡迎隨時與我們聯絡，謝謝',
  ok: '確認',
  home: '首頁',
  login: '登入',
  logout: '登出',
  register: '登記',
  page: '上一頁',
  history: '記錄',
  instantEnrollment: '報價',
  roarUs: '聯絡我們',
  privilegedClub: '專享禮遇',
  instantClaims: '索償',
  settings: '設定',
  buy: '獲取報價和投保',
  just: '點擊幾下即可輕鬆完成報價和投保。',
  borkermes: '保險顧問功能',
  ieborker: '為您的客戶獲取報價和投保',
  iemessages: '點擊幾下，即可為您的客戶輕鬆投保。',
  yes:'是',
  no:'否',

  //nova home care for menu only
  novaPackageInsurance: '新域「家」安心',
  
}
