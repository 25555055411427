import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import { Text, View } from "react-native";
import { JointNameInput, YesNoQuestion } from "./../../Form";
import Locale from "./../../Locale";

export default class JointPolicyHolderForm extends React.Component {
  render() {
    const {
      organization,
      noDocument,
      passport,
      noDateOfBirth,
      minDate,
      maxDate,
      noAddress,
      addressLabel,
      addressMandatory,
      num,
      jointpolicyHolder,
      jointpolicyHolder1,
      jointpolicyHolder2,
      jointpolicyHolder3,
      onChange,
      messages,
      messages1,
      messages2,
      messages3,
    } = this.props;

    var numlist = [];
    for (let i = 1; i <= num; i++) {
      numlist.push(i);
    }

    return (
      <View>
        {/* {this.props.premiumPlan ? undefined : (
          <Text>{Locale.text.jointpolicyHolder}</Text>
        )} */}

        <YesNoQuestion
          label={Locale.text.needjointpolicyHolder}
          labelII={Locale.text.needjointpolicyHolderII}
          value={jointpolicyHolder.isJointpolicyHolder}
          onChange={(isJointpolicyHolder) => onChange({ isJointpolicyHolder })}
          message={messages && messages.isJointpolicyHolder}
        />

        {jointpolicyHolder.isJointpolicyHolder == "Y" ? (
          <View>
            <Text>{Locale.text.numberOfPolicyholders}</Text>
            {numlist.length > 1 ? (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={jointpolicyHolder.jointpolicyHolderAmount}
                label="Age"
                onChange={(e) => {
                  onChange({ jointpolicyHolderAmount: e.target.value });
                }}
              >
                {numlist.map((option, index) => (
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
              </Select>
            ) : (
              <></>
            )}
            {jointpolicyHolder.jointpolicyHolderAmount >= 1 ? (
              <JointNameInput
                label={Locale.text.name}
                salutation={jointpolicyHolder1.salutation}
                firstName={jointpolicyHolder1.firstName}
                lastName={jointpolicyHolder1.lastName}
                onChange={(jointpolicyHolder1) =>
                  onChange({ jointpolicyHolder1 })
                }
                messages={messages1}
              />
            ) : null}
            {jointpolicyHolder.jointpolicyHolderAmount >= 2 ? (
              <JointNameInput
                label={Locale.text.name}
                salutation={jointpolicyHolder2.salutation}
                firstName={jointpolicyHolder2.firstName}
                lastName={jointpolicyHolder2.lastName}
                onChange={(jointpolicyHolder2) =>
                  onChange({ jointpolicyHolder2 })
                }
                messages={messages2}
              />
            ) : null}
            {jointpolicyHolder.jointpolicyHolderAmount >= 3 ? (
              <JointNameInput
                label={Locale.text.name}
                salutation={jointpolicyHolder3.salutation}
                firstName={jointpolicyHolder3.firstName}
                lastName={jointpolicyHolder3.lastName}
                onChange={(jointpolicyHolder3) =>
                  onChange({ jointpolicyHolder3 })
                }
                messages={messages3}
              />
            ) : null}
          </View>
        ) : null}
      </View>
    );
  }
}
