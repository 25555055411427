import React from "react";
import { Text, View } from "react-native";

import { DropDownList, Heading2, TextInput, YesNoQuestion } from "./../../Form";
import Locale from "./../../Locale";
import HengFaChuenChecking from "./../HengFaChuenChecking";

export default class InsuredNovaForm extends React.Component {
  checkHengFaChuen() {
    const { fullAddress } = this.props.insuredHome;

    if (fullAddress && fullAddress.length) {
      return HengFaChuenChecking.check(fullAddress);
    }
  }

  render() {
    const {
      insuredHome,
      onChange,
      messages,
      includeBuildingOptions,
      areaAgeTypeOptions,
      unitAreaTypeOptions,
      homeGrossAreas,
      homeSaleableAreas,
    } = this.props;

    return (
      <View>
        <Heading2>{Locale.text.home}</Heading2>

        <TextInput
          label={Locale.text.address}
          placeholder={Locale.text.hongKongAddressOnly}
          value={insuredHome.fullAddress}
          onChange={(fullAddress) => onChange({ fullAddress })}
          message={messages && messages.fullAddress}
        />

        {this.checkHengFaChuen() && (
          <Text>{Locale.text.manualUnderwritingIsRequired}</Text>
        )}

        <DropDownList
          label={Locale.text.novaPropertyType}
          options={includeBuildingOptions}
          value={insuredHome.homeType}
          onChange={(homeType) => onChange({ homeType })}
          message={messages && messages.homeType}
        />
        {insuredHome.homeType === "H" && (
          <Text style={{ color: "#C50000" }}>
            {Locale.text.manualUnderwritingIsRequired}
          </Text>
        )}

        <DropDownList
          label={Locale.text.novaAge}
          options={areaAgeTypeOptions}
          value={insuredHome.buildingAgeId}
          onChange={(buildingAgeId) => onChange({ buildingAgeId })}
          message={messages && messages.buildingAgeId}
        />
        {insuredHome.buildingAgeId === 7 || insuredHome.buildingAgeId === 14 ? (
          <Text style={{ color: "#C50000" }}>
            {Locale.text.manualUnderwritingIsRequired}
          </Text>
        ) : (
          <></>
        )}
        <DropDownList
          label={Locale.text.novaUnitAreaType}
          options={unitAreaTypeOptions}
          value={insuredHome.areaType}
          onChange={(choice) =>
            onChange({ areaType: choice, homeAreaId: null })
          }
          message={messages && messages.areaType}
        />

        <DropDownList
          label={Locale.text.novaLivingArea}
          disabled={!insuredHome.areaType}
          options={
            insuredHome.areaType === "S" ? homeGrossAreas : homeSaleableAreas
          }
          value={insuredHome.homeAreaId}
          onChange={(choice) => onChange({ homeAreaId: choice })}
          message={messages && messages.homeAreaId}
        />
        {insuredHome.homeAreaId == 6 ||
        insuredHome.homeAreaId == 12 ||
        insuredHome.homeAreaId == 18 ||
        insuredHome.homeAreaId == 24 ? (
          <Text style={{ color: "#C50000" }}>
            {Locale.text.manualUnderwritingIsRequired}
          </Text>
        ) : (
          <></>
        )}
        <YesNoQuestion
          label={Locale.text.theHomeIsUnoccupiedForLonger30Days}
          value={insuredHome.isUnoccupied}
          onChange={(isUnoccupied) => onChange({ isUnoccupied })}
          manualUnderwriting={insuredHome.isUnoccupied == "Y"}
          message={messages && messages.isUnoccupied}
        />

        <YesNoQuestion
          label={
            insuredHome.buildingAgeId === 4 ||
            insuredHome.buildingAgeId === 5 ||
            insuredHome.buildingAgeId === 6 ||
            insuredHome.buildingAgeId === 7 ||
            insuredHome.buildingAgeId === 11 ||
            insuredHome.buildingAgeId === 12 ||
            insuredHome.buildingAgeId === 13 ||
            insuredHome.buildingAgeId === 14
              ? Locale.text.anyLossDamageLiabilityOrAccidentII
              : Locale.text.anyLossDamageLiabilityOrAccident
          }
          value={insuredHome.isAnyLoss}
          onChange={(isAnyLoss) => onChange({ isAnyLoss })}
          manualUnderwriting={insuredHome.isAnyLoss == "Y"}
          message={messages && messages.isAnyLoss}
        />

        <YesNoQuestion
          label={Locale.text.anyInsuranceCompnayDeclined}
          value={insuredHome.isProposalDeclined}
          onChange={(isProposalDeclined) => onChange({ isProposalDeclined })}
          manualUnderwriting={insuredHome.isProposalDeclined == "Y"}
          message={messages && messages.isProposalDeclined}
        />
      </View>
    );
  }
}
