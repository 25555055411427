import React from "react";
import {
  FlatList,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import Locale from "./../../Locale";

export default class InformationForQuotation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hide: false,
    };
  }

  toggle() {
    if (this.props.data) {
      this.setState({ hide: !this.state.hide });
    }
  }

  render() {
    let planTypeLabel;
    let planTypeIndex;

    this.props.planTypeOptions.forEach(({ value, label }, index) => {
      if (value == this.props.planType) {
        planTypeLabel = label;
        planTypeIndex = index;
      }
    });

    const backgroundColor = backgroundColors[planTypeIndex];

    return (
      <View>
        <Text style={styles.iWouldLikeToBuy}>
          {Locale.text.iWouldLikeToBuy}
        </Text>

        <View style={{ backgroundColor }}>
          <TouchableOpacity
            style={styles.planType}
            onPress={() => this.toggle()}
          >
            <View style={styles.planTypeLabel}>
              <Text style={styles.planTypeLabelText}>{planTypeLabel}</Text>
            </View>

            <View style={styles.planTypeArrow}>
              {this.state.hide ? (
                <Image
                  style={styles.planTypeArrowImage}
                  source={whiteArrowRight}
                />
              ) : (
                <Image
                  style={styles.planTypeArrowImage}
                  source={whiteArrowDown}
                />
              )}
            </View>
          </TouchableOpacity>

          {!this.state.hide && (
            <FlatList
              data={this.props.data}
              renderItem={({ item, index }) =>
                item.value && (
                  <View key={index} style={styles.planDetail}>
                    <View style={styles.dataName}>
                      <Text>{item.label}</Text>
                    </View>

                    <View style={styles.dataValue}>
                      {item.style === "left" ? (
                        <Text style={styles.dataValueLeftText}>
                          {item.value}
                        </Text>
                      ) : (
                        <Text style={styles.dataValueText}>{item.value}</Text>
                      )}
                    </View>
                  </View>
                )
              }
              keyExtractor={(item, index) => index.toString()}
            />
          )}
        </View>
      </View>
    );
  }
}

const backgroundColors = ["#8E1230", "#E9573D", "#F09273"];
const whiteArrowDown = require("./white_arrow_down.png");
const whiteArrowRight = require("./white_arrow_right.png");

const styles = StyleSheet.create({
  iWouldLikeToBuy: {
    marginTop: 20,
    marginBottom: 20,
  },
  planType: {
    flex: 1,
    flexDirection: "row",
    minHeight: 60,
  },
  planTypeLabel: {
    flex: 9,
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: 20,
  },
  planTypeLabelText: {
    color: "white",
  },
  planTypeArrow: {
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-end",
    paddingRight: 20,
  },
  planTypeArrowImage: {
    width: 10,
    height: 10,
  },
  planDetail: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#FAFAFA",
    minHeight: 50,
    borderBottomWidth: 1,
    borderBottomColor: "lightgrey",
  },
  dataName: {
    justifyContent: "center",
    marginLeft: 20,
  },
  dataValue: {
    justifyContent: "center",
    alignItems: "flex-end",
    marginRight: 20,
  },
  dataValueText: {
    textAlign: "right",
  },
  dataValueLeftText: {
    textAlign: "left",
  },
});
