export const zhHK = {
  needsToBeInEnglish: "需以英文填寫",
  pleaseLogin: "請先登入",
  pleaseLoginBeforeYouSubmitClaims: "索償前請先登入",
  loading: "載入中……",
  login: "登入",
  logIn: "登入",
  loginExpired: "登入已過期",
  forBetterCustomerExperiencelogin: "會員登入",
  forBetterCustomerExperiencregister: "註冊新會員",
  forBetterCustomerExperienceCon: "不登入繼續",
  yourLogin: "您此次登入的時效已過，請重新登入。",
  codeBySMS: "以SMS再次發送六位數字確認碼",
  codeByEmail: "以電郵再次發送六位數字確認碼",
  continue: "繼續",
  return: "返回",
  ok: "確認",
  applicationDeclined: "申請已撤銷",
  reInput: "重新輸入",
  backHome: "回到首頁",
  buyAgain: "再次投保",
  roarUs: "聯絡我們",
  privilegedClub: "專享禮遇",
  cancel: "取消",
  alert: "請注意",
  register: "登記",
  name: "姓名",
  title: "稱謂",
  mr: "先生",
  ms: "女士",
  mrs: "太太",
  miss: "小姐",
  firstName: "名",
  lastName: "姓",
  dateOfBirth: "出生日期",
  mobileNumber: "手提電話號碼",
  emailAddress: "電郵地址",
  mustBeTheTraveler: "* 保單持有人必須是遊客 *",
  policyholderIs: "投保人",
  oneOfTheTravelers: "遊客之一",
  theTraveler: "遊客",
  firstAdult: "第一成人",
  insured: "受保人",
  relationshipWithThePolicyholder: "與保單持有人的關係",
  immediateFamily: "直系親屬",
  hkidOrPassportNumber: "香港身份證 / 證件號碼",
  hkid: "香港身份證",
  passport: "護照",
  pleaseEnterAllCharacters:
    "請輸入您的香港身份證的所有數字。 例: 您的香港身份證是A123456（7），請輸入A1234567。",
  specialRequests: "特別要求",
  referralCode: "介紹碼",
  thisFieldCannotBeBlank: "必須填寫空格",
  invalidPromotionCode: "無效的優惠代碼",
  addInsuranceAdvisor: "新增保險顧問",
  wouldYouLike: "您願意 ",
  toBeYourInsuranceAdvisor: " 成為您的保險顧問嗎？",
  check: "請檢查您的輸入一遍！",
  theInsuranceIsIssuedByContent:
    "此保險由忠意保險有限公司（香港分行）（本港獲授權的保險公司）承保並簽發。",
  notValidEmail: "這不是有效的電郵地址",
  notANumber: "此欄位只接受數字",
  notValidHKID: "這不是有效身份証號碼",
  yes: "是",
  no: "否",
  submissionFailed: "提交失敗",
  invalidMobileNumber: "無效的保險顧問電話號碼。",
  theInsuranceIsIssued:
    "此保險由忠意保險有限公司（香港分行）（本港獲授權的保險公司）承保並簽發，並由 ",
  professionalInsurance: " 安排。",
  insuranceAdvisorMobile: "保險顧問電話號碼",
  brokerDeclaration:
    "我/我們明白，確知及同意，忠意保險有限公司（香港分行） 會就本人的申請而簽發的保單，向安排保單事宜的獲授權保險經紀或保險代理",
  brokerDeclarationII:
    "支付佣金，並向其提供閣下之個人資料，作處理及管理該保單之用途 。",
  continueWithoutLogIn: "不登入繼續",
  forBetterCustomerExperience: "為了獲得更好的客戶體驗，您想先登入嗎？",
  getAQuote: "索取報價",
  productEdges: "產品特點",
  promotionCodes: "優惠代碼",
  optional: "非必填",
  businessEmailAddress: "商業電郵/會員",
  businessEmailMembership: "商業電郵/會員",
  periodOfInsurance: "保障年期",
  iWouldLikeToBuy: "我想投保",
  levyIsNotIncluded: "不包括保費徵費及有關費用",
  buyNow: "立即購買",
  getInsured: "立即投保",
  pleaseInputInEnglish: "* 請輸入英文*",
  productType: "計劃類型",
  effectiveDate: "生效日期",
  pleasePickADate: "請選擇日期",
  pleaseChoose: "請選擇",
  importantNotes: "重要聲明",
  importantNote: "重要聲明",
  payment: "付款",
  youHave: "您有 ",
  eCashNow: "忠意保險現金券，是否要使用它？",
  youHaveGeneraliVoucherNow: "您有忠意保險現金券，是否要使用它？",
  levy: "徵費",
  total: "合共",
  submit: "提交",
  bothDatesInclusive: "(包含開始日及結束日)",
  pleaseWait: "請稍候",
  thankYou: "謝謝！",
  youHaveSuccessfully: "您已成功提交",
  ccccc: "申請。",
  manualUnderwritingIsNeeded: "您的申請需要核保，我們會在兩個工作天內回覆您。",
  manualUnderwritingIsRequired: "* 您的申請需要核保 *",
  manualUnderwriting: "您的申請需要核保",
  needsManualUnderwriting: "，並需要核保。",
  inCaseOfAnyQuestions:
    "如有任何疑問，請聯絡我們的客戶服務熱線 (852) 6682 1112。",
  congratulations: "恭喜",
  madeAPurchase: "你已成功購買這項產品\n",
  weWillSend: "接下來，我們將會寄一封附有保單及保單條款的電郵到你的電子郵箱",
  anyQuestions: "\n如有任何疑問，歡迎隨時與我們聯絡，謝謝",
  annualStudyAbroadProtector: "全年海外留學保障",
  annualStudyAbroadGenerali: "全年海外留學保障",
  address: "地址",
  confirm: "確認",
  iWeDoNotConsentPDto: "我/我們不同意提供個人資料被第三方作為行銷用途使用。",
  iWeDoNotConsentPDToCompanyMarketing:
    "我/我們不同意提供個人資料給貴公司作為行銷用途",
  spouse: "配偶",
  insuredName: "受保人姓名",
  centalineLandlordProtector: "中原收租保",
  forDetails: "產品內容無免責條款跟最高費用，請參照產品徵費與政策條款",
  policyHolder: "保單持有人",
  pleaseEnterAllHKID:
    "請輸入您的香港身份證的所有數字。 例: 您的香港身份證是A123456(7)，請輸入A1234567。",
  size: "面積",
  house: "洋房",
  theHomeIsUnoccupiedForLonger30Days: "居所會否連續多於三十日無人居住?",
  anyLossDamageLiabilityOrAccident:
    "居所曾否於過往十二個月內，發生意外而引致財物損失或損毀、或第三者法律責任？",
  anyLossDamageLiabilityOrAccidentII:
    "居所曾否於過往三十六個月內，發生意外而引致財物損失或損毀、或第三者法律責任？",
  allCoveragesAreSubject: "所有保障範圍均以保單條款及條文作規限。",
  occupation: "職業",
  planType: "計劃類型",
  executivePersonalAccidentProtector: "行政人員人身意外保障",
  travelInsurnace: "旅遊保險",
  gereraliTravelPackageInsurance: "忠意旅遊綜合保險計劃",
  homeInsurance: "家居保險",
  homeScreenInstantEnrollment: "家居保險",
  homePackageInsurance: "家居綜合保險",
  homePackageInsuranceLeftPanel: "保護您寶貴的資產。",
  fireInsurance: "火險",
  fireInsuranceLeftPanel: "保護您的房屋免受火災導致的損失。",
  domesticHelperInsurance: "家庭僱傭保險",
  domesticHelperInsuranceLeftPanel: "保障家傭的安康並履行您的法律責任。",
  accidentInsurance: "人身意外險",
  generaliPersonalAccidentInsurancePlan: "人身意外保險計劃",
  affinityProduct: "特約保險",
  cityU: "旅遊計劃 - 香港城市大學",
  uow: "旅遊計劃 - 香港澳大利亞伍倫貢書院",
  centalineHomeProtector: "中原家居保",
  centalineTravelProtector: "中原旅遊保",
  centalineDomesticHelperProtector: "中原家傭保",
  travelInsurance: "旅遊保險計劃",
  claimThankYou: "謝謝您",
  submitted: "您的索償已成功送出。",
  claimReferenceNumber: "索償索引編號：",
  chat: "與客戶服務員對話",
  email: "電郵",
  policyProvision: "保單條款",
  insuranceAdvisor: "保險顧問",
  qrcodescanner: "QR Code 掃描器",
  history: "記錄",
  instantClaim: "即時索償",
  settings: "設定",
  forDetailsOfTheProduct:
    "有關產品細節，不保事項及保費率，請參閱產品小冊子及保單條款",
  daysdays: "天",
  pleaseFill: "請填入金額",
  others: "其他",
  openPhoto: "開啟照片",
  premium: "保費",
  roundedToTheNearestPercentage: "*四捨五入至最近百分比",
  medicalExpenses: "醫療費用",
  personalAccident: "人身意外",
  currency: "貨幣",
  sex: "性別",
  male: "男",
  female: "女",
  referenceCode: "參考碼",
  assicurazioniGeneraliSpAReservesTheRightTo:
    "忠意保險有限公司(香港分行)保留接受或拒絕任何申請的權利。",
  iCerifyThatIHaveReadAndAgreedDisclaimerAndPersonal:
    "我/我們證明我/我們已閱讀並同意免責聲明和個人信息收集聲明。",
  iWeDoNotConsentPDto3PartyMarketing:
    "我/我們不同意提供個人資料被第三方作為行銷用途使用",
  individual: "個人",
  family: "家庭",
  first: "第 1 個",
  second: "第 2 個",
  third: "第 3 個",
  ordinal: "第 ",
  th: " 個",
  child: "子女",
  addAChild: "新增子女",
  questions: "問題",
  correspondenceAddress: "通訊地址",
  theInsured: "也是受保人",
  oneOfTheInsureds: "也是受保人之一",
  sufferedArea: "殘疾部位",
  diagnosis: "診斷",
  iWeCertifyDisclaimerAndPICS:
    "我 / 我們證明我/我們已閱讀並同意免責聲明和收集個人資料聲明。",
  houseOrApartment: "洋房 / 住宅",
  apartment: "住宅",
  anyInsuranceCompnayDeclined:
    "居所曾否被任何保險公司拒絕投保申請，取消保單或拒絕續保，或須附加特別條款始能被接納?",
  generaliTravelPackageInsurance: "忠意旅遊綜合保險計劃",
  bravoTravelProtector: "忠意旅遊保",
  bravoStorageProtector: "忠意存",
  bravoMobileDeviceProtector: "忠意手機保",
  insuredDetails: "受保人詳情",
  policyNumber: "保單編號",
  policyholderName: "保單持有人姓名",
  hkidNumberPassNumber: "香港身份證號碼 / 護照號碼",
  claimDetails: "索賠詳情",
  timeOfAccident: "意外時間",
  pleasePickTime: "請選擇時間",
  placeOfAccident: "意外地點",
  descriptionOfTheAccident: "事件詳細經過",
  wasTheAccidentReported: "上述意外有否交由警方調查？",
  dateOfFirstConsultation: "初診日期",
  documentsAttached: "附加文件",
  otherInsurance: "其他保險公司",
  doYouHaveOtherInsurance: "請問除本公司外，有否投保於其他保險公司？",
  nameOfInsurer: "保險公司名稱",
  typeOfCoverage: "投保種類",
  policyEffectiveDate: "保單生效日期",
  claimYes: "有",
  claimNo: "沒有",
  yourClaimApplication: "您的索償申請已收到。",
  messageCode: "訊息碼：",
  errorMessage: "錯誤訊息：",
  facingErrorWhenSubmitting:
    "糟糕！提交圖片的過程遇到一些問題。可以請您再試一次看看嗎？或是您也可以撥打我們的熱線6682-1112，並提供您的索償索引編號：xxx",
  isNotAValidHKID: "無效香港身分證",
  typeOfClaim: "索償類別",
  allowBothEnglishAndChinese: "可以以英文或中文輸入索償細節",
  claimNotValidEmail: "這不是正確的電郵地址",
  claimNotValidHKID: "這不是正確的香港身份證號碼",
  hospitalMedicalBillsReceiptReport: "住院醫療/醫藥費收據",
  accidentalDeath: "意外死亡",
  deathCertificate: "死亡證明",
  fieldsMissing: "仍有空白欄位",
  someBlankFields: "目前仍有些欄位未填寫。您還要送出索償嗎？",
  resumeFilling: "返回填寫",
  nameOfDoctorHospital: "醫生/醫院名稱",
  claimtheAboveIsRequiredToBeAgreed: "您必須同意上述聲明才能完成申請",
  claimsSpecialRequests: "其他/特別要求",
  declaration: "聲明",
  declaration1:
    "本人/吾等作出聲明，就本人/吾等所知及所信，上述資料均屬真確及並無遺漏。",
  declaration2:
    "本人/吾等授權任何醫院、醫生、診所、其他人士、有關官方或機構，向忠意保險有限公司(香港分行)（「忠意」）或其受權之代表提供所有有關本人之損失、受傷、症病、病歷、醫療診斷及藥方、警方報告、口供、所有醫院或醫療報告之副本。",
  declaration3: "本人同意所有文件及收據予忠意將不獲退還。",
  declaration5: "我/我們不同意提供個人資料被第三方作為行銷用途使用",
  declaration6: "我/我們不同意提供個人資料給貴公司作為行銷用途",
  reservesTheRight:
    "忠意保險有限公司 (香港分行) 保留權利向索賠人要求提交索償證明文件的正本。請提交索償後，保留正本至少3個月。",
  skip: "跳過",
  theTravelerCannot: "遊客不得為公司。",
  less: "減",

  // insurance product
  quote: "報價",
  quotationandconfirmpurchase: "輕鬆完成報價和購買確認",
  removeInsured: "移除受保人",

  // claims
  submitAClaim: "即時提交索償",
  helperNPersonal: "僱傭與人身意外保險",

  // claims Payment Screen
  validation: "驗證",
  validationFailed: "驗證失敗",
  recaptchaValidationFailed: "ReCAPTCHA 驗證失敗！",
  retry: "重新驗證",

  // roar us
  roarUsPhone: "電話聯絡",

  //ChatScreen
  seeMoreHistory: "顯示更多",
  typeAMessage: "在此輸入訊息……",
  chatAlert: "提示",
  unstableNetworkConnection: "網絡不穩定",
  sorryOurOfficeHours:
    "我們的辨公時間是星期一至五上午九時至下午五時三十分（公眾假期除外）。請閣下留下訊息或電話號碼或電郵，我們會在下一個工作天內回覆。",
  alternativelyYouMayCall:
    "閣下也可以在辨公時間內致電 (852) 6682 1112 或電郵 bravo@generali.com.hk 與我們聯絡。",
  ifYouHavePurchased:
    "***如果閣下購買了旅遊保險，身在外地並且遇上緊急情況，請致電二十四小時全球緊急支援服務熱線（852）3187 6888。",
  welcomeToChat: "歡迎與忠意保險客戶服務主任對話",

  // priviledge club
  welcomeOffer: "BRAVO 忠意保險迎新獎賞",
  generaliVoucherBalance: "忠意保險現金券餘額",
  generaliVoucherBalanceIs: "Generali 現金券餘額 = 港幣$",
  validUntil: "有效至 ",
  voucherTermsAndConditions: "條款及細則",
  voucherTermsAndConditions1:
    "1. Generali 現金劵僅限於「Bravo Generali」平台購物，透過手機應用程式付款時使用。自領取之日起1年內有效，逾期未使用自動失效。",
  voucherTermsAndConditions2:
    "2. Generali 現金劵，不可轉讓，亦不可兌換現金或其他貨品。如退保或者退款，獎賞所對應的金額不作為退款款項。",
  voucherTermsAndConditions3:
    "3. 本公司對本條款及細則擁有絕對解釋權及保留一切更改的絕對權利。如 有任何爭議，忠意保留最終決定權而毋須另行通知。",

  // priviledge club voucher
  failedToRedeem: "領取失敗",
  redeemSuccessfully: "成功領取",
  offerExpired: "抱歉！此優惠已過期。",
  canOnlyEnjoyOurOfferOnce: "抱歉！每位客戶只能享用一次我們的優惠。",
  forNewRegisteredMembers: "抱歉！此優惠只適用於新註冊會員。",
  notEligibleToThisOffer: "抱歉！您未能享有此優惠。",
  noLongerAvailable: "抱歉，此優惠不再生效。",
  voucherBalanceIsCredited: "恭喜！您的優惠卷已入帳，金額為港幣",
  youCanCheckYourBalanceAnytime: "元。您可以隨時於「專享禮遇」中查看餘額。",
  pleaseLoginWithYourAccount: "領取優惠卷之前，請先用您的帳號登入。",

  // history
  historyList: "記錄查詢",
  historyDetail: "記錄細節",
  notice: "通告",
  historyOfNotice: "通告記錄",
  noNoticeHistoryYet: "目前沒有通告記錄",
  purchase: "投保記錄",
  historyOfPilicies: "保單記錄",
  historyOfPiliciesForBroker: "您顧客所持有的保單",
  noPurchaseHistoryYet: "目前沒有投保記錄",
  claim: "索償記錄",
  historyOfClaim: "您送出的索償記錄",
  historyOfClaimForBroker: "您為您的顧客提交的索償記錄",
  noClaimHistoryYet: "目前沒有索償記錄",
  attachments: "附件：",
  policySchedule: "保單項目表",
  weaCard: "全球緊急支援咭",
  submitClaims: "遞交索償",
  of: " 的",
  hasBeenAccepted: "已經被接受並且自 ",
  period: " 生效。",
  periodII: " 提交。",
  tr: "［保險顧問：",
  tr2: "］",
  hasBeenSubmitted: "已成功提交於：",
  yourClaimOn: "您為 ",
  hasBeenSubmittedOn: " 的申請索償已於",
  accessDenied: "無法存取",
  cannotBeAccessed:
    "在我們確認付款成功之前，保單項目表暫時無法被存取。請稍後再試。",
  historyLeftPanelBrokerHeading0: "",
  historyLeftPanelBrokerContent0: "",
  historyLeftPanelBrokerHeading1: "",
  historyLeftPanelBrokerContent1: "",
  historyLeftPanelBrokerHeading2: "",
  historyLeftPanelBrokerContent2: "",
  historyLeftPanelHeading0: "",
  historyLeftPanelContent0: "",
  historyLeftPanelHeading1: "",
  historyLeftPanelContent1: "",
  historyLeftPanelHeading2: "",
  historyLeftPanelContent2: "",

  //broker
  instantEnrollment: "即時投保",
  beforeSendingTheLink:
    "在向我/我們的客戶發送推薦鏈接之前，我/我們已經獲得客戶同意。",
  invalidPromotionCodes: "無效的優惠代碼",
  shareYourReferralLink: "分享你的推薦鏈接",
  referralLink: "推薦鏈接",
  reminder: "請留意",
  youNeedToAgree: "在分享推薦鏈接之前，你需要先同意聲明。",
  chooseAgain: "重新選擇",

  //home
  quickLinks: "快速鏈接",
  homeTravel: "旅遊保險",
  promotion: "推廣",
  test: "測試中",
  homeInstantEnrollment: "保險產品",
  claims: "索償",
  pleaseAgree: "請您同意以上法律資訊與私隱資料的闡述。",
  toEnjoyTheBenefits:
    "想輕鬆查閱保單或索償記錄及體驗一按投保及索償，請即登記成為用戶。",
  forThoseAlreadyRegistered: " 登入（已註冊過會員）",
  continueWithoutRegistration: "不註冊繼續",
  youUndertake: "您接受並確認：",
  youHavePersonallyRead: "您已閱讀，明白且同意",
  theLegalInformation: "法律資訊",
  privacyStatement: "私隱資料",
  youCanAlsoChoose: "您可以選擇您偏好的語言",
  hellohello: "您好 ",

  //affinityProducts
  staff: "職員",
  student: "學生",
  planB: "計劃 B",
  planC: "計劃 C",
  numberOfAdults: "成人人數",
  numberOfChildren: "兒童人數",
  numberOfTravelers: "旅客人數",
  exchangeStudent: "交換學生",
  planE: "計劃 E",
  affinityPeriodOfTravel: "旅程日期",
  pleasePutInEnglish: "* 請以英文輸入 *",
  affinityPassport: "證件號碼",
  productType: "產品類別",
  staffNumber: "職員編號",
  studentNumber: "學生編號",
  facultyOrDepartment: "院系/學系",
  travelProgramUOWCollegeHK: "旅遊計劃-香港澳大利亞伍倫貢書院",

  //AmountInput
  aud: "澳元",
  eur: "歐元",
  gbp: "英鎊",
  hkd: "港幣",
  jpy: "日元",
  rmb: "人民幣",
  usd: "美元",

  //AttachmentLink
  productLeaflet: "產品小冊子",
  productLeaflets: "產品小冊子",
  policyProvision: "保單條款",
  policyProvisions: "保單條款",
  forDetailsOfTheProductAndExclusions: "有關產品細節及不保事項，",
  forDetailsOfTheProductExclusionsAndPremiumRates:
    "有關產品細節，不保事項及保費率，",
  forCompleteDetailsAndExclusions: "有關詳細資料及不保事項，請參閱",
  pleaseReferTo: "請參閱",
  productDetailsAnd: "及",
  productDetailsPeriod: "。",

  //DirectMarketingTickBox
  agree: "我/我們同意提供我/我們的個人資料作為",
  directMarketing: "行銷用途",
  // directMarketingTickBoxPeriod: '使用。',

  //DocumentAttached
  documentAttachedTitle: "選擇照片",
  takePhoto: "拍攝照片",
  chooseFromLibrary: "從相簿中選擇",
  image: "選擇圖像時出錯：",

  //IdentityInput
  pleaseEnterAllChars:
    "請輸入您的香港身份證的所有數字。例：您的香港身份證是A123456（7），請輸入A1234567。",

  //LegalDocumentLink
  disclaimer: "免責聲明",
  pic: "收集個人資料聲明",
  agreed: "我/我們已閱讀並同意",
  comma: "的重要聲明，",
  disclaimerComma: "，",
  personalInformation: "收集個人資料聲明",
  and: "和",
  andThe: "及",
  termsOfBusinessAgreement: "業務協議",
  termsOfBusinessAgreementWith: "",
  termsOfBusinessAgreementWith2: "的業務協議",
  legalDocumentLinkPeriod: "。",

  //PaymentBreakdown
  ecLevy: "僱員補償保險徵款",
  terrorCharge: "恐佈襲擊保費",

  //insurance-product
  //travel
  emergencyMedicalTransport: "緊急醫療運送",
  cancelTheJourney: "取消旅程/縮短旅程或更改旅程",
  newCancelTheJourney: "取消旅程",
  shortenTheJourney: "縮短旅程或更改旅程",
  travelDelay: "旅程延誤",
  cashAllowance: "現金津貼:",
  per500every6hours: "每6小時500 (上限: 2,000)",
  compensationForPaid: "賠償已繳付之",
  travelOr: "或",
  transportationAndAccommodationCosts2000: "交通及住宿費用: 2,000",
  transportationAndAccommodationCosts1500: "交通及住宿費用: 1,500",
  transportationAndAccommodationCosts1000: "交通及住宿費用: 1,000",
  delay: "500/每6小時延誤",
  personalBaggage: "個人行李",
  personalResponsibility: "個人責任",
  insuranceProductTravelWeaCard: "24小時全球緊急支援服務",
  standardPremium: "保費（5天個人計劃）",
  reference: "本頁只提供計劃概要作參考之用，並不構成保險合約的一部分。",
  reference2: "有關詳細資料及不保事項，請參閱產品單張及保單條款。",
  policyholderMustBeHongKongResident: "保單持有人必須為香港居民及18歲或以上。",

  //cyberScreen
  cyberInsurance: "忠意碌",

  //ahiScreen
  ahiInsurance: "忠意保住院現金保障計劃",
  ahiInsuranceProtectorIntroParagraph:
    "人生變幻無常，意外往往無法預見。一旦因意外受傷需要住院，可能產生高昂的治療費用。因此我們必須防範於未然，減低意外對您和家人可能帶來的影響及其財務負擔。",
  ahiInsuranceProtectorProductEdge1:
    "家庭計劃將保障所有家庭成員，從新生嬰兒到70歲的成年人均可受保",
  ahiInsuranceProtectorProductEdge2:
    "因意外受傷需要住院，由住院首日起每日為您提供高達港幣$500現金保障。如因嚴重傷患入住深切治療部病房，更可獲得雙倍現金津貼",
  ahiInsuranceProtectorProductEdge3:
    "首年延伸新型冠狀病毒(COVID-19) 保障: 如因感染新型冠狀病毒(COVID-19)，而入住深切治療部病房超過3日或身故，可獲港幣$50,000的一次現金賠償",

  //ASAPScreens
  secondarySchoolNUniversityIsATime:
    "許多學生於中學和大學時到海外學習，藉以擴闊視野。通過全年海外留學保障，您可以確保貴子女能安心地開展新學習旅程。",
  highMedicalExpenses: "醫療保障額高達HK$1,500,000 (包括 COVID-19)",
  hospitaliztionBenefitsInHK: "在香港的住院保障（新診斷的疾病或受傷）",
  twelveMonthsConsecutiveCover: "連續12個月無間斷保障",
  optionalCoverForAccompanied: "可自選附加保障陪同留學之父/母",
  zeroExcess: "所有保障均無自負金額",
  asapEpaPlanType: "產品類別",
  withAccompanying: "保障包括陪同的父親、母親或監護人",
  withoutAccompanying: "保障不包括陪同的父親、母親或監護人",
  locationOfTheOverseasStudyingInstitution: "海外留學的地區",
  usaCanada: "美國/加拿大",
  productType: "產品類別",
  thePolicyHolderMustBeThe: "保單持有人必須是學生的父親，母親或監護人。",
  insuredStudent: "受保學生",
  overseasStudyingInstitution: "海外留學學校",
  location: "地區",
  thePolicyHolderShallBe: "投保人應為受保學生的母親，父親或法定監護人。",
  thisPolicyExcludes:
    "本保險不包括承保危險職業工作職責的受保人（請參閱我們的條款中的定義）。",
  theInsuredShallBe:
    "受保人的身體狀況良好，並同意本保險不包括任何已存在的疾病。所有承保範圍均受條款和條件的約束。",
  assicurazioniGeneraliReservesTheRight:
    "忠意保險有限公司(香港分行)保留接受或拒絕任何申請的權利。",
  iWeCertifyGeneraliAsap:
    "我/我們證明我/我們已閱讀並同意全年海外留學保障的重要聲明。",
  iWeCertifyDisclaimerNPersonalInformation:
    "我/我們證明我/我們已閱讀並同意免責聲明和收集個人資料聲明。",
  iWeUnderstand:
    "我/我們理解、承認並同意，購買忠意保險有限公司(香港分行)所發行的保險，會給付佣金與傳遞我/我們的個人數據給官方保險經紀人，並且只會在安排和管理保單的持續期間使用。",
  asapLevy: "保費徵費",
  cardNumber: "信用卡號碼",
  expi: "到期日",
  mm: "月",
  optionalBenefit: "自選保障",
  thereIsAnOptional: "陪同的父親或母親。",
  personToBeInsured: "受保人",
  sameAsThePolicyholder: "保單持有人",
  otherPerson: "其他人",
  other: "其他",
  otherCountry: "其他國家",
  insuredHKID: "受保人香港身分證",
  insuredDateOfBirth: "受保人出生日期",
  instituteName: "名稱",

  //CentalineLandlordProtectorScreens
  comprehesiveProtectionOfYourRentalPropertyContent:
    "每個業主都需要全面保護您的物業，以免造成家居財物損失，業主法律責任和租金損失。為了保障你的物業資產及租金收入，忠意保險提供中原收租保以減低租賃房產的風險。更提供全港獨有中原金融團隊的專業協助收樓/追租服務。",
  productEdge1: "高達HK$500,000的財物損失保障",
  productEdge2: "高達HK$10,000,000的業主法律責任保障",
  productEdge3: "高達HK$200,000的租金損失保障",
  productEdge4: "自選附加樓宇保險",
  productEdge5: "全港專業協助收樓/追租服務，全程中原金融團隊專業人士代理",
  withHomeBuilding: "包括樓宇保險",
  withoutHomeBuilding: "不包括樓宇保險",
  sizeOfTheProperty: "物業面積",
  pleaseChooseTheNetSaleable: "請選擇實用面積平方呎",
  centalExecutivePersonalAccidentProtector: "中原收租保",
  productType: "計劃類型",
  centalineHome: "居所",
  HKAdressOnly: "地址(僅限香港地址)",
  centalineLandlordProtectorhouseOrApartment: "洋房或村屋",
  centalineLandlordProtectorApartmen: "村屋",
  theHomeBuildingIsMoreThan40years: "上述居所樓齡是否超逾40年",
  anyLegalActionAgainstTenantToRent:
    "上述居所曾否因收取欠租或收回物業而對上述居所之租客採取法律行動",
  anyDefeatOfRentalByTenantDuring12Months:
    "在過去的12個月內，被保險的房屋遭到任何敗訴予租戶",
  thisInsuranceDoesNotCoverCstOrGD: "此保險不包括老化及自然損耗。",
  thisInsuranceIsNotMaintenanceContract: "此保險不是一份維修合約。",
  validCOverProperyGoodOrder:
    "有效保障的先決條件是採取合理的步驟以有效地保護單位於良好狀態 以避免意外。",
  theInsuredHomeResidentalInHKBuliding:
    "被保之單位只適用於位於香港的住宅樓宇, 並僅限作住宅用途。",
  theInsuredHomebrickStones: "被保之單位包括屋頂全用磚、石和混凝土所建造。",
  allstructuresOfInsuredHomeBeLegal: "被保之所有單位樓宇結構必須合符法例建造。",
  proposerMustBeHKCitizen: "申請人必須為香港公民或持有有效香港身份證之居民。",
  thisInsurISNotPublicHousingEstate: "本保險不適用於公屋及劏房。",
  tenancyAgreement: "租約–與租客之租約必須已加蓋釐印。",
  section3RentProtection:
    "項目3–租金保險:受保人被拖欠1個月租金及於14日內必須向租客採取法律行動，要求租戶立即付款，並以書面通知忠意保險有限公司(香港分行)。",
  sectione4buildingMax:
    "項目4–樓宇保險:最高賠償限額可達投保保險金額或樓宇重建所需之費用，兩者以較低者為準。",
  assicurazioniGenerali:
    "忠意保險有限公司(香港分行)保留接受或拒絕任何申請的權利",
  anyLegalAction:
    "上述居所曾否因收取欠租或收回物業而對上述居所之租客採取法律行動",
  anyDefaultOfRental: "十二個月內有無任何房客租金欠收在被投保房屋。",

  //DomesticHelperInsuranceScreens
  asAResponsibleEmployer:
    "作為負責任的僱主，閣下必然想為家傭選購一份妥善全面的保險。忠意保險的家庭僱傭保險不僅提供既符合法律要求又全面的僱員補償保險，還提供讓您的家人安心的額外保障，保護家傭的健康。",
  centalineProductDescription:
    "作為負責任的僱主，閣下必然想為家傭選購一份妥善全面的保險。中原家傭保不僅提供既符合法律要求又全面的僱員補償保險，還提供讓您的家人安心的額外保障，保護家傭的健康。",
  comprehensiveCoverageFromBasicEmployee:
    "全面覆蓋家傭因工作的意外補償和醫療費用",
  coverHelpersDeath:
    "保障家傭於休息日因意外導致死亡或永久性完全傷殘-最高保障額為港幣10萬元",
  coverHospitalization: "保障家傭的住院醫療及意外牙科醫療費用",
  optionalOutpatientExenses: "自選門診費用保障-最高HK$5,000保障額",
  centalineProductEdge1: "全面覆蓋家傭因工作的意外補償和醫療費用",
  centalineProductEdge2:
    "保障家傭於休息日因意外導致死亡或永久性完全傷殘：最高保障額為港幣10萬元",
  centalineProductEdge3: "保障家傭的住院醫療及意外牙科醫療費用",
  centalineProductEdge4:
    "獨家補償新聘家傭之費用，無需為家傭行為失當或失蹤而擔心財務安排",
  centalineProductEdge5: "自選門診費用保障",
  centalineProductEdge6: "提供風水顧問費保障",
  withOutpatientBenefit: "有門診保障",
  withoutOutpatientBenefit: "沒有門診保障",
  oneYear: "1年",
  twoYears: "2年",
  hkidPassportNumber: "香港身份證或護照",
  residentialAddress: "住宅地址",
  domesticHelper: "家庭僱傭",
  countryOfOrigin: "原居地",
  workingLocationInHK: "在香港的工作地點",
  sameAsTheResidentialAddress: "與保單持有人的住址相同",
  thePlaceOfEmploymentIsInHK: "工作地點在香港",
  theHelperHasEverBeenRufusedForEnrollment:
    "家傭曾否於投保或續保僱員補償保險、意外保險、醫療保險或其他類型保險時，被拒絕或須附加特別條款始能被接納",
  theHelperIsReceivingOrContemplatingAnyMdeical:
    "家傭是否現正或將會接受某種醫療護理或手術，或需定期服用藥物超過三個月以上",
  theHelperIsFullTimeOverseasHelper: "家傭是全職的海外僱傭，只做家務",
  thisInsuranceIsAvailableForFullTimeOverseas:
    "此保險僅適用於全職海外家庭傭工，而非本地傭工。",
  thisInsuranceIsAppliedForFullTimeOverseas:
    "此保險只適用於一名全職海外家庭傭工。",
  proposerOrInsuredEmploerMustBeHK:
    "申請人/受保人（僱主）必須為香港公民或持有有效香港身份證之居民並年滿18歲。",
  theBenefitPayableUnderSection2:
    "項目2 -「人身意外保險」賠償將支付給上述家庭傭工或其法定代表人。",
  iHerebyDeclareThatTheBasicBenefitPayableUnderSection2:
    "我/我們在此聲明，我/我們理解並同意項目2-「人身意外保險」賠償將支付給上述家庭傭工或其法定代表人。",
  iCerifyThatIHaveReadAndAgreedImportantNotes:
    " 我/我們證明我/我們已閱讀並同意家庭僱傭的重要聲明。",
  applicationDeclined: "申請已撤銷",
  sorryThatYourApplicationIsDeclinedAsOnlyHKPlace:
    "很抱歉，你的申請被拒絕，因為我們只接受香港作為工作地點。",
  sorryThatYourApplicationIsDeclinedAsOnlyFullTimeOverseas:
    "很抱歉你的申請被拒絕，我們只接受只做家務的全職海外家傭。",
  reIput: "重新輸入",
  goBackHome: "回首頁",
  sriLanka: "斯里蘭卡",
  indonesia: "印尼",
  philippines: "菲律賓",
  singapore: "新加坡",
  malaysia: "馬來西亞",
  thailand: "泰國",
  nepal: "尼泊爾",
  bangladesh: "孟加拉",

  //EPAScreens
  sometimesEvenMinorAccidentsLongContent:
    "行政人員人身意外保障特設禮賓服務，為專注工作同時著重生活享受的您，提供二十四小時貼心私人服務專線讓您輕鬆處理生活日常事務",
  highBenefitLimits: "提供大額保障(高達HK$10,000,000的人身意外保障)",
  allInOnePackageWithPersonalAccident:
    "一份保障涵蓋個人意外，意外醫療費用及住院現金",
  competitiveFamilyPlan: "更實惠的家庭計劃",
  oneStopGenerali: "一站式禮賓服務專線",
  individualPlan: "個人計劃",
  familyPlan: "家庭計劃",
  productType: "產品類型",
  ndChild: "第 2 位子女",
  occupationClericalAndNonManualWork: "職業:文書和非體力勞動工作",
  fullTimeStudentUnmarriedAndUnemployed: "全職學生，未婚及沒有就業",
  haveYourOrOtherCoveredMembersAPPOflife:
    "閣下或其他受保家庭成員有否已投保意外、疾病、傷殘、醫療或人壽保險被拒保、延擱或撤銷或曾持有該種保險或證書，而於事後曾被修正、增加保費、取消、或被拒絕續保？",
  insurer: "投保人",
  benefit: "保障項目",
  sumInsured: "投保金額",
  reason: "原因",
  condition: "現狀",
  doYouOrOtherCoveredMemberHaveAnyPhysicalOrImpairment:
    "有任何身心疾病狀況或不適?",
  beneficiaryWillBeTheLE: "根據香港法例，受益人將是受保人的法定繼承人。",
  assicurazioniGeneraliSpAReserves:
    "忠意保險有限公司(香港分行)保留接受或拒絕任何申請的權利。",
  // iWECerrifyNotesOfIPAPI:'我 / 我們證明我/我們已閱讀並同意行政人員人身意外保障的重要聲明。',
  iWeHerebyAuthorizedAnyLicensed:
    "我/我們特此授權任何有註冊的醫生、醫院、診所或任何有關之醫療設施，保險公司或任何熟悉我/我們健康情況之組織或人士，將我/我們過往之病狀或病歷詳細資料提供予貴公司或貴公司之代表以作承保和索賠。我/我們還授權所有上述來源向貴公司僱用的任何機構提供此類記錄或知識，以收集和傳輸此類信息。",
  iWeDoNotConsentToTheProvision:
    "我/我們不同意提供個人資料被第三方作為行銷用途使用",
  // iWeDoNotConsentToTheUse: '我/我們不同意提供個人資料給貴公司作為行銷用途',

  //FireInsuranceScreens
  yourHomeLongContent:
    "您的家會是您和您的家人和親人花大部分時間的地方。忠意了解在家中感覺安全可靠的重要性，我們的火災保險旨在保護您免受因火災危險而造成的住宅房屋損壞和損失。它可以讓您快速重健您的家，回復正常生活。",
  coverBuildingSORB: "覆蓋住宅建築結構",
  ageOfBuiling: "保障高達50年的建築樓齡",
  productType: "產品類別",
  nnnnn: "",
  hkidNumber: "香港身份證號碼",
  mmmmm: "不是有效的身份證",
  beingTheOwnerOftheInsuredPremises: "受保單位的業主",
  insuredPremises: "受保單位",
  residentialOrCommercial: "住宅/商業",
  residential: "住宅",
  commercial: "商業",
  fireTheHomeBuildingIsMoreThan50years: "居所之樓齡是否超逾五十年?",
  fireSumInsuredAmount: "投保額(港幣)",
  pleaseInputHere: "請在此輸入",
  itCanBe:
    "投保額可以是100萬港元或以上的任何數字，亦可以未償還的按揭貸款金額作為保額。",
  ageOfBuilding: "房屋年齡",
  ageOfBuilding40: "0 - 40",
  ageOfBuilding45: "41 - 45",
  ageOfBuilding50: "46 - 50",
  ageOfBuildingAbove: "50以上",
  lessThanMinimum: "您的投保額低於最低金額（100萬港幣），請重新輸入。",
  postCopyToMortgagee: "您是否需要我們將保單文件副本寄給抵押權人？",
  needToPostCopyToMortgagee: "需要將保單文件副本寄給抵押權人",
  nameOfMortgagee: "抵押權人名稱",
  addressOfMortgagee: "抵押權人地址",
  iWECertifyThatReadAndAgreedNGPI:
    "我/我們證明我/我們已閱讀並同意火災保險的重要聲明。",
  sorryThatYourApplication:
    "很抱歉你的申請被拒絕，我們只接受受保單位的業主作為保單持有人。",
  sorryThatYour: "很抱歉你的申請被拒絕，我們只接受住宅受保單位。",
  notValidPromotionCode: " 不是火險的有效的優惠代碼。",
  fireIsUnoccupiedForLonger30Days: "居所會否連續多於三十日無人居住",

  //HomePackageInsuranceScreens
  oncePurchaseLongConent:
    "一旦購買，您的物業可能是您擁有的最有價值的資產，您未來的財務穩定取決於維持您的投資價值。忠意保險提供的家居綜合保險，保障全面，包括財物保險及第三者責任保險等，保護您生命中的重要資產，讓您高枕無憂。",
  comprehensiveCoverage: "高達HK$1,000,000的家居財物保障",
  legalLiability: "高達HK$7,500,000的公眾責任保險",
  homePackagePersonalAccident: "高達HK$250,000的人身意外保險",
  optionalBuildingInsurance: "自選附加樓宇保險",
  CompetitiveAnnualPremiumWithLowExcess: "實惠的每年保費和低自負金額",
  homePackageProductDescription:
    "中原家居保為您的安樂窩提供全面保障，包括家居財物保險、公眾責任保險、人身意外保險及樓宇保險，切合您不同的保險需要。",
  homePackageProductEdge1: "高達HK$1,000,000的家居財物保障",
  homePackageProductEdge2: "高達HK$7,500,000的公眾責任保險",
  homePackageProductEdge3: "高達HK$5,000的香港境內之金錢損失保障",
  homePackageProductEdge4: "高達HK$7,500,000的寵物第三者責任保障",
  homePackageProductEdge5: "為家中手提電話和手提電腦提供保障",
  homePackageProductEdge6: "提供風水顧問費保障",
  homeContentsOnly: "家居財物",
  homeContentsOnlyNBuilding: "家居財物及樓宇",
  homePackageSizeOfTheProperty: "居所面積",
  pleasChoose: "請選擇居所建築面積",
  below500: "500平方尺或以下",
  size500OfTheProperty: "500平方尺或以下",
  productType: "計劃類型",
  home: "居所",
  hongKongAddressOnly: "地址 (僅限香港地址)",
  convertNetFloorArea:
    "如閣下的單位沒有關於建築面積的資料，請將實用面積乘以1.25以計算出大概之建築面積。",
  forExample: "例子：實用面積400呎，乘以1.25，等如建築面積500呎。",
  pleaseChooseTheGrossFloorArea: "請選擇居所建築面積",
  homePackageTheHomeBuildingIsMoreThan40years: "居所之樓齡是否超逾四十年?",
  thisInsuranceDoesNotCoverTheCostOfGradualDeterioration:
    "此保險不包括老化及自然損耗。",
  thisInsuranceISNotAMaintenanceContract: "此保險不是一份維修合約。",
  theInsuredHomeIsOnlyApplicableToContent:
    "被保之單位只適用於位於香港的住宅樓宇, 並僅限作住宅用途。",
  theInsuredHomeISBuiltOfBricksStones:
    "被保之單位包括屋頂全用磚、石和混凝土所建造。",
  allStructuresOfTheInsured: "被保之所有單位樓宇結構必須合符法例建造。",
  proposerMustBeAHKCitizen: "申請人必須為香港公民或持有有效香港身份證之居民。",
  applicationWillNotBeAccepted: "本保險不適用於劏房。",
  section4Building:
    "項目4–樓宇保險:最高賠償限額可達投保保險金額或樓宇重建所需之費用, 兩者以較低者為準。",
  homePackageIWECertifyThatReadAndAgreedNGPI:
    "我/我們證明我/我們已閱讀並同意家居綜合保險的重要聲明。",
  iWECertifyThatReadAndAgreedPICS:
    "我/我們證明我/我們已閱讀並同意免責聲明和個人信息收集聲明。",

  //IPAScreens
  ipaSometimesEvenMinorAccidentsLongContent:
    "有時即使是輕微的事故也可能造成巨大的破壞。收入損失，延長康復和長期醫療可能會造成財務負擔。 忠意提供高度靈活的保險解決方案，具有用戶化的優勢，以滿足您的特定需求。",
  ipaHighMedicalExpenses: "高達HK$10,000,000的人身意外保障",
  ipaHospitaliztionBenefitsInHK: "投保前毋須體格檢驗",
  ipaTwelveMonthsConsecutiveCover:
    "保障項目廣泛：包括人身意外、意外醫療費用、骨折等",
  ipaOptionalCoverForAccompanied: "保單續保時，保額自動增值",
  ipaZeroExcess: "提供額外家居意外保障",
  plan1: "計劃一",
  plan2: "計劃二",
  plan3: "計劃三",
  tailorMadePlan: "自訂計劃",
  OrWithSpouse: "個人或個人及配偶",
  withSpouse: "與配偶",
  occupationOfTheInsured: "受保人職業",
  occupationOfTheSpouse: "配偶職業",
  individualAndSpouse: "個人及配偶",
  productType: "產品類別",
  a1234567: "A1234567",
  dateOfBirth: "出生日期",
  rightHanded: "右撇子",
  ipaHaveYourOrOtherCoveredMembersAPPOflife:
    "閣下或其他受保家庭成員有否已投保意外、醫療或人壽保險被拒保、延擱或撤銷或曾持有該種保險或證書，而曾被修正、增加保費、取消、或被拒絕續保？",
  ipaInsurer: "保險公司",
  ipaBenefit: "保障",
  ipaReason: "理由",
  ipaCondition: "情況",
  ipaDoYouOrOtherCoveredMemberHaveAnyPhysicalOrImpairment:
    "閣下或其他受保家庭成員之身體或精神有否任何殘缺？",
  policyholderAndInsuredsHaveValidHKID:
    "保單持有人及受保人必須持有有效的香港身份證，並在香港居住。",
  premiumChargedWillBeBasedOnTheInsured:
    "受保人之保費將按照閣下「職業類別」中之職業釐訂。",
  ifACoupleAppliesTogether:
    "如夫婦同時投保，每名子女均可獲贈投保人保障項目A1 & A2 百分之二十之保障額及保障項目A3、A5、A6、A7 全額保障。保障項目B 暫時完全傷殘及C 雙倍賠償則不適用。保障項目A1 意外死亡及永久傷殘受保額最高為港幣$200,000。",
  beneficiaryWillbeTheLegalEstateOfTheInsured:
    "根據香港法例，受益人將是受保人的法定繼承人。",
  ipaAssicurazioniGeneraliSpAReserves:
    "忠意保險有限公司(香港分行)保留接受或拒絕投保申請的權利。",
  iWECerrifyNotesOfIPAPI:
    "我/我們證明我/我們已閱讀並同意人身意外保險計劃的重要聲明。",
  ipaIWeHerebyAuthorizedAnyLicensed:
    "我/我們特此授權任何有註冊的醫生、醫院、診所或任何有關之醫療設施，保險公司或任何熟悉我/我們健康情況之組織或人士，將我/我們過往之病狀或病歷詳細資料提供予貴公司或貴公司之代表以作承保和索賠。我/我們還授權所有上述來源向貴公司僱用的任何機構提供此類記錄或知識，以收集和傳輸此類信息。",
  ipaNdChild: "子女 2",
  fullTimeStudent: "全職學生，且未婚與尚未工作",
  phone: "電話",
  class1: "類別一",
  class2: "類別二",
  class3: "類別三",
  class4: "類別四",
  nvolveMainlyIndoorWork: "(主要從事室內及非危險性的工作)",
  requiringOutdoorWork:
    "(主要從事室外、間歇性體力勞動或使用輕型工具或機械及非危險性的工作)",
  ofLightManualWorks: "(主要從事輕量體力勞動的工作)",
  ofExtraHazardousNature: "(主要從事危險性的工作)",
  coverageA1: "保障A1",
  coverageA2: "保障A2",
  coverageB: "保障B",
  coverageC: "保障C",
  coverageD: "保障D",
  accidentalDeathNPermanent: "意外死亡及永久完全或部份傷殘",
  ipaSumInsured: "請輸入保額",
  accidentalMedicalExpenses: "事故醫療開銷",
  temporaryTotalDisablement: "暫時完全傷殘保障",
  doubleIndemnity: "雙倍賠償",
  brokenBonesAndBurns: "骨折及燒傷",

  //TravelInsurance
  travelCanGiveYouIncredibleLongContent:
    "旅行可以為您帶來難忘體驗和終生回憶。忠意旅遊綜合保險計劃會在整個旅程中保護您和您的親人，讓您安心享受悠閒假期。",
  bravoTravelProtectorIntroParagraph:
    "旅行可以為您帶來難忘體驗和終生回憶。忠意旅遊保會在整個旅程中保護您和您的親人，讓您安心享受悠閒假期。",
  onlyForProductComparisonPurposes: "僅供產品比較用途",
  travelNote: "「",
  travelNote2: "」",
  coverLossOfMobilePhone: "保障手提電話",
  coverTerroistAct: "保障包括恐怖襲擊",
  travelZeroExcess: "毋須自負金額",
  coverAllKindsOfLeisureSports:
    "保障一切業餘及消閒活動(包括滑雪、水肺潛水、高空彈跳等)",
  bravoTravelProtectorProductEdge1: "保障手提電話",
  bravoTravelProtectorProductEdge2:
    '醫療保障額高達HK$1,500,000 (包括 COVID-19) \n <b>有關Covid保障範圍，請參閱<a href="http://sitecontent-dev.s3-website.ap-east-1.amazonaws.com/bravo-uat/Bravo%20Travel%20Protector%20%28FAQ%29.pdf" target="_blank" rel="noopener noreferrer">常見問題</a>。</b>',
  bravoTravelProtectorProductEdge3: "免費増加個人保障意外保障全覧表",
  bravoTravelProtectorProductEdge4: "擴大「旅程延誤」保障",
  bravoTravelProtectorProductEdge5:
    "保障業餘及消閒活動 (包括滑雪、水肺潛水、高空彈跳等)",
  annualTravel: "全年旅遊計劃的每次旅遊保障期長達120天",
  premier: "尊貴",
  classic: "優越",
  standard: "標準",
  shortTermTripAnnual: "單次旅遊計劃/全年旅遊計劃",
  shortTermTrip: "單次旅遊計劃",
  annual: "全年旅遊計劃",
  individualOrWithOthers: "個人或多人",
  individualNChildren: "個人及子女",
  groupOfIndividuals: "多人",
  addtionalUpgradePersonalAccidentBenefit: "星級個人意外附加保障",
  addtionalCruiseBenefit: "升級郵輪附加保障",
  total2: "共",
  flatDiscount: "定額折扣",
  finalTotal: "合共",
  periodOfTravel: "旅遊日期",
  productType: "產品類別",
  companyName: "公司名稱",
  mainlandPermit: "回鄉證號碼",
  travelHkidOrPassportNumber: "香港身份證或護照",
  travelPolicyholderIs: "保單持有人是",
  children: "子女",
  parents: "父母親",
  relatives: "親戚",
  friends: "朋友",
  haveThisTripInvolvedAnyCompetition:
    "這次旅行是否涉及娛樂表演，記者的職責，導遊/旅遊護送的職責，特技作品，施工現場或施工現場相關工程？",
  numberOfChinaMedicalGuaranteeCards: "中國醫療保障卡數量",
  thisPromotionCodeIsNotValid: "無效的優惠代碼",
  destination: "地點",
  travelOneOfTheTravelers: "其中一位遊客",
  travelNumberOfChildren: "子女數量",
  numberOfIndividuals: "遊客人數",
  chinaMedicalGuaranteeCard: "中國醫療保證卡",
  additionalChinaMedicalGuaranteeCard: "中國醫療保證卡附加保障",
  nullnull: "無效",
  travelSorryThatYourApplication: "很抱歉您的申請被拒絕",
  theAboveIsRequiredToBeAgreed: "您必須同意上述聲明才能完成購買",
  policyholderAndInsuredsMust:
    "保單持有人及受保人必須持有有效的香港身份證，並在香港居住。",
  theCoveredTripShouldOriginate:
    "本旅遊保險只適用於由香港出發的旅程，保障不適用於香港境內。",
  noRefundOfPremium: "一經發出保單，將不獲退還已支付的保費。",
  coverWillAutomatically:
    "若受保人因不能避免的情況下延期，本保險保障將自動延長最多10日。",
  thisInsuranceIsOnlyValid:
    "本旅遊保險僅適用於度假或商務旅行（僅限行政職責需要），不適用於導遊或領隊等工作。",
  allPRCCitizensWhoReside: "此保險不適用於中國公民於中國境內旅遊。",
  obtainingMedicalTreatment: "本旅遊保險並不覆蓋以醫療為目的的旅程。",
  thisInsuranceIsNotApplicable:
    "本旅遊保險不適用於從事藝術或任何娛樂相關活動。",
  theInsuredsShallBeInGood:
    "受保人的身體狀況良好，並同意本保險不包括任何已存在的疾病。",
  onlyStandardPlan: "只有標準計劃適用於17歲或以下的受保人。",
  assicurazioniGeneraliSpA:
    "忠意保險有限公司(香港分行)保留接受或拒絕任何申請的權利。",
  onlyStandardPlanIsApplicable: "只有標準計劃適用於17歲或以下的受保人。",
  worldwide: "全球（於保單中“排除地區適用條款”內所載之<不受保國家/地區>除外）",
  question: "問題",
  IWeCertifyThat:
    "我/我們證明我/我們已閱讀並同意忠意旅遊綜合保險計劃的重要聲明。",
  age17OrBelow: "很抱歉，這個年齡組別只能選擇標準計劃。",
  age0: "很抱歉，這個年齡組別只能選擇標準計劃。",
  canOnlyChooseTheStandardPlan: "未滿18歲僅能選擇標準計劃",
  notAvailableForAgeBelow18: "抱歉此計劃不適用於18歲以下人士",
  roundedTo: "四捨五入至最近百分比",
  policyholderIsACompany: "保單持有人為公司",

  //InstantClaimsScreens  HelperAndPersonalAccidentInsuranceScreen
  dateOfTravelIncident: "事發日期與時間",
  helperNPersonalAccidentInsurance: "僱傭與人身意外保險",
  insuredOccupation: "受保人職業",
  dateOfAccident: "意外日期",
  howWasItHappened: "說明事故是如何發生的",
  pleaseDescribe: "請述受傷部位及傷勢，如：骨折、刀傷、瘀腫等。",
  nameOfThePoliceStation: "警署地點",
  caseReferenceNumber: "報案編號",
  nameOfDoctorOrHospital: "醫生/醫院名稱",
  youHaveSuccessfullySubmitted: "您已成功提交您的索償！",
  failedOnSubmittingThisClaim: "提交索償失敗！",
  employeesCompensation: "僱員補償",
  claimPersonalAccident: "個人意外/住院",
  clinicalExpenses: "門診",
  claimMedicalExpenses: "意外醫療費用/永久完全或部分傷殘/意外住院現金",
  dateOfTreatment: "治療日期",
  diagnosisOfSickness: "疾病/傷害的診斷",
  nameAndAddressOfAttendingDoctor: "主治醫生的姓名和地址",
  claimAmount: "索賠金額",
  theClaimantIs: "索賠者是18歲以下",
  claimedAmount: "索償金額",
  aCopyOfForm: "表格2/2A/2B副本",
  originalMedicalReceipts: "住院醫療/醫藥費收據，如適用",
  theEmploymentContract: "受傷傭工之僱傭合約",
  completeDocumentationShowing:
    "完整文件證明顯示，最近受保人已經給付受傷傭工之定期付款與醫療費用金額",
  theCertificateOfCompensationAssessment:
    "補償評估証明書(表格5)及評估証明書(表格7)，如適用",
  policeMemo: "有關意外的警方備忘/口供供詞/報告，如適用",
  attendingDoctorStatement: "主診醫生報告",
  pleaseBeRemindedToMail:
    "有關住院索償，如果索償金額高於港幣5,000及其等值，請寄回相關醫療賬單正本到本公司。我們會先處理你提交之電子索償申請，在收到醫療賬單的正本後，我們方可發放索償金額。如你成功提交索賠申請後，將收到索賠參考編號。請將醫療賬單的正本寄回香港英皇道1111號太古城中心一期21樓，並在信封上註明索賠參考編號以作跟進。",
  proofOfRelationship: "關係保證（18歲以下索償者需提供）",
  claimsHelperFacingErrorWhenSubmitting: "提交索償遇到錯誤！",

  //InstantClaimsScreens HomeInsuranceScreen
  claimsHomeInsuredName: "受保人姓名",
  insuredHomeAddress: "受保地址",
  propertyDamageOrLoss: "財產損失/損毀/租金收入損失/樓宇保險",
  publicLiability: "公眾責任",
  dateOfIncident: "事發日期與時間",
  timeOfIncident: "事發時間",
  placeOfIncident: "事發地點",
  detailIncident: "意外描述",
  nameOfWitness: "目擊者姓名與聯絡資料（如有）",
  contactOfWitness: "目擊者聯絡資料(如有)",
  wasTheIncidentReported: "可有向警方報案？",
  claimsHomeNameOfThePoliceStation: "報案警署",
  policeReportNumber: "報案編號",
  hasAnyPrecautionaryMeasures: "在事故發生時，是否已作出任何安全措施?",
  pleaseAdviseInDetails: "請提供詳情",
  followingTheIncident: "在事故發生後，是否已作出任何應變措施以減低損失?",
  haveYouEverExperienced: "以往是否遇過類似性質的事故?",
  datesOfIncidents: "何時發生",
  nameOfThirdParty: "第三者姓名",
  contactOfThirdParty: "第三者聯絡資料",
  addressOfThirdParty: "第三者地址",
  natureAndExtentOfDamageOrInjuries: "受損或受傷之性質及程度",
  hasClaimBeenMade: "是否被要求賠償？",
  claimsHomeAmount: "索償金額",
  anyStepsBeenTaken: "受保人可有就事件作出任何妥協或賠償承諾",
  particularsOfMainContractor: "總承辦商或承辦商資料，如有",
  isThereAnyWorkByContract: "在事故發生時，是否有以合約形式的工作在進行中?",
  trade: "行業",
  contactNoOrEmail: "電話/電郵",
  theirRespectiveThirdParty: "其第三者保險之保險公司資料",
  nameOfInsuranceCompany: "保險公司名稱",
  detailedBreakdown:
    "申索項目詳情，包括購買收據及/或更換維修報價單、發票及收據",
  colouredPhotograph: "能顯示涉及地方/財物的損毀程度的彩色照片",
  incidentReportMadeByTheBuilding:
    "由物業管理處發出的事故報告書，內含事故發生之詳情、原因及其減輕損毀程度之措施",
  theCorrespondence:
    "有關的往來文件及信函，包括受保人、受保租客、管理處、相關人仕及人等(如屋宇署/食物環境衞生署聯合辦事處等)，如適用",
  otherRelevantInformation: "其他相關資料/文件",
  clamsDateOfAccident: "事發日期與時間",
  inCaseOfBurglary: "如屬盜竊，說明竊匪如何進出單位。",
  haveYouReportedTheIncident: "您是否已向警方報案？",
  areYouTheSoleOwner: "您是否損毀/損失財物的唯一物主？",
  otherOwners: "其他物主",
  ofTheOtherOwners: "其他物主",
  includingTheHirePurchase: "包含借款人",
  addAnotherOwner: "新增其他物主",
  areYouEntitled: "您是否有權就是次意外向其他保險公司索償？",
  nameOfInsuranceCompanyies: "列明保險公司的名稱及保單編號",
  respectivePolicyNumber: "保單編號",
  detailsOfCoverage: "保障項目",
  haveYouEverSustainedLosses: "您以往是否曾蒙受類似性質的損失？",
  detailsOfIncidents: "事件細節",
  haveYouEverMadeClaimUnder: "您以往是否曾就其他保險單索償？",
  details: "列明詳情",
  remarks: "備註",
  totalClaimAmount: "總索償金額",
  detailedBreakdownOfTheClaimedItems:
    "索償項目詳情細項，包括購買收據及/或更換維修報價單、發票及收據",
  colouredPhotographsOfTheVicinity: "能顯示涉及地方/財物的損毀程度的彩色照片",
  detailDescriptionOf: "意外描述",
  fullDescriptionOf: "損失財物的詳情資料",
  addAnItem: "新增項目",
  memoReportsAndOrStatements:
    "警方備忘/報告及/或由受保人/其家屬/相關人仕提供之供詞(只適用於申索與財物遺失/企圖爆竊/爆竊)",
  dulyStampedRentalAgreement:
    "已釐印(印花稅)之租務合約副本(只適用於中原收租保)",
  aCopyOfDuly: "已釐印之租務合約副本",
  theCorrespondencesAmong:
    "有關的往來文件及信函，包括受保人、受保租客、管理處、相關人仕及人等(如屋宇署/食物環境衞生署聯合辦事處等)，如適用",
  claimsHomeHowWasItHappened: "描述事件是如何發生的",
  pleaseDescribeThe: "請述受傷部位及傷勢，如：骨折、刀傷、瘀腫等。",
  claimsHomeCaseReferenceNumber: "案件參考編號",
  claimsHomeProofOfRelationship: "與受保人之關係證明",
  rentalIncomeLoss: " 損失租金收入/",
  includingBrandName: "包含品牌名稱、型號及產品編號。",
  dateOfPurchase: "購買日期",
  purchasePrice: "購買價值",
  item: "物品",

  //InstantClaimsScreens  TravelInsuranceScreen
  travelClaimsPolicyNumber: "保單編號/保單證書",
  dateOfTravelAccident: "事發日期與時間",
  pleaseEnter: "請輸入您的香港身份證的所有數字。",
  claimsForExample: "例: 您的香港身份證是A123456（7），請輸入A1234567。",
  stateHowTheLossDamageOccuredDiscovered: "說明發生/發現的損失/損壞情況",
  medicalExpense: "醫療費用",
  medicalExpenseReClaimDetails: "醫療費用索賠詳情",
  travelMedicalExpense: "意外醫療費用",
  injuryOrSickness: "受傷/疾病",
  injury: "受傷",
  sickness: "疾病",
  dateOfInjuryOrSickness: "受傷/生病日期",
  // ifInjury: '如果受傷，事故是怎麼發生的?',
  // ifSickness: '如果病了，疾病什麼時候開始出現?',
  // natureOfInjury: '受傷性質 / 病因',
  pleaseStateInDetail:
    "請詳述意外發生經過/如屬疾病個案，請說明病徵及首次出現病徵時間",
  claimsTravelAmount: "索償金額",
  flightTicketClaimsTravelAmount: "機票索償金額",
  hotelClaimsTravelAmount: "酒店索償金額",
  othersClaimedAmount: "其他索償金額",
  nameAndAddressOfDoctorOrHospital: "診治醫生/醫院名稱及地址",
  ifItWasAHospitalPeriod: "如醫院﹐留院時間為",
  pleasePickADateOfHospital: "請選擇留院時段",
  travelItinerary: "行程表/機票存根/登機證",
  pleaseBeReminded:
    "有關住院索償，如果索償金額高於港幣5,000及其等值，請寄回相關醫療賬單正本到本公司。我們會先處理你提交之電子索償申請，在收到醫療賬單的正本後，我們方可發放索償金額。如你成功提交索賠申請後，將收到索賠參考編號。請將醫療賬單的正本寄回香港英皇道1111號太古城中心一期21樓，並在信封上註明索賠參考編號以作跟進。",
  loss: "行李、旅遊文件、個人財物損失",
  lossClaimDetail: "行李、旅遊文件、個人財物損失索賠詳情",
  dateOfLossDamage: "遺失/損壞日期",
  timeOfLossDamage: "遺失/損壞時間",
  placeOfLossDamage: "遺失/損壞地點",
  reportedPoliceStation: "報案的警署/公共交通機構/酒店等的名稱及聯絡資料",
  firstReportedPlace: "第一事發地點",
  reportedPlace: "事發地點",
  contactInformation: "聯絡資訊",
  addAReportedPlace: "添加事發的地點",
  howTheLossDamageOccurredDiscovered:
    "損失/損壞物品之事發經過(例如物品擺放位置及發現意外的地點，時間和經過)",
  lostDamagedItems: "損失/損壞物品",
  claimsTravelTotalClaimAmount: "索賠總額",
  firstLostDamagedItem: "第一個遺失/損壞的物品",
  dateWhenLostDamagedItems: "損失/損壞日期",
  OriginalPurchaseValue: "原購買價錢",
  replacementRepairCost: "更換/維修成本",
  addALostDamagedItem: "添加遺失/損壞的物品",
  lossDamageReportIssuedByTheRelevantAuthorities:
    "客運/航空公司/酒店之事件証明/警方報案記錄",
  photosShowingTheExtent: "能顯示損毀程度的彩色照片，如適用",
  purchaseReceiptRepairQuotation:
    "有關的損失物件的購買收據、維修報價單/發票、重新購置收據，如適用",
  compensationBreakdownFromOtherInsurers:
    "列出由其他保險公司/第三者（如客運/航空公司）獲得的補償細項，如適用",
  journeyCancellation: "行程取消/縮短/更改",
  journeyCancellationClaimDetails: "行程取消/縮短/更改索賠詳情",
  dateOfOriginalSchedule: "原定行程日期",
  locationOfOriginalSchedule: "原定行程地點",
  dateOfCCRJourney: "行程取消/縮短/更改的日期",
  locationOfCCRJourney: "行程取消/縮短/更改的地點",
  reasonForJourneyCCR: "行程取消/縮短/更改的原因",
  ifTheJourneyCurtailmentIsDueTo:
    "如行程取消或提早結束旅程原因是因為受保人本人或受保人的直系親屬或親密的生意伙伴死亡、嚴重受傷或患病，請提供以下資料:",
  fullNameOfTheSickPerson: "死亡、受傷或患者姓名",
  relationshipToTheInsuredPerson: "與受保人關係",
  receiptShowingAnyPrepaidCosts: "旅費按金或額外交通及或住宿費用的收據",
  documentationIssuedByTravelAgent:
    "由旅遊代理/客運/航空公司發出的旅程取消証明文件並列出 “不獲退款/已退還金額”項目",
  travelBaggageDelay: "旅程延誤/行李延誤",
  travelBaggageDelayDetails: "旅程延誤/行李延誤索賠詳情",
  reasonForDelay: "延誤原因",
  flightDelayed: "航班延誤",
  missedConnection: "錯過轉乘班機",
  baggageDelayed: "行李延誤",
  whichAirport: "機場名稱",
  delayedFlightNo: "延誤航班編號",
  dateNTime: "日期及時間",
  pleasePickADateNTime: "請選擇日期與時間",
  emergencyEssentialItemsPurchased: "購買之緊急必需品",
  firstItem: "第一项索償物件",
  addAPurchasedItem: "添加索償物件",
  datePurchased: "購買日期",
  pricePaid: "購買金額",
  documentationIndicatingTheReasonForDelay:
    "由旅遊代理/客運/航空公司發出的証明信件並列明延誤原因及延誤時間",
  personalAccidentClaimDetails: "人身意外保障索賠詳情",
  clamsTravelDateOfAccident: "意外發生的日期",
  clamsTravelTimeOfAccident: "意外發生的時間",
  describeHowTheAccidentOccurred: "詳述意外發生的經過及所遭受的損傷",
  nameAndAddressOfTheAttendingDoctor: "診治醫生名稱及地址",
  permanentDisabilityDegreeNExtent: "永久傷殘的程度(如適用)",
  causeOfDeath: "死亡原因(如適用)",
  nameAndContactNumberOfWitness: "證人名稱及聯繫電話(如適用)",
  contactNumber: "聯繫電話",
  accidentReportIssuedByRelevantAuthorities:
    "客運/航空公司/酒店之意外報告/警方報案記錄及調查報告，如適用",
  medicalReportStatingTheDateOfAttendance:
    "由具有醫學資格，獲認可及合法授權從事疾病或意外受傷診療的人士發出的醫療報告並列明受傷程度及到診日期，如適用",
  copyOfDeathCertificate:
    "死亡證/驗屍、屍體解剖、事後的調查分析報告副本，如適用",
  careVisit: "親屬探望",
  careVisitClaimDetails: "親屬探望索賠詳情",
  nameOfVisitingFamilyMember: "探望之親屬名稱",
  durationOfVisit: "探望時間",
  claimsTravelProofOfRelationship: "與受保人之關係證明，如適用",
  receiptsOfTravelingAccommodationExpensesIncurred:
    "旅費、交通及/或住宿費用的收據",
  personalLiability: "個人責任",
  personalLiabilityClaimDetails: "個人責任索賠詳情",
  dateOfTheIncident: "事發日期",
  timeOfTheIncident: "事發時間",
  placeOfTheIncident: "事發地點",
  describeInDetailHowTheAccidentOccurred:
    "詳細描述事故是如何發生以及第三方財產損失/傷害發生的",
  fullNameOfTheThirdPartyClaimant: "第三者索償人姓名及聯繫方式",
  contactOfTheThirdPartyClaimant: "第三者索償人手提電話號碼",
  fullNameOfWitness: "證人的全名及聯繫方式(如適用)",
  claimsTravelContactOfWitness: "證人手提電話號碼(如適用)",
  copyOfIncidentReportFromRelevantParties:
    "由有關機構 (如：當地警方及法定機構、酒店、體育中心等) 發出的之事件報告副本",
  claimLetterAndInvoice: "對方的申索信件及發票",
  otherDocuments: "其他相關文件–請註明",
  anyLawsuit:
    "如收到任何第三者對有關事件的索償要求、法庭傳票、通告及書面命令，或涉及任何法律訴訟，切勿自行處理，應立即通知及提交本公司處理",
  noLiabilityShould:
    "未得到本公司事先同意前，不要向第三者承認任何責任或達成和解或付款承諾",
  othersClaimDetails: "其他索賠詳情",
  otherInsuranceCoverage: "請問除本公司外，有否投保於其他保險公司？",
  CityUniversityOfHongKong: "香港城市大學",
  uowCollegeHongKong: "伍倫貢大學",
  applicationJson: "提交數據",
  failedOnSubmitting: "提交索償失敗！",
  policyholderThe: "保單持有人",
  policy: "保單",
  number: "號碼",
  certificate: "/保單證書",
  employerName: "/僱員姓名",
  staffStudentNumber: "員工/學生編號",
  occupationPosition: "職業/職位",
  stationedCityCountry: "派駐城市/國家",
  homeResidingCountry: "定居國家",
  dateOfSecondment: "派駐至該城市/國家的日期",
  purposeOfTrip: "外遊目的",
  business: "商務",
  accidentHappen: "請詳述意外發生經過？",
  symptomsFirstAppear: "首次出現病徵的時間？",
  pleaseFillInDetails: "請填寫細節",
  relationshipToInsuredPerson: "與受保人關係",
  documentShowingTheStudentIdentity: "海外留學生證明文件，如學生證及/或入學信",
  medicalCertificateIndicatingDiagnosis:
    "醫療證明文件清楚指出受保人/其直系親屬/旅遊夥伴或緊密商業夥伴所患之病症及不適合外遊之理由，如適用",
  claimsTravelDeathCertificate: "死亡證，如適用",
  item: "物品",
  lostDamagedItem: "遺失/損壞的物品",

  //SettingsScreens
  createAccount: "建立帳戶",
  createBioAccount: "以生物識別登入",
  getLoginBySMS: "以SMS登入",
  getLoginByEmail: "以電子郵件登入",
  getConfirmationCode: "獲取確認碼",
  confirmationCodeSent: "確認碼已發送",
  confirmationCodeSentToYourEmailAddress: "確認碼已發送到您登記的電郵地址",
  confirmationCodeSentTo: "確認碼已發送到：",
  enterCodeBelow: "請在下方輸入六位數字確認碼",
  loginWithMobilePhone: "用您的手機登入",
  loginWithEmailAddress: "用您的電子郵件登入",
  welcome: "歡迎",
  loggedIn: "您已成功登入",
  loggedInTo: "您已成功登入 ",
  toBetterProtectYourPersonalData:
    "！\n為更好保障您的個人資料，我們建議您每次使用後登出。",
  accountCreated: "您已成功登記！",
  youHaveRegistered: "您已完成登記",
  alreadyRegistered: "這個帳號先前已完成登記。您可以使用這組電話號碼登入。",
  logout: "登出",
  languages: "語言",
  preferredLanguage: "語言偏好",
  pleaseRegisterFirst: "請先登記。",
  settingsTheAboveIsRequiredToBeAgreed: "您必須同意上述聲明才能完成申請",
  loginFailed: "登入失敗。請檢查你輸入的手提電話號碼及六位數字確認碼。",
  pleaseAcceptTheTermsAndConditions: "請您同意上述條款及細則。",

  // Insurance Advisor Screen
  insuranceAdvisorIs: "保險顧問：",
  notSpecifiedYet: "目前尚未指定",
  change: "更換",
  changeInsuranceAdvisor: "要更換保險顧問嗎？",
  advisorWouldYouLike: "你希望 ",
  advisorComma: " （來自 ",
  advisorToBeYourInsuranceAdvisor: "）成為你的保險顧問嗎？",
  requiredToLogin: "需要登入",
  advisorPleaseLogin: "在管理你的保險顧問前，請先登入！",
  product: "產品：",
  pleaseInputTheMobileNumber: "請輸入保險顧問的手機號碼",
  removeInsuranceAdvisor: "移除保險顧問",
  doYouWantToRemove: "你想要移除",
  questionMark: "的保險顧問嗎？",
  thanksAndWeWillInform: "謝謝你，我們會通知",
  accordingly: "。",
  pleaseCheckTheProductList: "請檢查保險顧問列表，並按確認。",

  advisorInvalidMobileNumber: "這不是有效的手機號碼",
  advisorWTheAboveIsRequiredToBeAgreed: "您必須同意上述聲明才能完成登記",
  advisorWTheAboveIsRequiredToBeAgreed2:
    "您必須同意上述聲明才能完成生物識別登入",

  // Developer Screen
  developerChecker: "開發人員檢查工具",
  host: "主機",
  token: "令牌",
  version: "版本",

  //Biometrics Authentication
  biometricpleaseRegisterFirst: "請先登記生物識別登入。",
  biometricsuccesstitle: "成功",
  biometricsuccessdesc:
    "多謝，面孔認證/ 指紋認證登入已啟用。請注意，如您手機中的面孔認證/ 指紋認證記錄有變更，您需要重新設定生物識別登入。",
  biometricskeepitactive: "保持使用",
  biometricsdisable: "停止用",
  biometricsterms: "使用生物識別登入服務的條款和條件",
  biometricsagress:
    "您已經閱讀，理解並接受上述使用生物識別登入服務的條款和細則",
  biometricssms: "驗証碼不正確",
  biometricauthenticationQA: "需要註冊生物識別登入嗎？",
  biometricauthentication: "生物識別登入",
  biometricauthenticationcancel: "取消生物識別登入",
  biometricauthenticationcanceldesc: "生物識別登入目前生效中",
  biometricauthenticationcancelno: "保持生效",
  biometricauthenticationcancelyes: "改為失效",
  biometricstermsdetail:
    "閣下必須確保只有閣下的面孔或指紋儲存於閣下的認可流動裝置以使用該裝置，閣下明白成功登記閣下的認可流動裝置後，任何儲存於閣下的認可流動裝置之面孔或指紋均能用作登入此應用程式。\n\n如閣下有同卵雙胞胎的兄弟姐妹或樣貌相近的兄弟姐妹，請閣下使用一次性密碼登入此應用程式而不建議使用面孔認證功能。\n請閣下避免停用流動裝置內已同意的設定，否則有機會危及使用登入驗證的安全性（例如，停用面孔辨識的“注視感知“設定）。\n\n您可以隨時在設定中停用生物識別登入服務。\n\n閣下明白認可流動裝置上的生物識別登入功能不是由我們提供，我們不就以下事項作出聲明或保證：任何認可流動裝置的生物識別登入功能的安全；該功能按製造商聲稱的方式運作。\n\n除法例禁止本公司排除或限制本公司責任的情況下，本公司概不為閣下因以下情況蒙受的損失負責：任何閣下使用或嘗試使用生物識別登入服務；或閣下的指示；或任何透過使用或連結生物識別登入服務之未經授權的交易。",
  biometricauthenticationlogin: "生物識別登入",
  biometricauthenticationloginYN: "面孔ID / 指紋ID",
  biometricauloginisneeded: "設定生物識別登入",

  // utils
  versionCheck: "版本檢查",
  youAreRunningAnOldVersion: "您正在使用過時的版本！",
  pleaseHeadTo: "請前往 ",
  toGetTheLatestVersion: " 安裝本應用程式最新的版本。",
  visit: "前往 ",

  //NetPromoterScore
  feedbackSubmitted: "意見已提交",
  thankYouForYourFeedback: "謝謝您的回饋！",
  feedback: "意見回饋",
  doYouLikeThisApplicationPlatform: "您喜歡這個應用程式/平台嗎？",

  //RPAscreen1
  bravoPassengerProtector: "忠意乘",
  bravoPassengerProtectorLeftPanel: "全面保障您的每段行程",
  bravoPassengerProtectorIntroParagraph:
    "創新的個人保障計劃，守護作為乘客的您。無論您選乘的是各式公共交通工具或私家車輛，忠意乘意外保提供全方位保障: 無限遠近覆蓋全球、24小時緊急支援服務等，讓您及家人安心享乘每段行程。",
  bravoPassengerProtectorProductEdge1:
    "可選購家庭保障計畫，由初生嬰兒至70歲都受保",
  bravoPassengerProtectorProductEdge2: "意外導致身故保障額高達HK$ 500,000",
  bravoPassengerProtectorProductEdge3:
    "意外導致醫療費用，每次意外保障額高達HK$ 5,000",
  bravoPassengerProtectorProductEdge4: "全年保障每天只需少於$1",
  Beneficiary: "根據香港法例，受益人將是受保人的法定繼承人。",
  policyholderIsTheInsured: "投保人也是受保人之一",
  addInsured: "新增受保人",
  IWeHereByAuthorizedAnyLicensedSuchInformation:
    "我/ 我們特此授權任何有註冊的醫生、醫院、診所或任何有關之醫療設施，保險公司或任何熟悉我/ 我們健康情況之組織或人士，將我/ 我們過往之病狀或病歷詳細資料提供予貴公司或貴公司之代表以作承保和索賠。我 / 我們還授權所有上述來源向貴公司僱用的任何機構提供此類記錄或知識，以收集和傳輸此類信息。",
  youHaveOneInsuredOnly: "您只有一位受保人，我們會將您改為個人計劃。",
  cntinueInputting: "繼續輸入其他受保人",

  //LifeInsuranceApplicationFormScreen
  lifeInsuranceApplicationForm: "人壽保險投保書",
  privateConfidential: "私人及機密",
  pleaseEnsureThatYouDisclose:
    "重要指示：請閣下確定能盡己所知於此投保書上提供所有重要資料，此重要資料將成為合約之根據。否則，所繕發之保單將告無效。若閣下不清楚某事實是否為重要資料，應在投保書上披露該等資料。此投保書必須填妥及於香港特別行政區簽署。",
  healthInformation: "健康資料",
  beenHospitalized:
    "閣下在過去 12 個月內曾否住院共超過 30 天？或閣下曾否被醫生通知患上任何未期疾病？或現正在進行舒緩和深切治療？",
  personalDetails: "個人資料",
  proposedInsuredParticulars: "準受保人資料",
  proposedPolicyholderParticulars: "準保單持有人資料",
  nameInEnglish: "英文姓名",
  nameInChinese: "中文姓名 ( 如適用 )",
  countryOfBirth: "出生國家",
  gender: "性別",
  nationality: "國籍",
  maritalStatus: "婚姻狀況",
  flatRoom: "房號",
  floor: "樓層",
  block: "座數",
  buildingEstate: "大廈 / 屋邨",
  roadStreet: "街道門牌",
  district: "分區",
  area: "地區",
  city: "城市",
  country: "國家",
  argentina: "阿根廷",
  australia: "澳洲",
  austria: "澳地利",
  bahrain: "巴林",
  belgium: "比利時",
  bermuda: "百慕達",
  brazil: "巴西",
  britishVirginIslands: "英屬維爾京群島",
  bulgaria: "保加利亞",
  canada: "加拿大",
  chile: "智利",
  china: "中國",
  cyprus: "塞浦路斯",
  czechRepublic: "捷克共和國",
  denmark: "丹麥",
  estonia: "愛沙尼亞",
  finland: "芬蘭",
  france: "法國",
  germany: "德國",
  greece: "希臘",
  holland: "荷蘭",
  hongKong: "香港",
  hungary: "匈牙利",
  india: "印度",
  indonesia: "印尼",
  ireland: "愛爾蘭",
  isleOfMan: "馬恩島",
  italy: "意大利",
  japan: "日本",
  latvia: "拉脫維亞",
  liechtenstein: "列支敦士登",
  lithuania: "立陶宛",
  luxembourg: "盧森堡",
  macau: "澳門",
  malaysia: "馬來西亞",
  malta: "馬耳他",
  newZealand: "新西蘭",
  norway: "挪威",
  philippines: "菲律賓",
  poland: "波蘭",
  portugal: "葡萄牙",
  qatar: "卡塔爾",
  romania: "羅馬尼亞",
  singapore: "新加坡",
  slovakia: "斯洛伐克",
  southKorea: "南韓",
  spain: "西班牙",
  sweden: "瑞典",
  switzerland: "瑞士",
  thailand: "泰國",
  taiwan: "台灣",
  unitedArabEmirates: "亞聯酋",
  uk: "英國",
  usa: "美國",
  vietnam: "越南",
  otherPleaseSpecify: "其他(請註明)",
  businessRegistrationNo: "商業登記號碼",
  hKIDPassport: "身份證 / 護照",
  single: "單身",
  married: "已婚",
  averageMonthlyIncome: "平均每月收入（港幣）",
  nameOfEmployer: "僱主名稱",
  businessNature: "業務性質",
  businessAddress: "辦事處地址",
  permanentAddress: "永久地址",
  sameAsResidentialAddress: "與住宅地址相同",
  contactTelephoneNo: "聯絡電話號碼",
  wantAnHardcopyToBeMailed:
    "保單會寄至您的電郵地址。您仍然想要一份額外的紙本保單寄至您的通訊地址嗎？",
  insuredIsThePolicyholder: "受保人是保單持有人",
  detailsOfInsurancePlan: "投保計劃詳情",
  basicPlan: "基本計劃",
  endowment: "Endowment",
  policyCurrency: "保單貨幣",
  sumAssured: "美金投保額",
  anyNumbersFrom2500To1M: "任何介於 $2,500 到 $1,000,000 之間的數字",
  premiumPaymentMode: "保費繳付方式",
  withOrWithoutPrepayment: "是否預繳第二年保費",
  threePercentGuaranteedInterestRate:
    "預繳可享有 3% 保證利率。細節請參見預繳建議書。",
  monthly: "月繳",
  quarterly: "季繳",
  singlePay: "整付",
  semiAnnual: "半年繳",
  annualPay: "年繳",
  prepayment: "預繳",
  prepaymentPremium: "每期 / 整付 / 預繳保費",
  ifNoOptionIsSelectedForNonMonthly:
    "注意事項：若未有選擇的非月繳保費，則使用郵寄賬單。",
  initialPremiumPaymentMethod: "首期保費繳付方式( 視乎產品所提供之選擇 )",
  itCanBeACombinationOfAFewMethods:
    "可以是數個方法的組合。支付可以在表單填寫完後立即進行，或在我們核准您的申請之後。",
  bankIn: "銀行存款",
  cheque: "支票",
  bankDraft: "本票",
  creditCardAutopay: "信用卡自動轉帳",
  creditCardOnlinePayment: "信用卡上線上支付",
  renewalPremium: "續期保費繳付方式",
  bankAutopay: "銀行自動轉帳",
  chequeOrBankDraftOrBankIn: "支票或本票或銀行存款",
  beneficiaryInformation: "受益人資料",
  sectionIsLeftBlank:
    "如留空此部分，所有保險權益將支付予準保單持有人，若準保單持有人尚存；否則全數將撥作準保單持有人的遺產處理。",
  oneBeneficiaryIsDesignated:
    "如受益人超過一人，除非在此列明各分配比例，否則所有保險收益將平均分配予各在生之受益人。",
  primarSecondary: "基本 / 次位",
  primar: "基本",
  secondary: "次位",
  beneficiaryInEnglish: "受益人英文姓名",
  beneficiaryInChinese: "受益人中文姓名",
  relationshipWithProposedInsured: "與準受保人關係",
  parent: "父母",
  employer: "雇主",
  creditor: "債權人",
  partner: "夥伴",
  iDCardPassportNo: "身份證 / 護照",
  share: "分配比例 (%)（合共百份之百）",
  beneficiary: "受益人",
  addBeneficiary: "添加受益人",
  proposedInsuredsInsuranceCoverage: " 準受保人保障範圍",
  doesTheProposedInsured: "準受保人現時是否已有或正在申請任何保險？",
  identificationOfThirdPartyInterest: "釐清第三者權益",
  proposedPolicyholderActingOn:
    "準保單持有人是否代表其他人（但不限於受託人，代名人或代理人）的名義行事而申請此保單？",
  provideDetailsOfTheBeneficialOwner:
    "請提供實益擁有人 / 負責人之詳細資料和有法律效力 / 權力行事之證明文件",
  replacementDeclaration: "轉保聲明",
  replacedInThePast12Months:
    "閣下是否於過去 12 個月內以這份投保申請書 / 建議書取代閣下任何現有壽險保單，或取代任何現有壽險保單內大部分的壽險成分？",
  intendToReplaceInTheNext12Months:
    "閣下是否打算於未來 12 個月內以這份投保申請書 / 建議書取代閣下任何現有壽險保單，或取代任何現有壽險保單內大部分的壽險成分？",
  maximumHeight: "最高幾米",
  issuedYear: "簽發年份",
  sumAssuredInLifeBenefit: "人壽保障投保額",
  criticalIllness: "危疾",
  hospitalIncome: "住院入息",
  addCoverage: "新增保障範圍",
  coverage: "保障範圍",
  pleaseGiveFullDetails:
    "請提供完整的詳細信息（包括確切的診斷，所接受的治療，最後的諮詢日期，當前狀況等）。",
  iWeDoNotConsentToTheProvisionOfThePersonal:
    "本人 / 我們不允許貴公司向本文所述的第三方提供個人資料作直接促銷用途。",
  iWeDoNotConsentToTheUseOf:
    "本人 / 我們不允許貴公司使用個人資料作直接促銷用途。",
  tickTheBoxesButSignTheForm:
    "(若閣下沒有在方格內加上剔號但簽署本表格 / 文件,閣下會被視之為不反對(即閣下允許)本公司使用或向第三方提供個人資料作直接促銷用途。)",
  iWeDeclareUS: "本人 / 我們聲明於簽署本聲明時並非美國稅務居民 。",
  residentForTaxPurposes: "本人/ 我們聲明於簽署本聲明時是美國稅務居民。",
  iWeAcknowledgeThatGenerali:
    "本人/ 我們確認忠意人壽可將所需資料轉移到香港境內及境外地區之稅務機關以遵守合規法案的責任，如適用時，本人/ 我們願意放棄所有禁止或限制該披露之權利。",
  taxpayerIdentification: "美國納稅人識別號碼",
  weAreHongKongResidentsForTax:
    "本人 / 我們謹此聲明,在簽署本聲明時,本人 / 我們是香港的稅務居民,而且本人 / 我們並非任何香港以外司法管轄區的稅務居民。",
  iWeTaxPurposes:
    "本人 / 我們謹此聲明,在簽署本聲明時,本人 / 我們是在香港以外的司法管轄區的稅務居民身份。",
  jurisdiction: "稅務聲明",
  jurisdictionOfResidence: "稅務居住地",
  taxpayerIdentificationNumber: "稅務編號",
  enterReasonTIN: "如沒有提供稅務編號，填寫理由 A、B 或 C",
  explainWhyTheAccount: "如選擇理由 B，請提供帳戶持有人不能取得稅務編號的原因",
  ifATINIsUnavailable: "如沒有提供稅務編號，必須填寫合適的理由 :",
  reasonA: "理由 A – 帳戶持有人的稅務居住地並沒有向其居民發出稅務編號。",
  reasonB:
    "理由 B – 帳戶持有人不能取得稅務編號。如選取這一理由,請提供帳戶持有人不能取得稅務編號的原因。",
  reasonC:
    "理由 C – 帳戶持有人毋須提供稅務編號。稅務居住地的主管機關不需要帳戶持有人披露稅務編號。",
  a: "A",
  b: "B",
  c: "C",
  transferAnyRequiredInformationToTheIRD:
    "本人 / 我們確認，忠意人壽可向香港稅務局轉交本表格所載資料，香港稅務局又可將這些將資料交換至香港以外的稅務部門； 本人 / 我們放棄任何本人 / 我們所擁有的關於禁止或限制上述資料披露之全部權利（如有）。",
  adviseGeneraliOfAnyChangeInCircumstances:
    "本人 / 我們承諾，如情況發生改變以致影響的本人 / 我們的稅務居民身份，或導致本表格所載的資料變得不正確，本人會通知忠意人壽，並會在情況發生改變後三十日內，向忠意人壽提交一份已適當更新的自我證明書。",
  addJurisdiction: "新增稅務聲明",
  understandAndAgreeTheDeclarationAndAuthorization:
    "本人 / 我們了解並同意聲明及授權。",
  agreeThatThisApplicationWillBeSubjectToTheApprovalOfGenerali:
    "本人 / 我們同意這項申請需經過忠意公司的核可。",
  nameOfCardIssuer: "發卡銀行名稱",
  countryOfIssue: "發卡國家",
  typeOfCreditCard: "信用卡類別",
  creditCardNumber: "信用卡賬戶號碼",
  creditCardExpiryDate: "信用卡到期日（月 / 年）",
  detailedDate: "日期 ( 日 / 月 / 年)",
  nameOfCardholder: "持卡人英文姓名",
  signatureOfCardholder: "持卡人簽名",
  asShownOnYourCreditCard: "(必須與閣下信用卡上的資料相同)",
  visa: "VISA",
  master: "萬事達卡",
  bankNameAndBranch: "銀行及分行之名稱",
  accountNoToBeDebited: "銀行賬戶之號碼",
  policyNumberDebtorReference: "保單號碼(付款人編號)",
  nameOfBankAccountHolder: "銀行賬戶持有人姓名",
  policyholdersName: "保單持有人姓名",
  addressOfBankAccountHolder: "銀行賬戶持有人地址",
  signatureOfBankAccount: "銀行賬戶持有人簽名",
  signatureMustAgreeWith: "(簽名必須與銀行賬戶檔案相同)",
  forBankUseOnly: "銀行專用",
  viewDetails: "檢視內容",
  signatureOfProposedPolicyholder: "準保單持有人簽署",
  notInHongKong: "不在香港境內",
  beforeYouSignYourName: "簽署之前，請先確認您的位置在香港境內。",
  failedToCheckYourLocation: "無法檢查您的位置",
  pleasePermitTheAppToAccessYourLocation:
    "請先開啟權限，讓 Bravo Generali 存取您的位置，並再嘗試一次。",
  ndmInEnglish: "* 請以英文填寫此份表單 *",
  ndmSelf: "本人",
  ndmPleaseUseTheSameAsMarkedOnYourHKIDPassport:
    "請使用您身份證 / 護照上的名字。",
  ndmResidentialAddressIsTheHomeAddress:
    "住宅地址是您一年中居住時間最長的居家地址。",
  ndmYourPolicyOrOtherCorrespondences:
    "您的保單或其他通訊文件會被郵寄至這個地址。",
  ndmMinimum10Maximum100: "至少10%，至多100%。",
  agreeToUsePersonalDataForSendingGiftVouchers:
    "本人 / 我們同意公司使用本人 / 我們的個人資料來寄送現金券贈禮或特別優惠。",
  ndmSave: "儲存當前進度",
  ndmSaved: "儲存成功",
  ndmBeenSaved: "您的申請已成功儲存。",
  ndmSignature: "簽署",
  ndmSignatureClear: "清除",
  ndmSignatureSave: "儲存",
  ndmSignatureSignYourName: "簽署您的名字",
  ndmSignatureWithinTheCanvas: "請在書寫範圍內簽署您的名字。",
  ndmSignatureNotSatisfied: "不滿意目前的簽署？",
  ndmSignatureSignAgain: "重新簽署",
  ndmSignatureDoYouWantToSignAgain: "您想要重新簽署嗎？",
  ndmNotRobot: "我不是機器人",
  ndmPleaseSmile: "請對著鏡頭微笑，以證明您是人類",
  ndmDetectAgain: "重新偵測",
  ndmPictureNotSatisfied: "不滿意目前的相片？",
  ndmVerified: "您已通過驗證",
  ndmSubmitForm: "現在您可以提交申請表了。您要現在提交嗎？",
  ndmSubmitLater: "稍後提交",
  ndmPersonalDetailsInsured: "個人資料 – 受保人",
  ndmSameAsPolicyholder: "同保單持有人",
  ndmAge: "年齡",
  ndmGender: "性別",
  ndmSmokingStatus: "吸煙狀況",
  ndmPersonalDetailsPolicyholder: "個人資料 – 保單持有人",
  ndmProposalDetails: "建議書資料",
  ndmCurrency: "貨幣",
  ndmUSD: "美元",
  ndmPaymentMode: "缴費方式",
  ndmAnnual: "年",
  ndmPlanDetails: "保障詳情",
  ndmPremiumPaymentTerm: "保費繳付年期",
  ndm2Years: "2 年",
  ndmPolicyTerm: "保障年期",
  ndm5Years: "5 年",
  ndmAnnualPremium: "每年總保費",
  ndmAnnualPremiumMinAndMax: "最少 2,500 - 最大 1,000,000 (美元)",
  ndmStandardAnnualPremium: "每年標準保費",
  ndmPayablePremium: "應繳保費",
  ndmModalPremiumStandard: "每期標準保費",
  ndmProposalOptions: "建議書選項",
  ndmNoOfYearForPDF: "保費預存賬戶年期",
  ndmInsuredName: "受保人",
  ndmPolicyholderName: "保單持有人",
  thanksForYourSubmission: "感謝您的申請。我們將在兩個工作天內與您聯繫。",

  //CancellationRightsAndRefundOfPremiumAndLevyScreen
  cancellationRightsAndRefundOfPremiumAndLevy:
    "取消投保權益及發還保費與保費徵費",
  iProposedPolicyholderUnderstandThatIHave:
    "本人作為準保單持有人，明白本人有權以書面通知要求取消保單及取回所有已繳保費及已繳保費徵費*（但不附帶任何利息）；唯本人必須親筆簽署該通知，並於保單交付本人或本人的代表後或通知書發予本人或本人的代表後起計二十一天內，以較先者為準，直接將其正本交予忠意人壽（香港）有限公司，地址為香港皇后大道東 8 號忠意保險大廈 5 樓。若本人曾獲得賠償，將不獲退款。",
  theInsuranceAuthorityCollects:
    "* 由 2018 年 1 月 1 日開始，保險業監管局透過保險公司向保單持有人收取保費徵費。",

  //PersonalInformationCollectionStatementScreen
  personalInformationCollectionStatement: " 收集個人資料聲明",
  fromTimeToTimeItIsNecessary:
    "閣下須要不時向忠意人壽（香港）有限公司（「本公司」）提供關於閣下自己、保單持有人、受保人、受益人、索償人及／或其他有關人士的資料（「個人資料」），以讓本公司為閣下提供保險及／或相關產品與服務，處理經由本公司發出及／或安排的保單之下的索償事宜，及／或處理閣下提出的任何或所有其他要求、查詢和投訴。",
  provisionOfThePersonal:
    "閣下是自願向本公司提供個人資料的。然而，若閣下未能提供個人資料，可能導致本公司不能夠為閣下提供保險及／或相關產品與服務，處理經由本公司發出及／或安排的保單之下的索償事宜，及／或處理閣下提出的任何或所有其他要求、查詢和投訴。",
  ThePurposesForWhich: "個人資料可被用於以下用途：",
  processingYourInsuranceApplication:
    "處理閣下的保險申請，安排並執行保險合約，並管理閣下在本公司的賬戶 ;",
  customerServices: "客戶服務及其他相關活動 ;",
  conductingData: "進行資料核對程序 ;",
  designingInsurance: "設計保險及／或相關產品與服務供客戶使用 ;",
  designingInsuranceAnd:
    "推銷本公司及／或本母公司及本集團的公司（下文合稱為「集團實體」）的保險及／或相關產品與服務 ;",
  directMarketingOfInsuranceAnd:
    "就閣下事前訂明的允許（如有）約束之下，直接促銷保險及／或其他相關產品與服務，而閣下可在任何時間知會本公司以行使撤回允許的權利 ;",
  statisticalOrActuarialResearch:
    "本公司、本集團實體、保險業協會或聯會、政府部門及／或監管機構的統計或精算研究 ;",
  complyingWithTheRequirements:
    "遵從任何法律、規則、規例、守則、指引、法院命令、合規政策和程序的規定，以及本公司及／或本集團實體應要遵守的任何其他有關規定，包括但不限於對客戶進行盡職審查及披露有關資料 ; 及",
  fulfillingAnyOther: "實現與上述（i）至（viii）直接有關的任何其他用途。",
  thePersonalDataHeldByTheCompany:
    "由本公司持有的個人資料將受到保密，但本公司可依據以上（c）段所列的用途向以下各方（不論在香港特別行政區境內還是境外）提供個人資料，事前無須知會閣下及／或該等個人資料所涉及的任何其他有關人士：",
  intermediariesClaimsServiceProviders:
    "與本公司的業務營運相關的中介人、索償服務提供商、共同保險公司、再保險公司、銀行及信用卡公司、健康及醫療機構、業務夥伴及／或任何其他有關各方，以適用者為準，向本公司提供行政、電訊、電腦、付款、推銷、調查、諮詢及／或其他服務 ；",
  relevantInsuranceIndustryAssociations:
    "相關的保險業協會或聯會，及／或該等協會或聯會的成員 ；",
  overseasLocations: "本公司及／或本集團實體的海外辦事處，以適用者為準 ；",
  personsToWhomTheCompany:
    "根據上述 (c) (viii) 的規定，本公司及／或本集團實體負有義務須向其作出披露的人士 ；",
  anyCourt:
    "根據任何法律約束之下，本公司及／或本集團實體須向其提供資料的任何法院、政府部門或監管機構（包括但不限於稅務局、保險業監管機構等）；",
  lawfulSuccessors: "本公司的合法繼承人或受讓人 ； 及",
  personsWhoOwe: "對本公司及／或本集團實體負有保密責任的人士。",
  theCompanyMayVerify:
    "本公司可使用由相關的保險業協會或聯會及／或該等協會或聯會的成員所收集及發放或轉移的資料，來核實任何或所有個人資料。",
  inAccordance: "根據《個人資料（私隱）條例》：",
  anyIndividual: "任何人均有權 ：",
  checkWhether: "查詢本公司有沒有持有其資料，如有的話，可取得一份該等資料 ；",
  requireTheCompany: "要求本公司改正其任何不正確的個人資料 ； 及",
  ascertainTheCompanysPolicies:
    "查明關於本公司的個人資料政策和處事常規，並可獲通知有關本公司所持個人資料的種類 ； 及",
  rightToCharge: "本公司有權就處理任何查閱個人資料的要求之下收取合理的費用。",
  thePersonToWhomRequests:
    "如欲查閱及／或改正個人資料及／或查詢關於本公司的政策和處事常規及所持個人資料的種類，請向以下人員提出要求：",
  personalDataProtectionOffice: "個人資料保護主任",
  generali: "忠意人壽（香港）有限公司",
  adress: "香港皇后大道東8號忠意保險大厦5樓",
  note: "附註 ： 本收集個人資料聲明的英文及中文版本之間如有任何歧義，概以英文版本為準。",
  useAndProvision: "使用及提供個人資料作直接促銷",
  thisSectionFormsPart: "( 本節條文是組成「收集個人資料聲明」的一部分。)",
  provisionOfConsent: "閣下在本節中提供的允許是自願的，並不會影響閣下的申請。",
  personalDataIncludingButNotLimited:
    "個人資料，包括但不限於，姓名、聯絡的詳細資料、其他產品及服務組合資料、交易模式及行為，財務背景及人口統計資料等可被用作於直接促銷本公司及本母公司及本集團的公司（下文合稱為「集團實體」）及／或由本公司所選定的第三方的保險及／或其他相關產品與服務 ；",
  personalDataMayAlsoBeProvided:
    "就以上（1）段所述的用途，個人資料亦可被提供予本集團實體及本公司所選定的第三方服務提供商，包括但不限於，電話服務中心。",
  theCompanyRequiresYourConsent:
    "本公司須獲閣下允許（包括表示不反對）本公司可按照本節條文所述的用途使用個人資料。若閣下不希望本公司使用或向第三方提供個人資料作直接促銷用途，閣下可於下方行使退出權利或於日後任何時間知會本公司。",
  foreignAccountTaxComplianceAct: " 海外帳戶稅收合規法案",
  foreignAccountTax:
    "在美國的《海外帳戶稅收合規法案》( “ 《合規法案》” ) 下，海外金融機構須就美國人於海外金融機構之非美國境內之帳戶，向美國國稅局匯報有關資料及取得客戶同意海外金融機構可向美國國稅局匯報有關資料。海外金融機構如未有簽署或同意遵守《合規法案》下的協議 ( 即 “ 《海外金融機構協議》” ) 有關之要求，及 / 或未曾獲得相關豁免遵守相關要求 ( 以上海外金融機構統稱為 “ 《不參與合規法案之海外金融機構》” ) ，其所有源自美國的付款中可預扣款項 ( 在合規法案中己闡明 ) 將被徵收百分之三十之預扣稅 ( “ 《合規法案預扣稅》” ) ( 初步包括紅利、利息及一些衍生款項 )。",
  agreedAnInterGovernmentalAgreement:
    "美國政府與香港政府已簽訂 ( “ 《跨政府協議》” ) 促使香港的海外金融機構遵守合規法案，及提供一個框架讓香港的海外金融機構能有效率的進行盡職審查以 (i) 識別美國身份標記，(ii) 徵求美國保單持有人同意披露及 (iii) 向美國國稅局匯報美國保單持有人相關稅務資料。",
  fATCAAppliesToGeneraliLife:
    "合規法案適用於忠意人壽 ( 香港 ) 有限公司 ( 下稱「忠意人壽」) 及此保單。忠意人壽是一間參與合規法案之海外金融機構，及致力遵守合規法案。因此，忠意人壽需要閣下 :",
  provideToGeneraliCertain:
    "提供相關資料予忠意人壽，如適用，包括閣下的美國身份証明資料 ( 如姓名、地址、美國聯邦納稅人識別號碼等 ); 及",
  consentToGenerali:
    "同意忠意人壽向美國國稅局匯報此資料及閣下之帳戶資料，( 如帳戶結存、利息、紅利收入及提款。)",
  ifYouFailToComplyWith:
    "如閣下未能遵從以上要求 ( 即為 “ 《不遵從合規法案之戶口持有人》” )，忠意人壽須向美國國稅局匯報帳戶結存、款項及不同意披露的美國帳戶數目之綜合資料。",
  inCertainCircumstances:
    "忠意人壽，在某些情況下，可能被要求在閣下保單付款中徵收合規法案預扣稅。現時忠意人壽只會在以下情況徵收合規法案預扣稅 :",
  inlandRevenueDepartment:
    "若香港稅務局未能與美國國稅局就跨政府協議 ( 及有關香港與美國之間的稅務資料交換協定 ) 交換資料，忠意人壽可能需要從閣下保單的可預扣款項中扣除及預扣合規法案之預扣稅及匯出予美國國稅局 ; 及",
  nonparticipatingFfi:
    "如閣下 ( 或任何一位帳戶持有人 ) 是不參與合規法案之金融機構，忠意人壽 ( 香港 ) 有限公司可能需要從閣下保單的可預扣款項中扣除及預扣合規法案之預扣稅及匯出予美國國稅局。",
  shouldSeekIndependentProfessional:
    "有關合規法案對閣下及閣下保單之影響，請諮詢獨立的專業意見。",
  proposedPolicyholder:
    "如果準保單持有人為個人，請填妥以下聲明以及提供所須的資料。如果準保單持有人為機構（包括但不限於信託或公司），該機構則不須填寫下列聲明，但其必須填妥另一份「海外帳戶稅收合規法案公司客戶聲明書」或「W-8BENE 表格」或「W-8IMY 表格」。",

  //AutomaticExchangeOfInformationScreen
  automaticExchangeOfInformation: "自動交換資料",
  underTheLaws:
    "根據實施的自動交換財務帳戶資料（「自動交換資料」）的法律、法規及國際協定，財務機構須辨別具有須申報外國稅務居民身份的帳戶持有人（包括某些帳戶持有人及保單受益人）和某些機構保單持有人的控權人，並向財務機構營運當地的稅務部門申報其稅務資料（包括但不限於姓名、地址、稅務居住地、該稅務居住地的稅務編號、帳戶結餘及收入資料）。當地稅務部門將每年定期把上述資料交予須申報外國稅務居民所屬稅務居住地的相關稅務部門。忠意人壽會將收集的稅務資料用於自動交換資料。這些資料以及其他關於帳戶持有人的資料可能會被傳遞給香港稅務局或其他本地或海外稅務部門用於轉交其他司法管轄區的稅務部門。有關香港實施自動交換資料的指南，請瀏覽香港稅務局網站 ：http://www.ird.gov.hk/chi/tax/dta_aeoi.htm。",
  theInformationRequired:
    "在本部分中收集的資料、關於閣下姓名和住址之資料和在第一部分中的出生日期，將共同組成用於自動交換資料的自我證明。根據《稅務條例》第80(2E) 條，如任何人在作出自我證明時，在明知一項陳述在要項上屬具誤導性、虛假或不正確，或罔顧一項陳述是否在要項上屬具誤導性、虛假或不正確下，作出該項陳述，即屬犯罪。",
  youMustReportAllChanges:
    "閣下必須在閣下的稅務居民身份發生任何變動後的30日內，向忠意人壽申報該等變動。",
  youShouldSeekIndependent:
    "閣下應就自動交換資料對閣下保單造成的影響，諮詢獨立的專業意見。",
  ifTheProposedPolicyholder:
    "如果準保單持有人為個人，請填妥以下聲明以及提供所須的資料。如果準保單持有人為機構（包括但不限於信託或公司），該機構則不須填寫下列聲明，但其必須填妥另一份「實體稅務居民身分自我證明表格」； 填妥後該表格會構成本投保書的一部分。",

  //DeclarationAndAuthorizationScreen
  declarationAndAuthorization: "聲明及授權",
  weAcknowledge:
    "本人 / 我們確認，本人 / 我們已獲提供一份由忠意人壽（香港）有限公司（「忠意人壽」）發出的收集個人資料聲明（「該聲明」）。本人 / 我們確認已經閱讀並且明白該聲明。本人 / 我們同意忠意人壽可依照該聲明的條款收集、使用、儲存、披露、轉移及以其他方式處理本人 / 我們的個人資料。本人 / 我們進一步確認，本人 / 我們已獲得受保人和任何其他有關人士（如適用的話）的明示同意，可以按照該聲明所述的用途將他們的個人資料提供給忠意人壽，並允許忠意人壽可依照該聲明的條款收集、使用、儲存、披露、轉移及以其他方式處理該等個人資料。",
  relevantIndividuals:
    "本人 / 我們確認，本人 / 我們已獲提供一份由忠意人壽發出有關《海外帳戶稅收合規法案》（“《合規法案》”）及自動交換財務帳戶資料（《自動交換資料》）的通知。本人 / 我們確認已經閱讀並且明白該《合規法案》及《自動交換資料》通知。本人 / 我們明白，根據有關的法律，任何美國稅務居民（定義於第十ー部分）就其稅務狀況作出虛假或失實陳述，可能會受到刑罰。若本人 / 我們的稅務狀況有更改，或成為美國人士，或者成為任何本人 / 我們未曾就其向忠意人壽進行申報的司法管轄區之稅務居民，本人 / 我們會於三十日內通知忠意人壽。",
  herebyDeclareAndAgree:
    "本人 / 我們在此聲明及同意，此投保書內所提供之一切陳述及資料，就本人 / 我們所知所信，均為事實之全部並確實無訛，及一切該等陳述及資料，將成為發出保單的根據，並作為保單一部份，並且明白若資料錯誤或不詳盡，可能導致保單之保障無效。本人 / 我們在此聲明，並無隱瞞任何足以影響忠意人壽衡量應否接受此投保書的事實（不論是否已包括在此投保書的問題內）及假如未能確定某些資料是否重要，則應將有關事實予以披露。",
  weAuthorizeGeneraliOrAny:
    "本人 / 我們授權忠意人壽或任何其委任之體檢醫生或化驗所，替本人 / 我們進行所需之醫療評估及測試，並對本人 / 我們之健康狀況進行審核及評估，作為處理本申請及其後與之有關的賠償事宜。如本人 / 我們不能提供任何此投保書所需的資料，忠意人壽可能因此不能處理此投保申請。本人 / 我們謹此授權任何註冊西醫、醫院、診所、保險公司及機構、其他組織或人士，凡知道或擁有有關本人 / 我們或本人 / 我們健康狀況之資料者，均可將該等資料提供給忠意人壽或其授權代表或再保險公司或仲裁機構以作評核本保險申請及其後與保單有關的賠償事宜之用。此授權文件之傳真或影印本皆與正本同樣有效。",
  proposedInsuredsHealth:
    "本人 / 我們同意在簽署此投保書後至收取保單前，若準受保人身體健康狀況 , 可保資料或其他重要資料有任何改變時，本人 / 我們會向忠意人壽如實披露。",
  iWeConfirmAndAcknowledgeThat: "本人 / 我們同意和確認 ：",
  iWeShallBeResponsibleForObserving:
    "本人 / 我們將有責任遵守任何有關司法管轄區之所有適用法律和法規之要求 ;",
  ifNecessary:
    "如有需要，本人 / 我們將徵詢獨立專業顧問有關購買、持有、撤銷、贖回或以其他方式處置所發保單或行使保單任何權利可能引致的財務、稅務、法律或法規上的後果。忠意人壽沒有就有關本人 / 我們之稅務或公民身份提供任何意見 ;",
  shouldIWeBeCompelledBy:
    "若本人 / 我們被任何司法管轄區之適用法律和法規要求贖回，退保或撤銷該保單，本人 / 我們願意承擔因此而引致的任何費用、損失或責任 ;",
  generaliShallBeEntitledTo:
    "忠意人壽有權，在法律許可的範圍內，提交或報告有關本人 / 我們的個人資料和其他有關本保單或申請的資料予有關政府部門、監管機構、法院、法庭、行政委員會及 / 或執法機構（包括本地及海外）( 統稱為「有關機構」）。忠意人壽也有權就上述有關機構所提出之任何查詢作出回覆，以符合任何司法管轄區適用之法律和法規要求。本人 / 我們明白和確認，如果本人 / 我們拒絕給予上述事項之明示同意予忠意人壽，忠意人壽將無法提供任何保險或相關產品和服務予本人 / 我們。",

  //CreditCardPaymentAuthorizationFormScreen
  creditCardPaymentAuthorizationForm: "信用卡付款授權書",
  pleaseFillInTheAppropriateBoxesAndInEnglish: "請填寫適當方格及用英文正楷填寫",
  iAuthorizeGeneraliLife:
    "本人授權忠意人壽 ( 香港 ) 有限公司由本人下列指定之信用卡賬戶扣除此投保申請 / 保單之應繳保費、費用及 / 或收費﹝如有﹞及保費徵費*，直至另行通知。",
  iunderstandAtLeast:
    "本人明白如需取消此付款指示，必須於最少兩個工作天前以書面提出。",

  //DirectDebitAuthorizationFormScreen
  directDebitAuthorizationForm: "直接付款授權書",
  nameOfPartyToBeCredited: "收款之一方（受益人）",
  bankNo: "銀行編號",
  branchNo: "分行編號",
  accountNoToBeCredited: "收款賬戶之號碼",
  ourBelowNamedBankToEffectTransfer:
    "本人 / 吾等現授權本人 / 吾等之下述銀行，自本人 / 吾等之賬戶內轉賬以支付有關此投保申請 / 保單之應繳保費、費用及 / 或收費﹝如有﹞及保費徵費* 予上述受益人，( 根據受益人不時給予本人 / 吾等銀行之指示 ) 自本人 / 吾等之賬戶內轉賬予上述受益人。",
  weAgreeThatMy:
    "本人 / 吾等同意本人 / 吾等之銀行毋須證實該等轉賬通知是否已交予本人 / 吾等。",
  weJointlyAndSeverallyAccept:
    "如因該等轉賬而令本人 / 吾等之賬戶出現透支（或令現時之透支增加），本人 / 吾等願共同及各別承擔全部責任。",
  shouldThereBeInsufficientFundsInMy:
    "本人 / 吾等同意本人 / 吾等之賬戶如無足夠款項支付該授權轉賬，本人 / 吾等之銀行有權不予轉賬，且銀行可收取慣常之收費，並可隨時以一星期書面通知取消本授權書。",
  authorizationShallHave: "本授權書將繼續生效直至另行通知為止。",
  noticeOfCancellationOrVariation:
    "本人 / 吾等同意，本人 / 吾等取消或更改本授權書之任何通知，須於取消 / 更改生效日最少兩個工作天之前交予本人 / 吾等之銀行。",

  // footer
  footerPIC: "個人資料收集聲明",
  footerPICHref:
    "https://www.generali.com.hk/ZH_HK/personal-information-collection-statement",
  footerLegalInformation: "法律信息",
  footerLegalInformationHref:
    "https://www.generali.com.hk/ZH_HK/legal-information",
  footerPrivacy: "隱私，安全和暫存紀錄",
  footerPrivacyHref: "https://www.generali.com.hk/ZH_HK/privacy",
  footerEmail: "信箱: bravo@generali.com.hk",
  footerPhone: "電話: +852 6682 1112",
  footerCopyright:
    "版權 © 2019 忠意人壽(香港)有限公司 及 忠意保險有限公司 香港分行",
  footerReserved: "版權所有",
  footerGroup: "忠意集團成員",
  footerGroupHref: "http://www.generali.com/",
  tryAgain: "我們的系統暫時不可用，請稍後再試 ",

  //special case
  paymentStopService1: "尊貴的客戶，",
  paymentStopService2:
    "由於我們現正進行系統更新，在7月8日晚上11:00至翌日7月9號晚上11:00，服務將會臨時暫停。不便之處，敬請原諒。",
  jointpolicyHolder: "聯名保單持有人",
  needjointpolicyHolder: "需要加入聯名保單持有人嗎?",
  needjointpolicyHolderII:
    " (請包括所有物業聯名持有人，你的按揭銀行亦可能要求保單上加入按揭擔保人或聯名貸款人，如有任何疑問請與銀行確認)",

  //nova home care
  novaPackageInsurance: "新域「家」安心",
  headernova1: "新域「家」安心",
  headernova2: "家居保險",
  novaPackageInsuranceLeftPanel: "一個全面家居保障，令生活自在無憂!",
  novaPackageInsuranceDetail:
    "無論您是收租業主,自住或租客，新域「家」安心都能為您和同住家人提供全面保障，包括住所內的家居財物，因颱風、火災、盜竊或水浸等意外導致的損失及損毀和第三者責任保障。我們提供兩個計劃級別及三項自選保障 (樓宇保障、停車位及電動汽車充電器責任保障和欺詐交易及網購保障)，以滿足不同客戶需要。",
  novaProductionPoint1: "高達HK$1,500,000的家居財物保障 (包括手提電話)",
  novaProductionPoint2: "高達HK$20,000,000的公眾責任保障",
  novaProductionPoint3: "高達HK$50,000的人身意外保障",
  novaProductionPoint4: "自選附加保障 - 樓宇保障",
  novaProductionPoint5: "自選附加保障 - 停車位(包括電動汽車充電器)責任保障",
  novaProductionPoint6: "自選附加保障 - 欺詐交易及網購保障",
  novaProductionPoint7: "實惠保費價格和低自負金額",
  novaplanType: "計劃級別",
  novaPlan1: "計劃1",
  novaPlan2: "計劃2",
  novaOtherBenefits: "附加保障",
  novaOtherBenefitsII: "自選保障",
  novaBuildingBenefits: "樓宇保障",
  novaEVCharger: "停車位(包括電動汽車充電器)責任保障",
  novaFraudulentCharger: "欺詐交易及網購保障",
  novaBuildInfo: "物業資料",
  novaBuildType: "物業類型",
  novaPropertyType: "物業類型",
  novaBuildTypeA: "多層住宅大廈",
  novaBuildTypeB: "獨立屋/村屋",
  novaAge: "樓齡",
  novaPropertySize: "物業面積",
  novaAgeRemark: "備註︰屋頂/花園/陽台/工作平台的建築物面積亦需要計算在內。",
  novaUnitAreaType: "單位面積類型",
  novaPracticalArea: "實用面積",
  novaBuildingArea: "建築面積",
  novaLivingArea: "居所面積（平方尺）",
  novaSubDescription:
    "此保險由忠意保險有限公司（香港分行）（本港獲授權的保險公司）承保並簽發，並由 Nova Insurance Consultants Limited安排。",
  novaEVChargerQA:
    "在停車位(包括電動汽車充電器)責任保障部分下是否需要添加物業管理公司名稱？",
  novaEVChargerQAII: "（如物業管理公司要求）",
  novaPropertyManagementCompanyName: "物業管理名稱",
  novaParkinglotaddress: "停車場地址",
  novaOption: "(請提供)",
  novaEVChargerExtra: "額外停車場位置的電動汽車充電器",
  novaSameParking:
    "*指定的停車場位置必須位於受保家居的同一屋苑或大廈內。",
  novaPropertyManagementCompanyNameII: "延伸受保人",
  novaSpecialRemark:"(如非有特定的附加要求，請勿填寫此欄位。否則需要進行審核，並不會即時批出保單。)",
  novaYear:"年",
  novaEvcert:"保險證明書",
};
