import React from "react";
import { Text, View } from "react-native";

import { Divider } from "@material-ui/core";
import {
  Button,
  DropDownList,
  Form,
  Heading1,
  YesNoQuestion,
} from "./../../Form";
import Locale from "./../../Locale";
import { Layout, Navbar } from "./../../Screen";
import ProductAPI from "./../../api/ProductAPI";
import DiscountInput from "./../components/DiscountInput";
import IntroParagraph from "./../components/IntroParagraph";
import LeafletAndProvision from "./../components/LeafletAndProvision";
import PlanType from "./../components/PlanType";
import ProductEdges from "./../components/ProductEdges";
import NovaScreen from "./NovaScreen";
var leaflet = "Product_leaflet_NovaHome_en.pdf";
export default class NovaScreen1 extends NovaScreen {
  componentDidMount() {
    leaflet =
      Locale.getLocale() === "zhHK"
        ? "Product_leaflet_NovaHome_ch.pdf"
        : "Product_leaflet_NovaHome_en.pdf";
    this.initialize();

    // GrossArea.initialize().then(() => {
    //   this.updateinsuredHome({ grossArea: GrossArea.grossAreas[0].id });
    // });
  }

  render() {
    const { pl, policy, insuredHome, payment } = this.state.insuranceProduct;

    return (
      <Layout alert={this.state.alert}>
        <Navbar screen={this} title={Locale.text.getAQuote} />

        {Locale.text.headernova1 + "\n" + Locale.text.headernova2}
        {Locale.text.novaPackageInsuranceLeftPanel}

        <View style={Layout.container}>
          <Form>
            <Heading1>
              {Locale.text.headernova1 + "\n" + Locale.text.headernova2}
            </Heading1>

            <IntroParagraph>
              {Locale.text.novaPackageInsuranceDetail}
            </IntroParagraph>

            <ProductEdges>
              {Locale.text.novaProductionPoint1}
              {Locale.text.novaProductionPoint2}
              {Locale.text.novaProductionPoint3}
              {Locale.text.novaProductionPoint4}
              {Locale.text.novaProductionPoint5}
              {Locale.text.novaProductionPoint6}
              {Locale.text.novaProductionPoint7}
            </ProductEdges>

            <LeafletAndProvision
              screen={this}
              leaflet={leaflet}
              provision={provision}
            />

            <PlanType
              product={"nova"}
              options={this.planOptions}
              value={pl}
              onChange={(pl) => {
                this.updatePlanCode(pl);
                this.updateinsuredHome({
                  areaType: "",
                  buildingAgeId: "",
                  homeAreaId: "",
                });

                ProductAPI.getHomeAreas(
                  { pn: "EAPP", pl: this.state.insuranceProduct.pl },
                  (msgDesc) => {
                    this.homeGrossAreas = [];
                    this.homeSaleableAreas = [];

                    console.log(msgDesc.homeAreas);
                    for (let i = 0; i < msgDesc.homeAreas.length; i++) {
                      if (msgDesc.homeAreas[i].areaType === "GROSS") {
                        this.homeGrossAreas.push({
                          value: msgDesc.homeAreas[i].id,
                          label:
                            Locale.getLocale() === "zhHK"
                              ? msgDesc.homeAreas[i].descZhHk
                              : msgDesc.homeAreas[i].descEnUs,
                        });
                      }
                      if (msgDesc.homeAreas[i].areaType === "SALEABLE") {
                        this.homeSaleableAreas.push({
                          value: msgDesc.homeAreas[i].id,
                          label:
                            Locale.getLocale() === "zhHK"
                              ? msgDesc.homeAreas[i].descZhHk
                              : msgDesc.homeAreas[i].descEnUs,
                        });
                      }
                    }
                    this.setState({ homeGrossAreas: this.homeGrossAreas });
                    this.setState({
                      homeSaleableAreas: this.homeSaleableAreas,
                    });
                  }
                );

                ProductAPI.getHomeBuildingAges(
                  { pn: "EAPP", pl: this.state.insuranceProduct.pl },
                  (msgDesc) => {
                    this.areaAgeTypeOptions = [];
                    console.log(msgDesc);
                    for (let i = 0; i < msgDesc.homeBuildingAges.length; i++) {
                      this.areaAgeTypeOptions.push({
                        value: msgDesc.homeBuildingAges[i].id,
                        label:
                          Locale.getLocale() === "zhHK"
                            ? msgDesc.homeBuildingAges[i].descZhHk
                            : msgDesc.homeBuildingAges[i].descEnUs,
                      });
                    }
                    this.setState({
                      areaAgeTypeOptions: this.areaAgeTypeOptions,
                    });
                  }
                );
              }}
            />
            <Divider />
            <Text style={{ fontSize: 18, textAlign: "center" }}>
              <u>
                <b>{Locale.text.novaOtherBenefits}</b>
              </u>
            </Text>

            <YesNoQuestion
              cozy
              label={Locale.text.novaBuildingBenefits}
              value={insuredHome.includeBuilding}
              onChange={(choice) =>
                this.updateinsuredHome({ includeBuilding: choice })
              }
              message={this.state.messages.insuredHome?.includeBuilding}
            />
            <YesNoQuestion
              cozy
              label={Locale.text.novaEVCharger}
              value={insuredHome.includeEV}
              onChange={(choice) =>
                this.updateinsuredHome({ includeEV: choice })
              }
              message={this.state.messages.insuredHome?.includeEV}
            />

            <YesNoQuestion
              cozy
              label={Locale.text.novaFraudulentCharger}
              value={insuredHome.includeFraudulent}
              onChange={(choice) =>
                this.updateinsuredHome({ includeFraudulent: choice })
              }
              message={this.state.messages.insuredHome?.includeFraudulent}
            />

            <Divider />
            <Text style={{ fontSize: 18, textAlign: "center" }}>
              <u>
                <b>{Locale.text.novaBuildInfo}</b>
              </u>
            </Text>

            <DropDownList
              cozy
              label={Locale.text.novaPropertyType}
              options={this.includeBuildingOptions}
              value={insuredHome.homeType}
              onChange={(choice) =>
                this.updateinsuredHome({ homeType: choice })
              }
              message={this.state.messages.insuredHome?.homeType}
            />
            {insuredHome.homeType === "H" && (
              <Text style={{ color: "#C50000" }}>
                {Locale.text.manualUnderwritingIsRequired}
              </Text>
            )}

            <DropDownList
              cozy
              label={Locale.text.novaAge}
              options={this.areaAgeTypeOptions}
              value={insuredHome.buildingAgeId}
              onChange={(choice) =>
                this.updateinsuredHome({ buildingAgeId: choice })
              }
              message={this.state.messages.insuredHome?.buildingAgeId}
            />

            {insuredHome.buildingAgeId === 7 ||
            insuredHome.buildingAgeId === 14 ? (
              <Text style={{ color: "#C50000" }}>
                {Locale.text.manualUnderwritingIsRequired}
              </Text>
            ) : (
              <></>
            )}

            <DropDownList
              cozy
              label={Locale.text.novaUnitAreaType}
              options={this.unitAreaTypeOptions}
              value={insuredHome.areaType}
              onChange={(choice) =>
                this.updateinsuredHome({ areaType: choice, homeAreaId: null })
              }
              message={this.state.messages.insuredHome?.areaType}
            />

            <DropDownList
              cozy
              label={Locale.text.novaLivingArea}
              disabled={!insuredHome.areaType}
              options={
                insuredHome.areaType === "S"
                  ? this.homeGrossAreas
                  : this.homeSaleableAreas
              }
              value={insuredHome.homeAreaId}
              onChange={(choice) => {
                this.updateinsuredHome({ homeAreaId: choice });
              }}
              message={this.state.messages.insuredHome?.homeAreaId}
            />

            <Text style={{ fontSize: 12, textAlign: "left", color: "blue" }}>
              {Locale.text.novaAgeRemark}
            </Text>

            {insuredHome.homeAreaId == 6 ||
            insuredHome.homeAreaId == 12 ||
            insuredHome.homeAreaId == 18 ||
            insuredHome.homeAreaId == 24 ? (
              <Text style={{ color: "#C50000" }}>
                {Locale.text.manualUnderwritingIsRequired}
              </Text>
            ) : (
              <></>
            )}

            <DiscountInput
              cozy
              disableBusiness={true}
              promotionCode={payment.promotionCode}
              businessEmail={policy.businessEmail}
              directCustomer={
                this.state.initialized &&
                !this.state.agent &&
                !this.state.broker
              }
              onChange={({ promotionCode, businessEmail }) => {
                this.updatePayment({ promotionCode });
                this.updatePolicy({ businessEmail });
              }}
              messages={this.state.messages.discount}
            />

            <Button
              disabled={this.state.waiting}
              onPress={() => this.getQuote("nova2")}
            >
              {Locale.text.getAQuote}
            </Button>
          </Form>
        </View>
      </Layout>
    );
  }
}

const provision = "Product_Provision_NOVA Home Care Insurance Policy.pdf";
