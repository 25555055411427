import React from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";

import Locale from "./../../Locale";

export default class PlanType extends React.Component {
  render() {
    let planTypes = [];

    this.props.options.forEach(({ value, label }, index) => {
      planTypes.push(
        <View
          key={value}
          style={{ minHeight: 60, backgroundColor: backgroundColors[index] }}
        >
          <TouchableOpacity
            style={styles.planType}
            onPress={() => this.props.onChange(value)}
          >
            <View style={styles.planTypeRadioButton}>
              {this.props.value == value ? (
                <Image
                  style={styles.planTypeRadioButtonImage}
                  source={radioButtonSelected}
                />
              ) : (
                <Image
                  style={styles.planTypeRadioButtonImage}
                  source={radioButtonUnselected}
                />
              )}
            </View>

            <View style={styles.planTypeLabel}>
              <Text style={styles.planTypeLabelText}>{label}</Text>
            </View>
          </TouchableOpacity>
        </View>
      );
    });

    return (
      <View>
        <View style={styles.planTypeTitle}>
          {this.props.product === "nova" ? (
            <Text style={styles.planTypeTitleText}>
              <b>
                <u>{Locale.text.novaplanType}</u>
              </b>
            </Text>
          ) : (
            <Text style={styles.planTypeTitleText}>{Locale.text.planType}</Text>
          )}
        </View>
        <View style={styles.planTypes}>{planTypes}</View>
      </View>
    );
  }
}

const backgroundColors = ["#8E1230", "#E9573D", "#F09273"];
const radioButtonSelected = require("./radio_button_selected.png");
const radioButtonUnselected = require("./radio_button_unselected.png");

const styles = StyleSheet.create({
  planTypeTitle: {
    marginTop: 5,
    marginBottom: 5,
  },
  planTypeTitleText: {
    fontSize: 18,
    marginTop: 10,
    marginLeft: 5,
    textAlign: "center",
  },
  planTypes: {
    marginTop: 10,
  },
  planType: {
    flex: 1,
    flexDirection: "row",
    height: 60,
  },
  planTypeRadioButton: {
    flex: 2,
    justifyContent: "center",
    alignItems: "center",
  },
  planTypeRadioButtonImage: {
    width: 20,
    height: 20,
  },
  planTypeLabel: {
    flex: 8,
    justifyContent: "center",
    alignItems: "flex-start",
  },
  planTypeLabelText: {
    color: "white",
  },
});
