export const enUS = {
  instantEnrollment: 'Quote',
  quotationandconfirmpurchase: 'Quote and confirm purchase in just a few clicks',
  travelInsurnace: 'Travel Insurance',
  bravoTravelProtector: 'Bravo Travel Protector',
  gereraliTravelPackageInsurance: 'Generali Travel Package Insurance',
  annualStudyAbroadProtector: 'Annual Study Abroad Protector',
  ussstudyplan: 'US Study Plan',
  ussstudyplanpopup:'<ol><li>l am eligible for one of the following: a)Undergraduate student &mdash; registered for and attending classes on a full-time basis; or b)Graduate student; or c)Scholar or researcher who is invited by an educational organization</li><li>I am not US citizen</li></ol>',
  homeInsurnace: 'Home Insurance',
  homePackageInsurance: 'Home Package Insurance',
  fireInsurance: 'Fire Insurance',
  domesticHelperInsurance: 'Domestic Helper Insurance',
  accidentInsurance: 'Accident Insurance',
  generaliPersonalAccidentInsurancePlan: 'Generali Personal Accident Insurance Plan',
  executivePersonalAccidentProtector: 'Executive Personal Accident Protector',
  bravoPassengerProtector: 'Bravo GO',
  ahiInsurance: 'Bravo Shield Hospital Income Plan',
  affinityProduct: 'Affinity Product',
  cityU: 'Travel Program (City University of HK)',
  uow: 'Travel Program (UOW College HK)',
  centalineLandlordProtector: 'Centaline Landlord Protector',
  centalineHomeProtector: 'Centaline Home Protector',
  centalineTravelProtector: 'Centaline Travel Protector',
  centalineDomesticHelperProtector: 'Centaline Domestic Helper Protector',

  // Payment Screen
  payment: 'Payment',
  submit: 'Submit',
  validationFailed: 'Validation Failed',
  recaptchaValidationFailed: 'Failed on validating the ReCAPTCHA!',
  retry: 'Retry',
  congratulations: 'Congratulations',
  madeAPurchase: 'You have successfully made a purchase. ',
  weWillSend: 'We will send your policy schedule and policy provisions to you via email ',
  anyQuestions: '. If you have any questions, please contact us.\nThank you.',
  ok: 'OK',
  home: 'Home',
  login: 'Login',
  logout: 'Logout',
  register: 'Register',
  page: 'Go Back',
  history: 'History',
  instantEnrollment: 'Quote',
  roarUs: 'Roar Us',
  privilegedClub: 'Privilege Club',
  instantClaims: 'Claims',
  settings: 'Settings',
  buy: 'Quote and Buy',
  just: 'Get a quote and confirm your purchase in just a few clicks.',
  borkermes: 'Insurance Advisor Functions.',
  ieborker: 'Get a quote and Buy for Your Clients',
  iemessages: 'Purchase policies for your clients in just a few clicks.',
  yes:'Yes',
  no:'No',

  //nova home care
  novaPackageInsurance: 'Nova 「Home」 Care Home Insurance',
}
