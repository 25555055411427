import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import BrowserDetection from 'react-browser-detection';
import moment from 'moment';

import './App.css';
import { memberStorage } from './lib/MemberStorage';
import Home from './screens/Home/Home';
import BuyAgain from './screens/Home/BuyAgain';
import Promotion from './screens/Home/Promotion';
import Iemessage from './screens/Home/Iemessage';
import Document29CScreen from './screens/Home/Document29CScreen';
import Document29EScreen from './screens/Home/Document29EScreen';
import Broker from './screens/Broker/Broker';
import InstantEnrollment from './screens/InstantEnrollment/InstantEnrollment';
import InstantClaimsScreens from './screens/InstantClaimsScreens/InstantClaimsScreens';
import HomePackageInsurance1 from './screens/InstantEnrollment/HomePackageInsurance/HomePackageInsurance1';
import HomePackageInsurance2 from './screens/InstantEnrollment/HomePackageInsurance/HomePackageInsurance2';
import HomePackageInsurance3 from './screens/InstantEnrollment/HomePackageInsurance/HomePackageInsurance3';
import HomePackageInsurance4 from './screens/InstantEnrollment/HomePackageInsurance/HomePackageInsurance4';
import FireInsurance1 from './screens/InstantEnrollment/FireInsurance/FireInsurance1';
import FireInsurance2 from './screens/InstantEnrollment/FireInsurance/FireInsurance2';
import FireInsurance3 from './screens/InstantEnrollment/FireInsurance/FireInsurance3';
import FireInsurance4 from './screens/InstantEnrollment/FireInsurance/FireInsurance4';
import ASAP1 from './screens/InstantEnrollment/ASAP/ASAP1';
import ASAP2 from './screens/InstantEnrollment/ASAP/ASAP2';
import ASAP3 from './screens/InstantEnrollment/ASAP/ASAP3';
import ASAP4 from './screens/InstantEnrollment/ASAP/ASAP4';
import USSP1 from './screens/InstantEnrollment/USSP/USSP1';
import USSP2 from './screens/InstantEnrollment/USSP/USSP2';
import USSP3 from './screens/InstantEnrollment/USSP/USSP3';
import USSP4 from './screens/InstantEnrollment/USSP/USSP4';
import IPA1 from './screens/InstantEnrollment/IPA/IPA1';
import IPA2 from './screens/InstantEnrollment/IPA/IPA2';
import IPA3 from './screens/InstantEnrollment/IPA/IPA3';
import IPA4 from './screens/InstantEnrollment/IPA/IPA4';
import EPA1 from './screens/InstantEnrollment/EPA/EPA1';
import EPA2 from './screens/InstantEnrollment/EPA/EPA2';
import EPA3 from './screens/InstantEnrollment/EPA/EPA3';
import EPA4 from './screens/InstantEnrollment/EPA/EPA4';
import RPAScreen1 from './insurance-product/rpa/RPAScreen1';
import RPAScreen2 from './insurance-product/rpa/RPAScreen2';
import RPAScreen3 from './insurance-product/rpa/RPAScreen3';
import RPAScreen4 from './insurance-product/rpa/RPAScreen4';
import CyberScreen1 from './insurance-product/cyber/CYBERScreen1';
import CyberScreen2 from './insurance-product/cyber/CYBERScreen2';
import CyberScreen3 from './insurance-product/cyber/CYBERScreen3';
import CyberScreen4 from './insurance-product/cyber/CYBERScreen4';
import AHIScreen1 from './insurance-product/ahi/AHIScreen1';
import AHIScreen2 from './insurance-product/ahi/AHIScreen2';
import AHIScreen3 from './insurance-product/ahi/AHIScreen3';
import AHIScreen4 from './insurance-product/ahi/AHIScreen4';
import AHIFScreen1 from './insurance-product/ahif/AHIFScreen1';
import AHIFScreen2 from './insurance-product/ahif/AHIFScreen2';
import AHIFScreen3 from './insurance-product/ahif/AHIFScreen3';
import AHIFScreen4 from './insurance-product/ahif/AHIFScreen4';
import HomeScreen1 from './insurance-product/home/HomeScreen1';
import HomeScreen2 from './insurance-product/home/HomeScreen2';
import HomeScreen3 from './insurance-product/home/HomeScreen3';
import HomeScreen4 from './insurance-product/home/HomeScreen4';
import NovaScreen1 from './insurance-product/nova/NovaScreen1';
import NovaScreen2 from './insurance-product/nova/NovaScreen2';
import NovaScreen3 from './insurance-product/nova/NovaScreen3';
import NovaScreen4 from './insurance-product/nova/NovaScreen4';
import FireScreen1 from './insurance-product/fire/FireScreen1';
import FireScreen2 from './insurance-product/fire/FireScreen2';
import FireScreen3 from './insurance-product/fire/FireScreen3';
import FireScreen4 from './insurance-product/fire/FireScreen4';
import DHScreen1 from './insurance-product/dh/DHScreen1';
import DHScreen2 from './insurance-product/dh/DHScreen2';
import DHScreen3 from './insurance-product/dh/DHScreen3';
import DHScreen4 from './insurance-product/dh/DHScreen4';
import DomesticHelperInsurance1 from './screens/InstantEnrollment/DomesticHelperInsurance/DomesticHelperInsurance1';
import DomesticHelperInsurance2 from './screens/InstantEnrollment/DomesticHelperInsurance/DomesticHelperInsurance2';
import DomesticHelperInsurance3 from './screens/InstantEnrollment/DomesticHelperInsurance/DomesticHelperInsurance3';
import DomesticHelperInsurance4 from './screens/InstantEnrollment/DomesticHelperInsurance/DomesticHelperInsurance4';
import CentalineLandlordProtector1 from './screens/InstantEnrollment/CentalineLandlordProtector/CentalineLandlordProtector1';
import TravelInsurance1 from './screens/InstantEnrollment/TravelInsurance/TravelInsurance1';
import TravelInsurance2 from './screens/InstantEnrollment/TravelInsurance/TravelInsurance2';
import TravelInsurance3 from './screens/InstantEnrollment/TravelInsurance/TravelInsurance3';
import TravelInsurance4 from './screens/InstantEnrollment/TravelInsurance/TravelInsurance4';
import Payment from './screens/InstantEnrollment/Payment';
import HelperAndPersonalAccidentInsurance from './screens/InstantClaimsScreens/HelperAndPersonalAccidentInsurance/HelperAndPersonalAccidentInsurance';
import TravelInsurance from './screens/InstantClaimsScreens/TravelInsurance/TravelInsurance';
import HomeInsurance from './screens/InstantClaimsScreens/HomeInsurance/HomeInsurance';
import Login from './screens/Settings/Login';
import Register from './screens/Settings/Register';
import Settings from './screens/Settings/Settings';
import InsuranceAdvisor from './screens/Settings/InsuranceAdvisor';
import Autore from './screens/Settings/Autore';
import Languages from './screens/Settings/Languages';
import PrivilegedClubList from './screens/PrivilegedClub/PrivilegedClubList';
import PrivilegedClubDetail from './screens/PrivilegedClub/PrivilegedClubDetail';
import History from './history/HistoryScreen';
import PurchaseHistory from './history/PurchaseHistoryScreen';
import RoarUs from './screens/RoarUs/RoarUs';
import HealthCheck from './screens/HealthCheck';
// import {isMobile} from 'react-device-detect';

export default class Generali extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this._resetTimeout();
    console.log(`navigator.userAgent`)
    window.onFocus = () => this._checkTimeout();
    
    window.onBlur = () => {
      if(navigator.userAgent.match(/android/i)||navigator.userAgent.match(/iphone/i)){
        memberStorage.getMember((member, token)=>{
          if(member){
            memberStorage.removeMember();
          }
        })
      }else{
        this._checkTimeout();
      }
    }

    window.onbeforeunload = () => {
      memberStorage.getMember((member, token)=>{
        if(member){
          memberStorage.removeMember();
        }
      })  
  }
    this.interval = setInterval(() => this._checkTimeout(), 1000 * 60);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  _resetTimeout() {
    this.timeout = moment().add(30, 'minutes').toDate();
  }

  _checkTimeout() {
    if (new Date() > this.timeout) {
      memberStorage.getMember((member, token) => {
        if (member) {
          memberStorage.removeMember(() => window.location = '/login');
        }
      });
    }
  }

  _onClick() {
    this._checkTimeout();
    this._resetTimeout();
  }

  
  render() {
    return (
      <Router>
        <div onClick={() => this._onClick()}>
          <Route exact path='/' component={Home} />
          <Route exact path='/buyagain' component={BuyAgain} />
          <Route exact path='/promotion' component={Promotion} />
          <Route exact path='/iemessage' component={Iemessage} />
          <Route exact path='/broker' component={Broker} />
          <Route path='/instant-enrollment' component={InstantEnrollment} />
          <Route path='/instant-claimsScreens' component={InstantClaimsScreens} />
          <Route path='/29C' component={Document29CScreen} />
          <Route path='/29E' component={Document29EScreen} />
          <Route path='/asap1' component={ASAP1} />
          <Route path='/asap2' component={ASAP2} />
          <Route path='/asap3' component={ASAP3} />
          <Route path='/asap4' component={ASAP4} />
          <Route path='/ussp1' component={USSP1} />
          <Route path='/ussp2' component={USSP2} />
          <Route path='/ussp3' component={USSP3} />
          <Route path='/ussp4' component={USSP4} />
          <Route path='/ipa1' component={IPA1} />
          <Route path='/ipa2' component={IPA2} />
          <Route path='/ipa3' component={IPA3} />
          <Route path='/ipa4' component={IPA4} />
          <Route path='/epa1' component={EPA1} />
          <Route path='/epa2' component={EPA2} />
          <Route path='/epa3' component={EPA3} />
          <Route path='/epa4' component={EPA4} />
          <Route path='/cyber1' component={CyberScreen1} />
          <Route path='/cyber2' component={CyberScreen2} />
          <Route path='/cyber3' component={CyberScreen3} />
          <Route path='/cyber4' component={CyberScreen4} />
          <Route path='/ahi1' component={AHIScreen1} />
          <Route path='/ahi2' component={AHIScreen2} />
          <Route path='/ahi3' component={AHIScreen3} />
          <Route path='/ahi4' component={AHIScreen4} />
          <Route path='/ahif1' component={AHIFScreen1} />
          <Route path='/ahif2' component={AHIFScreen2} />
          <Route path='/ahif3' component={AHIFScreen3} />
          <Route path='/ahif4' component={AHIFScreen4} />
          <Route path='/home1' component={HomeScreen1} />
          <Route path='/home2' component={HomeScreen2} />
          <Route path='/home3' component={HomeScreen3} />
          <Route path='/home4' component={HomeScreen4} />
          <Route path='/nova1' component={NovaScreen1} />
          <Route path='/nova2' component={NovaScreen2} />
          <Route path='/nova3' component={NovaScreen3} />
          <Route path='/nova4' component={NovaScreen4} />
          <Route path='/fire1' component={FireScreen1} />
          <Route path='/fire2' component={FireScreen2} />
          <Route path='/fire3' component={FireScreen3} />
          <Route path='/fire4' component={FireScreen4} />
          <Route path='/domestic1' component={DHScreen1} />
          <Route path='/domestic2' component={DHScreen2} />
          <Route path='/domestic3' component={DHScreen3} />
          <Route path='/domestic4' component={DHScreen4} />
          <Route path='/rpa1' component={RPAScreen1} />
          <Route path='/rpa2' component={RPAScreen2} />
          <Route path='/rpa3' component={RPAScreen3} />
          <Route path='/rpa4' component={RPAScreen4} />
          <Route path='/centaline1' component={CentalineLandlordProtector1} />
          <Route path='/gtravel1' component={TravelInsurance1} />
          <Route path='/gtravel2' component={TravelInsurance2} />
          <Route path='/gtravel3' component={TravelInsurance3} />
          <Route path='/gtravel4' component={TravelInsurance4} />
          <Route path='/payment' component={Payment} />
          <Route path='/helper' component={HelperAndPersonalAccidentInsurance} />
          <Route path='/travel' component={TravelInsurance} />
          <Route path='/home' component={HomeInsurance} />
          <Route path='/settings' component={Settings} />
          <Route path='/languages' component={Languages} />
          <Route path='/insuranceadvisor' component={InsuranceAdvisor} />
          <Route path='/autore' component={Autore} />
          <Route path='/login' component={Login} />
          <Route path='/privilegedClubList' component={PrivilegedClubList} />
          <Route path='/privilegedClubDetail' component={PrivilegedClubDetail} />
          <Route path='/history' component={History} />
          <Route path='/purchasehistory' component={PurchaseHistory} />
          <Route path='/roarUs' component={RoarUs} />
          <Route path='/register' component={Register} />
          <Route path='/healthcheck' component={HealthCheck} />

          <BrowserDetection>
            {{ ie: () => <Redirect to='/iemessage' /> }}
          </BrowserDetection>
        </div>
      </Router>
    );
  }
}
