import React from "react";
import { View } from "react-native";

import { Button, Form, Heading1 } from "./../../Form";
import Format from "./../../Format";
import Locale from "./../../Locale";
import { Layout, Navbar } from "./../../Screen";
import HengFaChuenChecking from "./../HengFaChuenChecking";
import CommissionsDisclosures from "./../components/CommissionsDisclosures";
import Confirmation from "./../components/Confirmation";
import DirectMarketing from "./../components/DirectMarketing";
import DisclaimerAndPIC from "./../components/DisclaimerAndPIC";
import ImportantNotes from "./../components/ImportantNotes";
import Payment from "./../components/Payment";
import SpecialRequestConfirmation from "./../components/SpecialRequestConfirmation";
import NovaScreen from "./NovaScreen";

export default class NovaScreen4 extends NovaScreen {
  constructor(props) {
    super(props);

    this.readPlanBroker().then((planBroker) => this.setState({ planBroker }));
  }

  render() {
    const {
      insuranceProduct: {
        agent,
        pl,
        policy,
        policyHolder,
        insuredHome,
        jointpolicyHolder,
        jointpolicyHolder1,
        jointpolicyHolder2,
        jointpolicyHolder3,
        payment,
      },
      messages,
    } = this.state;
    const { formatName, formatDate, getYesNoLabel } = Format;
    const manualUnderwriting =
      insuredHome.homeType == "H" ||
      insuredHome.grossArea > 5 ||
      insuredHome.isUnoccupied == "Y" ||
      insuredHome.isAnyLoss == "Y" ||
      insuredHome.isOldBuilding == "Y" ||
      insuredHome.isProposalDeclined == "Y" ||
      HengFaChuenChecking.check(insuredHome.fullAddress) ||
      !!policy.specialRequest;

    var carparkaddress = "";
    var optionBenef = "";

    optionBenef =
      insuredHome.includeBuilding === "Y"
        ? "• " + Locale.text.novaBuildingBenefits + "\n"
        : optionBenef;

    if (insuredHome.includeEV === "Y") {
      optionBenef =
        insuredHome.includeEV === "Y"
          ? optionBenef + "• " + Locale.text.novaEVCharger + "\n"
          : optionBenef;
    }

    if (insuredHome.includeEV === "Y") {
      carparkaddress = insuredHome.carparkSpaces
        ? "ㅤㅤㅤ1. " + insuredHome.carparkSpaces + "\n"
        : carparkaddress;

      carparkaddress =
        insuredHome.carparkSpacesI && insuredHome.numCarparkSpaces >= 1
          ? carparkaddress + "ㅤㅤㅤ2. " + insuredHome.carparkSpacesI + "\n"
          : carparkaddress;

      carparkaddress =
        insuredHome.carparkSpacesII && insuredHome.numCarparkSpaces >= 2
          ? carparkaddress + "ㅤㅤㅤ3. " + insuredHome.carparkSpacesII + "\n"
          : carparkaddress;

      carparkaddress =
        insuredHome.carparkSpacesIII && insuredHome.numCarparkSpaces >= 3
          ? carparkaddress + "ㅤㅤㅤ4. " + insuredHome.carparkSpacesIII + "\n"
          : carparkaddress;
    }

    if (insuredHome.includeEVYesNo === "Y") {
      optionBenef =
        optionBenef +
        "ㅤㅤㅤ" +
        Locale.text.novaPropertyManagementCompanyNameII +
        ": " +
        insuredHome.propertyMgtName +
        "\n";
    }
    if (insuredHome.includeEV === "Y" ) {
      optionBenef =
        optionBenef +
        "ㅤㅤㅤ" +
        Locale.text.novaParkinglotaddress +
        ":\n" +
        carparkaddress;
    }
   
    optionBenef =
      insuredHome.includeFraudulent === "Y"
        ? optionBenef + "• " + Locale.text.novaFraudulentCharger
        : optionBenef;

    return (
      <Layout alert={this.state.alert}>
        <Navbar screen={this} title={Locale.text.getInsured} />

        {Locale.text.headernova1}
        {Locale.text.headernova2}

        <View style={Layout.container}>
          <Form>
            <Heading1>
              {Locale.text.headernova1 + "\n" + Locale.text.headernova2}
            </Heading1>

            <Confirmation
              data={[
                {
                  label: Locale.text.insuranceAdvisorMobile,
                  value: agent.mobile,
                },
                {
                  label: Locale.text.novaplanType,
                  value: this.getDesc(this.planOptions, pl),
                },
                {
                  label: Locale.text.novaOtherBenefitsII,
                  value: optionBenef ? optionBenef : null,
                },
                // {
                //   label: Locale.text.novaPropertyManagementCompanyNameII,
                //   value: insuredHome.propertyMgtName,
                // },
                // {
                //   label: Locale.text.novaParkinglotaddress,
                //   value: carparkaddress,
                // },
                {
                  label: Locale.text.effectiveDate,
                  value:
                    formatDate(policy.effectiveDateStart) +
                    " - " +
                    formatDate(policy.effectiveDateEnd),
                },
                {
                  label: Locale.text.promotionCodes,
                  value: payment.promotionCode,
                },
                {
                  label: Locale.text.businessEmailMembership,
                  value: policy.businessEmail,
                },
                {
                  label: Locale.text.policyHolder,
                  value: this.props.isCompany
                    ? policyHolder.organization
                    : formatName(
                        policyHolder.salutation,
                        policyHolder.firstName,
                        policyHolder.lastName
                      ),
                },
                {
                  label: Locale.text.jointpolicyHolder,
                  value:
                    jointpolicyHolder.isJointpolicyHolder === "Y"
                      ? formatName(
                          jointpolicyHolder1.salutation,
                          jointpolicyHolder1.firstName,
                          jointpolicyHolder1.lastName
                        )
                      : null,
                },
                {
                  label:
                    policyHolder.documentType == "HKID"
                      ? Locale.text.hkid
                      : Locale.text.passport,
                  value: this.props.isCompany
                    ? undefined
                    : policyHolder.documentNo,
                },
                {
                  label: Locale.text.dateOfBirth,
                  value:
                    this.props.isCompany || !policyHolder.dateOfBirth
                      ? undefined
                      : formatDate(policyHolder.dateOfBirth),
                },
                {
                  label: Locale.text.mobileNumber,
                  value: policyHolder.mobile,
                },
                {
                  label:
                    this.props.addressLabel ||
                    Locale.text.correspondenceAddress,
                  value: policyHolder.address1,
                },
                {
                  label: Locale.text.emailAddress,
                  value: policyHolder.email,
                },
              ]}
            />

            <Confirmation
              heading={Locale.text.home}
              data={[
                {
                  label: Locale.text.address,
                  value: insuredHome.fullAddress,
                },
                {
                  label: Locale.text.novaPropertySize,
                  value:
                    insuredHome.areaType === "S"
                      ? this.getDesc(
                          this.homeGrossAreas,
                          insuredHome.homeAreaId
                        ) +
                        " " +
                        this.getDesc(
                          this.unitAreaTypeOptions,
                          insuredHome.areaType
                        )
                      : this.getDesc(
                          this.homeSaleableAreas,
                          insuredHome.homeAreaId
                        ) +
                        " " +
                        this.getDesc(
                          this.unitAreaTypeOptions,
                          insuredHome.areaType
                        ),
                },
                {
                  label: Locale.text.novaPropertyType,
                  value: this.getDesc(
                    this.includeBuildingOptions,
                    insuredHome.homeType
                  ),
                },
                {
                  label: Locale.text.novaAge,
                  value:
                    this.getDesc(
                      this.areaAgeTypeOptions,
                      insuredHome.buildingAgeId
                    ) +
                    " " +
                    Locale.text.novaYear,
                },

                {
                  label: Locale.text.postCopyToMortgagee,
                  value: getYesNoLabel(insuredHome.isPostPolicy),
                },
                {
                  label: Locale.text.theHomeIsUnoccupiedForLonger30Days,
                  value: getYesNoLabel(insuredHome.isUnoccupied),
                },
                {
                  label:
                    insuredHome.buildingAgeId === 4 ||
                    insuredHome.buildingAgeId === 5 ||
                    insuredHome.buildingAgeId === 6 ||
                    insuredHome.buildingAgeId === 7 ||
                    insuredHome.buildingAgeId === 11 ||
                    insuredHome.buildingAgeId === 12 ||
                    insuredHome.buildingAgeId === 13 ||
                    insuredHome.buildingAgeId === 14
                      ? Locale.text.anyLossDamageLiabilityOrAccidentII
                      : Locale.text.anyLossDamageLiabilityOrAccident,
                  value: getYesNoLabel(insuredHome.isAnyLoss),
                },
                {
                  label:
                    Locale.text.homePackageTheHomeBuildingIsMoreThan40years,
                  value: getYesNoLabel(insuredHome.isOldBuilding),
                },
                {
                  label: Locale.text.anyInsuranceCompnayDeclined,
                  value: getYesNoLabel(insuredHome.isProposalDeclined),
                },
              ]}
            />

            <SpecialRequestConfirmation policy={policy} />

            <ImportantNotes>
              {
                Locale.text
                  .thisInsuranceDoesNotCoverTheCostOfGradualDeterioration
              }
              {Locale.text.theInsuredHomeIsOnlyApplicableToContent}
              {Locale.text.theInsuredHomeISBuiltOfBricksStones}
              {Locale.text.allStructuresOfTheInsured}
              {Locale.text.applicationWillNotBeAccepted}
              {Locale.text.assicurazioniGeneraliSpAReservesTheRightTo}
              {Locale.text.policyholderMustBeHongKongResident}
            </ImportantNotes>

            <DisclaimerAndPIC
              screen={this}
              product={this.getPlanCodeLabel()}
              planBroker={this.state.planBroker}
              value={policy.disclaimerAndPIC}
              onChange={(disclaimerAndPIC) =>
                this.updatePolicy({ disclaimerAndPIC })
              }
              message={messages && messages.disclaimerAndPIC}
            />

            <DirectMarketing
              screen={this}
              value={policy.allowPrivacyPromote}
              onChange={(allowPrivacyPromote) =>
                this.updatePolicy({
                  allowPrivacyPromote,
                  allowPrivacyPromote3P: allowPrivacyPromote,
                })
              }
              message={messages && messages.allowPrivacyPromote}
            />

            <CommissionsDisclosures
              agent={this.state.agent}
              broker={this.state.broker}
              promotionCode={payment.promotionCode}
              value={policy.commissionsDisclosures}
              onChange={(commissionsDisclosures) =>
                this.updatePolicy({ commissionsDisclosures })
              }
              message={messages.commissionsDisclosures}
            />

            <Payment
              manualUnderwriting={manualUnderwriting}
              broker={this.state.broker}
              payment={payment}
              onChange={(ecash) => this.updatePayment({ ecash })}
              premium={this.getParam("premium")}
              levy={this.getParam("levy")}
            />

            <Button
              disabled={this.state.waiting}
              onPress={() => this.confirm()}
            >
              {Locale.text.submit}
            </Button>
          </Form>
        </View>
      </Layout>
    );
  }
}
