import Request from './Request';

export default class ApplyAPI {
  /* 3.1 投保家居保險 */
  static home(data, onSuccess, onFail) {
    Request.post('/apply/home', data, onSuccess, onFail);
  }

  /* 3.2 投保外籍傭工保險 */
  static dh(data, onSuccess, onFail) {
    Request.post('/apply/dh', data, onSuccess, onFail);
  }

  /* 3.3 投保全年海外留學保障 */
  static asap(data, onSuccess, onFail) {
    Request.post('/apply/asap', data, onSuccess, onFail);
  }

  /* 3.4 投保火險 */
  static fire(data, onSuccess, onFail) {
    Request.post('/apply/fire', data, onSuccess, onFail);
  }

  /* 3.5 投保收租保 */
  static landlord(data, onSuccess, onFail) {
    Request.post('/apply/landlord', data, onSuccess, onFail);
  }

  /* 3.6 投保單次旅遊保險 */
  static singleTravel(data, onSuccess, onFail) {
    Request.post('/apply/travel/single', data, onSuccess, onFail);
  }

  /* 3.7 投保全年旅遊保險 */
  static annualTravel(data, onSuccess, onFail) {
    Request.post('/apply/travel/annual', data, onSuccess, onFail);
  }

  /* 3.8 投保行政人員意外保險 */
  static epaPa(data, onSuccess, onFail) {
    Request.post('/apply/pa/epa', data, onSuccess, onFail);
  }

  /* 3.9 投保個人意外保險 */
  static ipaPa(data, onSuccess, onFail) {
    Request.post('/apply/pa/ipa', data, onSuccess, onFail);
  }

  /* 3.13 投保意見回饋 */
  static feedback(data, onSuccess, onFail) {
    Request.post('/apply/feedback', data, onSuccess, onFail);
  }

  /* 3.14 投保大專旅遊保險 */
  static ctuTravelAffinity(data, onSuccess, onFail) {
    Request.post('/apply/affinity/travel/ctu', data, onSuccess, onFail);
  }

  /* 3.14 投保大專旅遊保險 */
  static uowTravelAffinity(data, onSuccess, onFail) {
    Request.post('/apply/affinity/travel/uow', data, onSuccess, onFail);
  }

  /* 3.16 投保乘客意外保險 */
  static rpaPa(data, onSuccess, onFail) {
    Request.post('/apply/pa/rpa', data, onSuccess, onFail);
  }

  /* 3.17e 儲存"快蓄保2"申請表 */
  static saveEndowment(data, onSuccess, onFail) {
    Request.post('/apply/endowment/save', data, onSuccess, onFail);
  }

  /* 3.17f 載入"快蓄保2"申請表 */
  static loadEndowment(data, onSuccess, onFail) {
    Request.post('/apply/endowment/load', data, onSuccess, onFail);
  }

  /* 3.19 投保意外住院入息保險 */
  static ahi(data, onSuccess, onFail) {
    Request.post('/apply/pa/ahi', data, onSuccess, onFail);
  }

  static novaHome(data, onSuccess, onFail) {
    Request.post('/apply/novahome', data, onSuccess, onFail);
  }

  
}
