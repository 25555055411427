import Locale from "./../../Locale";
import Member from "./../../Member";
import Validation from "./../../Validation";
import AgentAPI from "./../../api/AgentAPI";
import ApplyAPI from "./../../api/ApplyAPI";
import ProductAPI from "./../../api/ProductAPI";
import InsuranceProductScreen from "./../InsuranceProductScreen";

export default class NovaScreen extends InsuranceProductScreen {
  constructor(props) {
    super(props, "NOVAHOME1");

    if (!this.state.insuranceProduct.pl) {
      this.state.insuranceProduct.pl = "NOVAHOME1";
      this.state.insuranceProduct.insuredHome = {
        includeBuilding: "",
        includeEV: "",
        includeFraudulent: "",
        homeType: "",
        areaType: "",
        buildingAgeId: "",
        homeAreaId: "",
      };
    }

    ProductAPI.getHomeAreas(
      { pn: "EAPP", pl: this.state.insuranceProduct.pl },
      (msgDesc) => {
        this.homeGrossAreas = [];
        this.homeSaleableAreas = [];

        console.log(msgDesc.homeAreas);
        for (let i = 0; i < msgDesc.homeAreas.length; i++) {
          if (msgDesc.homeAreas[i].areaType === "GROSS") {
            this.homeGrossAreas.push({
              value: msgDesc.homeAreas[i].id,
              label:
                Locale.getLocale() === "zhHK"
                  ? msgDesc.homeAreas[i].descZhHk
                  : msgDesc.homeAreas[i].descEnUs,
            });
          }
          if (msgDesc.homeAreas[i].areaType === "SALEABLE") {
            this.homeSaleableAreas.push({
              value: msgDesc.homeAreas[i].id,
              label:
                Locale.getLocale() === "zhHK"
                  ? msgDesc.homeAreas[i].descZhHk
                  : msgDesc.homeAreas[i].descEnUs,
            });
          }
        }
        this.setState({ homeGrossAreas: this.homeGrossAreas });
        this.setState({ homeSaleableAreas: this.homeSaleableAreas });
      }
    );

    ProductAPI.getHomeBuildingAges(
      { pn: "EAPP", pl: this.state.insuranceProduct.pl },
      (msgDesc) => {
        this.areaAgeTypeOptions = [];
        console.log(msgDesc);
        for (let i = 0; i < msgDesc.homeBuildingAges.length; i++) {
          this.areaAgeTypeOptions.push({
            value: msgDesc.homeBuildingAges[i].id,
            label:
              Locale.getLocale() === "zhHK"
                ? msgDesc.homeBuildingAges[i].descZhHk
                : msgDesc.homeBuildingAges[i].descEnUs,
          });
        }
        this.setState({ areaAgeTypeOptions: this.areaAgeTypeOptions });
      }
    );

    this.planOptions = [
      { value: "NOVAHOME1", label: Locale.text.novaPlan1 },
      { value: "NOVAHOME2", label: Locale.text.novaPlan2 },
    ];
    this.includeBuildingOptions = [
      { value: "A", label: Locale.text.novaBuildTypeA },
      { value: "H", label: Locale.text.novaBuildTypeB },
    ];
    this.unitAreaTypeOptions = [
      { value: "G", label: Locale.text.novaPracticalArea },
      { value: "S", label: Locale.text.novaBuildingArea },
    ];

    //Test
    // this.updatePolicy({ pl: "NOVAHOME1" });
    // this.updateinsuredHome({ includeBuilding: "Y" });
    // this.updateinsuredHome({ includeEV: "Y" });
    // this.updateinsuredHome({ includeFraudulent: "Y" });
    // this.updateinsuredHome({ homeType: "A" });
    // this.updateinsuredHome({ areaType: "G" });
  }

  handleLeavePage(e) {
    const confirmationMessage = "Some message";
    e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
    return confirmationMessage; // Gecko, WebKit, Chrome <34
  }

  componentDidMount() {
    // window.addEventListener('beforeunload', this.handleLeavePage);
  }
  componentWillUnmount() {
    // window.removeEventListener("beforeunload", this.handleLeavePage);
  }

  componentDidUpdate() {
    const { insuredHome } = this.state.insuranceProduct;
  }

  updateinsuredHome(data) {
    this.updateInsuranceProduct("insuredHome", data);
  }

  getPlanCodeLabel() {
    const { pl } = this.state.insuranceProduct;

    switch (pl) {
      case "NOVAHOME1":
        return Locale.text.headernova1 + " " + Locale.text.headernova2;
      case "NOVAHOME2":
        return Locale.text.headernova1 + " " + Locale.text.headernova2;
      default:
        return Locale.text.headernova1 + " " + Locale.text.headernova2;
    }
  }

  getIncludeBuildingLabel() {
    const { insuredHome } = this.state.insuranceProduct;

    switch (insuredHome.includeBuilding) {
      case "Y":
        return Locale.text.homeContentsOnlyNBuilding;
      case "N":
        return Locale.text.homeContentsOnly;
    }
  }

  getHomeTypeLabel() {
    const { insuredHome } = this.state.insuranceProduct;

    switch (insuredHome.homeType) {
      case "A":
        return Locale.text.apartment;
      case "H":
        return Locale.text.house;
    }
  }

  getQuote(nextScreen) {
    this.setState({ waiting: true }, () => {
      const {
        pn,
        pl,
        agent,
        policy,
        policyHolder,
        jointpolicyHolder,
        jointpolicyHolder1,
        jointpolicyHolder2,
        jointpolicyHolder3,
        insuredHome,
        payment,
      } = this.state.insuranceProduct;

      const { trimAndRemoveEmptyStrings, getValidator } = Validation;

      [
        agent,
        policy,
        policyHolder,
        insuredHome,
        jointpolicyHolder,
        jointpolicyHolder1,
        jointpolicyHolder2,
        jointpolicyHolder3,
        payment,
      ].forEach((data) => {
        trimAndRemoveEmptyStrings(data);
      });

      const messages = {};

      const discountValidation = new Validation(
        {
          businessEmail: policy.businessEmail,
          promotionCode: payment.promotionCode,
        },
        {
          businessEmail: getValidator("text"),
          promotionCode: getValidator("text"),
        }
      );

      const quotainsuredHomeValidation = new Validation(insuredHome, {
        areaType: getValidator("text", true),
        homeType: getValidator("text", true),
        buildingAgeId: getValidator("number", true),
        homeAreaId: getValidator("number", true),
        includeBuilding: getValidator("text", true),
        includeEV: getValidator("text", true),
        includeFraudulent: getValidator("text", true),
      });

      messages.insuredHome = quotainsuredHomeValidation.validate();
      console.log(this.state.insuranceProduct);
      const jointpolicyHolderValidation1 = new Validation(jointpolicyHolder1, {
        firstName:
          jointpolicyHolder.isJointpolicyHolder == "Y" &&
          jointpolicyHolder.jointpolicyHolderAmount >= 1
            ? getValidator("text", true)
            : undefined,
        lastName:
          jointpolicyHolder.isJointpolicyHolder == "Y" &&
          jointpolicyHolder.jointpolicyHolderAmount >= 1
            ? getValidator("text", true)
            : undefined,
      });
      messages.jointpolicyHolder1 = jointpolicyHolderValidation1.validate();
      const jointpolicyHolderValidation2 = new Validation(jointpolicyHolder2, {
        firstName:
          jointpolicyHolder.isJointpolicyHolder == "Y" &&
          jointpolicyHolder.jointpolicyHolderAmount >= 2
            ? getValidator("text", true)
            : undefined,
        lastName:
          jointpolicyHolder.isJointpolicyHolder == "Y" &&
          jointpolicyHolder.jointpolicyHolderAmount >= 2
            ? getValidator("text", true)
            : undefined,
      });
      messages.jointpolicyHolder2 = jointpolicyHolderValidation2.validate();

      const jointpolicyHolderValidation3 = new Validation(jointpolicyHolder3, {
        firstName:
          jointpolicyHolder.isJointpolicyHolder == "Y" &&
          jointpolicyHolder.jointpolicyHolderAmount >= 3
            ? getValidator("text", true)
            : undefined,
        lastName:
          jointpolicyHolder.isJointpolicyHolder == "Y" &&
          jointpolicyHolder.jointpolicyHolderAmount >= 3
            ? getValidator("text", true)
            : undefined,
      });
      messages.jointpolicyHolder3 = jointpolicyHolderValidation3.validate();

      messages.discount = discountValidation.validate();

      if (nextScreen == "nova4") {
        const agentValidation = new Validation(agent, {
          mobile: getValidator("number"),
        });

        messages.agent = agentValidation.validate();

        const policyValidation = new Validation(policy, {
          effectiveDateStart: getValidator("text", true),
          specialRequest: getValidator("text"),
        });

        messages.policy = policyValidation.validate();

        const policyHolderValidation = new Validation(policyHolder, {
          firstName: getValidator("text", true),
          lastName: getValidator("text", true),
          mobile: getValidator("number", true),
          email: getValidator("email", true),
          address1: getValidator("text"),
        });

        messages.policyHolder = policyHolderValidation.validate();

        const insuredHomeValidation = new Validation(insuredHome, {
          areaType: getValidator("text", true),
          fullAddress: getValidator("text", true),
          homeType: getValidator("text", true),
          buildingAgeId: getValidator("number", true),
          homeAreaId: getValidator("number", true),
          propertyMgtName: getValidator(
            "text",
            insuredHome.includeEVYesNo === "Y" ? true : false
          ),
          carparkSpaces: getValidator(
            "text",
            insuredHome.includeEV === "Y" ? true : false
          ),
          carparkSpacesI: getValidator(
            "text",
            insuredHome.includeEV === "Y" && insuredHome.numCarparkSpaces >= 1
              ? true
              : false
          ),
          carparkSpacesII: getValidator(
            "text",
            insuredHome.includeEV === "Y" && insuredHome.numCarparkSpaces >= 2
              ? true
              : false
          ),
          carparkSpacesIII: getValidator(
            "text",
            insuredHome.includeEV === "Y" && insuredHome.numCarparkSpaces >= 3
              ? true
              : false
          ),
          isUnoccupied: getValidator("text", true),
          includeEVYesNo: getValidator(
            "text",
            insuredHome.includeEV === "Y" ? true : false
          ),
          // includeEVYesNo: getValidator(
          //   "text",
          //   insuredHome.includeEV === "Y" ? true : false
          // ),
          includeBuilding: getValidator("text", true),
          includeEV: getValidator("text", true),
          includeFraudulent: getValidator("text", true),
          isAnyLoss: getValidator("text", true),
          isProposalDeclined: getValidator("text", true),
        });

        messages.insuredHome = insuredHomeValidation.validate();

        const jointpolicyHolderValidation1 = new Validation(
          jointpolicyHolder1,
          {
            firstName:
              jointpolicyHolder.isJointpolicyHolder == "Y" &&
              jointpolicyHolder.jointpolicyHolderAmount >= 1
                ? getValidator("text", true)
                : undefined,
            lastName:
              jointpolicyHolder.isJointpolicyHolder == "Y" &&
              jointpolicyHolder.jointpolicyHolderAmount >= 1
                ? getValidator("text", true)
                : undefined,
          }
        );
        messages.jointpolicyHolder1 = jointpolicyHolderValidation1.validate();

        const jointpolicyHolderValidation2 = new Validation(
          jointpolicyHolder2,
          {
            firstName:
              jointpolicyHolder.isJointpolicyHolder == "Y" &&
              jointpolicyHolder.jointpolicyHolderAmount >= 2
                ? getValidator("text", true)
                : undefined,
            lastName:
              jointpolicyHolder.isJointpolicyHolder == "Y" &&
              jointpolicyHolder.jointpolicyHolderAmount >= 2
                ? getValidator("text", true)
                : undefined,
          }
        );

        messages.jointpolicyHolder2 = jointpolicyHolderValidation2.validate();

        const jointpolicyHolderValidation3 = new Validation(
          jointpolicyHolder3,
          {
            firstName:
              jointpolicyHolder.isJointpolicyHolder == "Y" &&
              jointpolicyHolder.jointpolicyHolderAmount >= 3
                ? getValidator("text", true)
                : undefined,
            lastName:
              jointpolicyHolder.isJointpolicyHolder == "Y" &&
              jointpolicyHolder.jointpolicyHolderAmount >= 3
                ? getValidator("text", true)
                : undefined,
          }
        );

        messages.jointpolicyHolder3 = jointpolicyHolderValidation3.validate();
      }
      console.log(JSON.stringify(messages));
      const invalid = JSON.stringify(messages) != "{}";
      if (invalid) {
        this.alert(Locale.text.alert, Locale.text.check, [
          { text: Locale.text.ok, onPress: () => this.dismissAlert() },
        ]);
        console.log(messages);
        this.setState({ messages, waiting: false });
      } else {
        const carparkSpacesList = [];
        if (insuredHome.carparkSpaces)
          carparkSpacesList.push({ address: insuredHome.carparkSpaces });
        if (insuredHome.carparkSpacesI && insuredHome.numCarparkSpaces >= 1)
          carparkSpacesList.push({ address: insuredHome.carparkSpacesI });
        if (insuredHome.carparkSpacesII && insuredHome.numCarparkSpaces >= 2)
          carparkSpacesList.push({ address: insuredHome.carparkSpacesII });
        if (insuredHome.carparkSpacesIII && insuredHome.numCarparkSpaces >= 3)
          carparkSpacesList.push({ address: insuredHome.carparkSpacesIII });

        this.readPremium({
          pn: pn,
          pl: pl,
          "member.id": Member.getMemberId(),
          "member.token": Member.getMemberToken(),
          "payment.promotionCode": payment.promotionCode,
          "policy.businessEmail": policy.businessEmail,
          "insuredHome.homeType": insuredHome.homeType,
          numCarparkSpaces:
            insuredHome.includeEV === "Y" && carparkSpacesList.length > 0
              ? carparkSpacesList?.length
              : insuredHome.includeEV === "N"
              ? 0
              : 1,
          "insuredHome.buildingAgeId": insuredHome.buildingAgeId,
          "insuredHome.homeAreaId": insuredHome.homeAreaId,
          "insuredHome.includeBuilding": insuredHome.includeBuilding,
          "insuredHome.includeEV": insuredHome.includeEV,
          "insuredHome.includeFraudulent": insuredHome.includeFraudulent,
        }).then(({ premium, levy }) => {
          if (insuredHome.homeType === "H")
            this.push(nextScreen, { premium: {}, levy: null });
          else this.push(nextScreen, { premium, levy });
        });
      }
    });
  }

  getDesc(list, id) {
    let desc;
    if (list) {
      list.forEach((a) => {
        if (id === a.value) {
          desc = a.label;
        }
      });
    }
    return desc;
  }

  confirm() {
    this.setState({ waiting: true }, () => {
      const {
        pn,
        pl,
        agent,
        policy,
        policyHolder,
        insuredHome,
        jointpolicyHolder,
        jointpolicyHolder1,
        jointpolicyHolder2,
        jointpolicyHolder3,
        payment,
      } = this.state.insuranceProduct;

      const { getValidator, getProductConfirmationValidator } = Validation;

      const confirmationValidation = new Validation(policy, {
        disclaimerAndPIC: getProductConfirmationValidator(),
        allowPrivacyPromote: getValidator("text", true),
        commissionsDisclosures:
          (this.state.agent || this.state.broker || payment.promotionCode) &&
          getProductConfirmationValidator(),
      });

      const messages = confirmationValidation.validate();

      if (messages) {
        this.alert(Locale.text.alert, Locale.text.check, [
          { text: Locale.text.ok, onPress: () => this.dismissAlert() },
        ]);

        this.setState({ messages, waiting: false });
      } else {
        const carparkSpacesList = [];
        if (insuredHome.carparkSpaces)
          carparkSpacesList.push({ address: insuredHome.carparkSpaces });
        if (insuredHome.carparkSpacesI && insuredHome.numCarparkSpaces >= 1)
          carparkSpacesList.push({ address: insuredHome.carparkSpacesI });
        if (insuredHome.carparkSpacesII && insuredHome.numCarparkSpaces >= 2)
          carparkSpacesList.push({ address: insuredHome.carparkSpacesII });
        if (insuredHome.carparkSpacesIII && insuredHome.numCarparkSpaces >= 3)
          carparkSpacesList.push({ address: insuredHome.carparkSpacesIII });

        const jointPolicyHoldersList = [];
        if (
          jointpolicyHolder1 &&
          jointpolicyHolder.jointpolicyHolderAmount >= 1
        )
          jointPolicyHoldersList.push({
            type: "JI",
            firstName: jointpolicyHolder1.firstName,
            lastName: jointpolicyHolder1.lastName,
          });
        if (
          jointpolicyHolder2 &&
          jointpolicyHolder.jointpolicyHolderAmount >= 2
        )
          jointPolicyHoldersList.push({
            type: "JI",
            firstName: jointpolicyHolder2.firstName,
            lastName: jointpolicyHolder2.lastName,
          });
        if (
          jointpolicyHolder3 &&
          jointpolicyHolder.jointpolicyHolderAmount >= 3
        )
          jointPolicyHoldersList.push({
            type: "JI",
            firstName: jointpolicyHolder3.firstName,
            lastName: jointpolicyHolder3.lastName,
          });

        // if (payment.promotionCode) {
        //   data.push({
        //     label: Locale.text.promotionCodes,
        //     value: payment.promotionCode,
        //   });
        // }

        this.apply(
          this.getPlanCodeLabel(),
          this.state.broker ? AgentAPI.novaHome : ApplyAPI.novaHome,
          {
            pn: pn,
            pl: pl,
            "member.id": Member.getMemberId(),
            "member.token": Member.getMemberToken(),
            "agent.mobile": agent.mobile,
            "policy.effectiveDateStart": policy.effectiveDateStart,
            "policy.effectiveDateEnd": policy.effectiveDateEnd,
            "policy.allowPrivacyPromote3P": policy.allowPrivacyPromote3P,
            "policy.allowPrivacyPromote": policy.allowPrivacyPromote,
            "policy.businessEmail": policy.businessEmail,
            "policy.specialRequest": policy.specialRequest,
            "policyHolder.salutation": policyHolder.salutation,
            "policyHolder.firstName": policyHolder.firstName,
            "policyHolder.lastName": policyHolder.lastName,
            "policyHolder.mobile": policyHolder.mobile,
            "policyHolder.email": policyHolder.email,
            "policyHolder.address1": policyHolder.address1,
            "policyHolder.documentType": policyHolder.documentType,
            "policyHolder.documentNo": policyHolder.documentNo,
            "policyHolder.dateOfBirth": policyHolder.dateOfBirth,
            "insuredHome.fullAddress": insuredHome.fullAddress,
            jointPolicyHolders:
              jointpolicyHolder.isJointpolicyHolder === "Y"
                ? jointPolicyHoldersList
                : [],
            "insuredHome.area": "HK",
            numCarparkSpaces: carparkSpacesList.length,
            "insuredHome.carparkSpaces": carparkSpacesList,
            "insuredHome.homeType": insuredHome.homeType,
            "insuredHome.buildingAgeId": insuredHome.buildingAgeId,
            "insuredHome.homeAreaId": insuredHome.homeAreaId,
            "insuredHome.propertyMgtName": insuredHome.propertyMgtName,
            "insuredHome.includeEV": insuredHome.includeEV,
            "insuredHome.includeFraudulent": insuredHome.includeFraudulent,
            "insuredHome.isUnoccupied": insuredHome.isUnoccupied,
            "insuredHome.includeBuilding": insuredHome.includeBuilding,
            "insuredHome.isAnyLoss": insuredHome.isAnyLoss,
            "insuredHome.isProposalDeclined": insuredHome.isProposalDeclined,
            "payment.ecash": payment.ecash,
            "payment.promotionCode": payment.promotionCode,
            rawRequest: JSON.stringify(this.state.insuranceProduct),
          }
        );
      }
    });
  }
}
