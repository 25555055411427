import React from "react";
import { Text, View } from "react-native";

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import {
  Button,
  Divider,
  DropDownList,
  Form,
  Heading1,
  TextInput,
  YesNoQuestion,
} from "./../../Form";
import Locale from "./../../Locale";
import { Layout, Navbar } from "./../../Screen";
import DiscountInput from "./../components/DiscountInput";
import EffectiveDate from "./../components/EffectiveDate";
import JointPolicyHolderForm from "./../components/JointPolicyHolderForm";
import LegalStatement from "./../components/LegalStatement";
import MobileNumberOfTheInsuranceAdvisor from "./../components/MobileNumberOfTheInsuranceAdvisor";
import PolicyHolderForm from "./../components/PolicyHolderForm";
import ReferralCode from "./../components/ReferralCode";
import SpecialRequest from "./../components/SpecialRequest";
import InsuredNovaForm from "./InsuredNovaForm";
import NovaScreen from "./NovaScreen";
import ProductAPI from "./../../api/ProductAPI";
export default class NovaScreen3 extends NovaScreen {
  render() {
    const {
      pl,
      agent,
      policy,
      policyHolder,
      insuredHome,
      jointpolicyHolder,
      jointpolicyHolder1,
      jointpolicyHolder2,
      jointpolicyHolder3,
      payment,
    } = this.state.insuranceProduct;

    return (
      <Layout alert={this.state.alert}>
        <Navbar screen={this} title={Locale.text.getInsured} />

        {Locale.text.headernova1}
        {Locale.text.headernova2}

        <View style={Layout.container}>
          <Form>
            <Heading1>{Locale.text.novaPackageInsurance}</Heading1>
            <Heading1>{Locale.text.headernova2}</Heading1>

            <LegalStatement
              pn={"nova"}
              broker={this.state.broker}
              agent={this.state.agent}
            />

            <Text>{Locale.text.pleaseInputInEnglish}</Text>

            <MobileNumberOfTheInsuranceAdvisor
              broker={this.state.broker}
              value={agent.mobile}
              onChange={(mobile) => this.updateAgent({ mobile })}
              messages={this.state.messages.agent}
            />

            <DropDownList
              label={Locale.text.novaplanType}
              options={this.planOptions}
              value={pl}
              onChange={(pl) => {
                this.updatePlanCode(pl);
                this.updateinsuredHome({
                  areaType: "",
                  buildingAgeId: "",
                  homeAreaId: "",
                });
                ProductAPI.getHomeAreas(
                  { pn: "EAPP", pl: this.state.insuranceProduct.pl },
                  (msgDesc) => {
                    this.homeGrossAreas = [];
                    this.homeSaleableAreas = [];

                    console.log(msgDesc.homeAreas);
                    for (let i = 0; i < msgDesc.homeAreas.length; i++) {
                      if (msgDesc.homeAreas[i].areaType === "GROSS") {
                        this.homeGrossAreas.push({
                          value: msgDesc.homeAreas[i].id,
                          label:
                            Locale.getLocale() === "zhHK"
                              ? msgDesc.homeAreas[i].descZhHk
                              : msgDesc.homeAreas[i].descEnUs,
                        });
                      }
                      if (msgDesc.homeAreas[i].areaType === "SALEABLE") {
                        this.homeSaleableAreas.push({
                          value: msgDesc.homeAreas[i].id,
                          label:
                            Locale.getLocale() === "zhHK"
                              ? msgDesc.homeAreas[i].descZhHk
                              : msgDesc.homeAreas[i].descEnUs,
                        });
                      }
                    }
                    this.setState({ homeGrossAreas: this.homeGrossAreas });
                    this.setState({
                      homeSaleableAreas: this.homeSaleableAreas,
                    });
                  }
                );

                ProductAPI.getHomeBuildingAges(
                  { pn: "EAPP", pl: this.state.insuranceProduct.pl },
                  (msgDesc) => {
                    this.areaAgeTypeOptions = [];
                    console.log(msgDesc);
                    for (let i = 0; i < msgDesc.homeBuildingAges.length; i++) {
                      this.areaAgeTypeOptions.push({
                        value: msgDesc.homeBuildingAges[i].id,
                        label:
                          Locale.getLocale() === "zhHK"
                            ? msgDesc.homeBuildingAges[i].descZhHk
                            : msgDesc.homeBuildingAges[i].descEnUs,
                      });
                    }
                    this.setState({
                      areaAgeTypeOptions: this.areaAgeTypeOptions,
                    });
                  }
                );
              }}
            />

            <br />
            <Text style={{ fontSize: 18, textAlign: "center" }}>
              {Locale.text.novaOtherBenefits}
            </Text>

            <YesNoQuestion
              label={Locale.text.novaBuildingBenefits}
              value={insuredHome.includeBuilding}
              onChange={(choice) =>
                this.updateinsuredHome({ includeBuilding: choice })
              }
            />
            <YesNoQuestion
              label={Locale.text.novaEVCharger}
              value={insuredHome.includeEV}
              onChange={(choice) =>
              {
                if(choice==="N")
                this.updateinsuredHome({
                  includeEV: choice,
                  includeEVYesNo: choice,
                })
                else
                this.updateinsuredHome({
                  includeEV: choice,
           
                })
              }
              }
            />
            {insuredHome.includeEV === "Y" && (
              <>
                <>
                  <br />
                  <TextInput
                    label={Locale.text.novaParkinglotaddress}
                    placeholder={Locale.text.novaOption}
                    value={insuredHome.carparkSpaces}
                    onChange={(carparkSpaces) =>
                      this.updateinsuredHome({ carparkSpaces })
                    }
                    message={this.state.messages.insuredHome?.carparkSpaces}
                  />

                  <Text>{Locale.text.novaEVChargerExtra}</Text>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={insuredHome.numCarparkSpaces}
                    label="numCarparkSpaces"
                    onChange={(e) => {
                      this.updateinsuredHome({
                        numCarparkSpaces: e.target.value,
                      });
                    }}
                  >
                    <MenuItem value={0}>0</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                  </Select>
                  <Text style={{ fontSize: 12, textAlign: "left" }}>
                    {Locale.text.novaSameParking}
                  </Text>

                  {insuredHome.numCarparkSpaces >= 1 ? (
                    <TextInput
                      label={""}
                      placeholder={Locale.text.novaOption}
                      value={insuredHome.carparkSpacesI}
                      onChange={(carparkSpacesI) =>
                        this.updateinsuredHome({ carparkSpacesI })
                      }
                      message={this.state.messages.insuredHome?.carparkSpacesI}
                    />
                  ) : null}
                  {insuredHome.numCarparkSpaces >= 2 ? (
                    <TextInput
                      label={""}
                      placeholder={Locale.text.novaOption}
                      value={insuredHome.carparkSpacesII}
                      onChange={(carparkSpacesII) =>
                        this.updateinsuredHome({ carparkSpacesII })
                      }
                      message={this.state.messages.insuredHome?.carparkSpacesII}
                    />
                  ) : null}
                  {insuredHome.numCarparkSpaces >= 3 ? (
                    <TextInput
                      label={""}
                      placeholder={Locale.text.novaOption}
                      value={insuredHome.carparkSpacesIII}
                      onChange={(carparkSpacesIII) =>
                        this.updateinsuredHome({ carparkSpacesIII })
                      }
                      message={
                        this.state.messages.insuredHome?.carparkSpacesIII
                      }
                    />
                  ) : null}
                </>
                <br />
                <YesNoQuestion
                  label={Locale.text.novaEVChargerQA}
                  sublabel={Locale.text.novaEVChargerQAII}
                  value={insuredHome.includeEVYesNo}
                  onChange={(choice) =>
                    this.updateinsuredHome({ includeEVYesNo: choice })
                  }
                  message={this.state.messages.insuredHome?.includeEVYesNo}
                />
                {insuredHome.includeEVYesNo === "Y" && (
                  <>
                    <TextInput
                      label={Locale.text.novaPropertyManagementCompanyName}
                      placeholder={Locale.text.novaOption}
                      value={insuredHome.propertyMgtName}
                      onChange={(propertyMgtName) =>
                        this.updateinsuredHome({ propertyMgtName })
                      }
                      message={this.state.messages.insuredHome?.propertyMgtName}
                    />

                    {/* <Checkbox
                  label={Locale.text.novaEVChargerExtra}
                  value={insuredHome.extraEV}
                  onChange={(choice) => {
                    this.updateinsuredHome({ extraEV: choice });
                  }}
                />
                {insuredHome.extraEV === "Y" && (
                  <>
                    <TextInput
                      label={""}
                      placeholder={Locale.text.novaOption}
                      value={insuredHome.novaEVChargerExtra}
                      onChange={(carparkSpacesII) =>
                        this.updateinsuredHome({ carparkSpacesII })
                      }
                    />
                    <Text style={{ fontSize: 12, textAlign: "left" }}>
                      {Locale.text.novaSameParking}
                    </Text>
                  </>
                )} */}
                  </>
                )}
              </>
            )}
            <YesNoQuestion
              label={Locale.text.novaFraudulentCharger}
              value={insuredHome.includeFraudulent}
              onChange={(choice) =>
                this.updateinsuredHome({ includeFraudulent: choice })
              }
            />

            <EffectiveDate
              value={policy.effectiveDateStart}
              onChange={(effectiveDate) => this.updatePolicy(effectiveDate)}
              messages={this.state.messages.policy}
            />

            <DiscountInput
              disableBusiness={true}
              promotionCode={payment.promotionCode}
              businessEmail={policy.businessEmail}
              directCustomer={!this.state.agent && !this.state.broker}
              onChange={({ promotionCode, businessEmail }) => {
                this.updatePayment({ promotionCode });
                this.updatePolicy({ businessEmail });
              }}
              messages={this.state.messages.discount}
            />

            <PolicyHolderForm
              noDocument
              noDateOfBirth
              policyHolder={policyHolder}
              onChange={(data) => this.updatePolicyHolder(data)}
              messages={this.state.messages.policyHolder}
            />

            <JointPolicyHolderForm
              noDocument
              noDateOfBirth
              num={1}
              jointpolicyHolder={jointpolicyHolder}
              jointpolicyHolder1={jointpolicyHolder1}
              jointpolicyHolder2={jointpolicyHolder2}
              jointpolicyHolder3={jointpolicyHolder3}
              onChange={({
                data,
                jointpolicyHolder,
                isJointpolicyHolder,
                jointpolicyHolderAmount,
                jointpolicyHolder1,
                jointpolicyHolder2,
                jointpolicyHolder3,
              }) => {
                if (data) this.updateJointPolicyHolder(data);
                if (jointpolicyHolderAmount)
                  this.updateJointPolicyHolder({
                    jointpolicyHolderAmount: jointpolicyHolderAmount,
                  });
                if (isJointpolicyHolder) {
                  this.updateJointPolicyHolder({
                    isJointpolicyHolder: isJointpolicyHolder,
                  });
                  if (isJointpolicyHolder === "Y")
                    this.updateJointPolicyHolder({
                      jointpolicyHolderAmount: 1,
                    });
                }

                this.updateJointPolicyHolder1(jointpolicyHolder1);
                this.updateJointPolicyHolder2(jointpolicyHolder2);
                this.updateJointPolicyHolder3(jointpolicyHolder3);
              }}
              messages={this.state.messages.jointpolicyHolder}
              messages1={this.state.messages.jointpolicyHolder1}
              messages2={this.state.messages.jointpolicyHolder2}
              messages3={this.state.messages.jointpolicyHolder3}
            />

            <InsuredNovaForm
              includeBuildingOptions={this.includeBuildingOptions}
              areaAgeTypeOptions={this.areaAgeTypeOptions}
              unitAreaTypeOptions={this.unitAreaTypeOptions}
              homeGrossAreas={this.homeGrossAreas}
              homeSaleableAreas={this.homeSaleableAreas}
              homeAreaId={this.homeAreaId}
              insuredHome={insuredHome}
              onChange={(data) => this.updateinsuredHome(data)}
              messages={this.state.messages.insuredHome}
            />

            <Divider />

            <SpecialRequest
              value={policy.specialRequest}
              onChange={(specialRequest) =>
                this.updatePolicy({ specialRequest })
              }
              messages={this.state.messages.policy}
            />
            <Text>{Locale.text.novaSpecialRemark}</Text>

            <ReferralCode
              value={policy.referralCode}
              onChange={(referralCode) => this.updatePolicy({ referralCode })}
              messages={this.state.messages.referralCode}
            ></ReferralCode>

            <Button
              disabled={this.state.waiting}
              onPress={() => this.getQuote("nova4")}
            >
              {Locale.text.buyNow}
            </Button>
          </Form>
        </View>
      </Layout>
    );
  }
}
