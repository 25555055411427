import React from "react";
import { View } from "react-native";

import { Button, Form } from "./../../Form";
import Locale from "./../../Locale";
import { Layout, Navbar } from "./../../Screen";
import Alert from "./../../components/Alert/Alert";
import InformationForQuotation from "./../components/InformationForQuotation";
import Total from "./../components/Total";
import NovaScreen from "./NovaScreen";
export default class NovaScreen2 extends NovaScreen {
  buyNow() {
    if (this.state.broker) {
      this.askCustomerToLogin().then(() => this.push("nova3"));
    } else {
      if (Locale.getPaymentStatus()) {
        this.askCustomerToLogin().then(() => this.push("nova3"));
      } else {
        this.setState({
          alert: Alert.alert(
            Locale.text.paymentStopService1,
            Locale.text.paymentStopService2,
            [
              {
                text: Locale.text.yes,
                onPress: () => this.setState({ alert: null }),
              },
            ]
          ),
        });
      }
    }
    //this.askCustomerToLogin().then(() => this.push('Home3'));
  }

  render() {
    const { pl, policy, insuredHome, payment } = this.state.insuranceProduct;

    const data = [];

    var optionBenef = "";
    optionBenef =
      insuredHome.includeBuilding === "Y"
        ? "• " + Locale.text.novaBuildingBenefits + "\n"
        : optionBenef;
    optionBenef =
      insuredHome.includeEV === "Y"
        ? optionBenef + "• " + Locale.text.novaEVCharger + "\n"
        : optionBenef;
    optionBenef =
      insuredHome.includeFraudulent === "Y"
        ? optionBenef + "• " + Locale.text.novaFraudulentCharger
        : optionBenef;

    if (optionBenef) {
      data.push({
        label: Locale.text.novaOtherBenefits,
        value: optionBenef,
        style: "left",
      });
    }
    console.log(insuredHome);

    data.push({
      label: Locale.text.novaBuildType,
      value: this.getDesc(this.includeBuildingOptions, insuredHome.homeType),
    });
    data.push({
      label: Locale.text.novaAge,
      value:
        this.getDesc(this.areaAgeTypeOptions, insuredHome.buildingAgeId) + " "+
        Locale.text.novaYear,
    });
    data.push({
      label: this.getDesc(this.unitAreaTypeOptions, insuredHome.areaType),
      value:
        insuredHome.areaType === "S"
          ? this.getDesc(this.homeGrossAreas, insuredHome.homeAreaId)
          : this.getDesc(this.homeSaleableAreas, insuredHome.homeAreaId),
    });

    if (payment.promotionCode) {
      data.push({
        label: Locale.text.promotionCodes,
        value: payment.promotionCode,
      });
    }

    if (policy.businessEmail) {
      data.push({
        label: Locale.text.businessEmailMembership,
        value: policy.businessEmail,
      });
    }

    return (
      <Layout alert={this.state.alert}>
        <Navbar screen={this} title={Locale.text.getAQuote} />

        {Locale.text.headernova1}
        {Locale.text.headernova2}

        <View style={Layout.container}>
          <Form>
            <InformationForQuotation
              planTypeOptions={this.planOptions}
              planType={pl}
              data={data}
            />

            <Total premium={this.getParam("premium")} />
          </Form>

          <Button withMargin onPress={() => this.buyNow()}>
            {Locale.text.buyNow}
          </Button>
        </View>
      </Layout>
    );
  }
}
